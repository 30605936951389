import React from 'react';
import { createUseStyles } from 'react-jss';
import Button from '../../../atoms/Button/Button';
import { Link } from 'react-router-dom';
import { STATIC_PAGES_ROUTES } from '../../../../utils/constant';


const useStyles = createUseStyles((theme: any) => ({
    container: {
        background: "white",
        position: 'absolute',
        width: '100%',
        top: '135px',
        left: '0',
        zIndex: '99',
    },
    primaryContainer: {
        width: '1297px',
        margin: '0 auto',
    },
    heading: {
        color: theme.palette.text.secondary.secondary500,
        fontSize: '32px',
        lineHeight: '38px',
    },
    subText: {
        color: theme.palette.text.neutral.neutral600,
    },
    memberOptions: {
        width: '320px',
        // background: theme.palette.background.primary.primary300,
        background: '#D9D9D9',
        "& span": {
            color: theme.palette.text.primary.primary950,
        }
    },
}));



const TradeDropdown: React.FC = () => {
    const classes = useStyles();

    return (
        <div className={`${classes.container} py-10`}>
            <div className={`${classes.primaryContainer} flex justify-between items-center`}>
                <div className='w-1/2'>
                    <h3 className={`${classes.heading} font-bold`}>SteelBazaar Trade Assurance</h3>
                    <p className={ `${classes.subText} text-base font-medium mb-5`}>Complete Protection from Payment to Delivery</p>
                    
                    <Link to={STATIC_PAGES_ROUTES.TRADE_ASSURANCE}><Button variant={'primaryContained'} label="Learn More"></Button></Link>

                </div>
                <div className='flex flex-col  w-1/2'>
                    <div className='flex items-center mb-8 gap-8'>
                    <Link to={STATIC_PAGES_ROUTES.TRADE_ASSURANCE}>
                    <div className={`${classes.memberOptions} px-6 py-8 w-full text-center`}>
                            <span className='text-base text-center'>Safe & easy payments</span>
                        </div>
                        </Link>
                        <Link to={STATIC_PAGES_ROUTES.TRADE_ASSURANCE}>
                        <div className={`${classes.memberOptions} w-full px-6 py-8 text-center`}>
                            <span className='text-base text-center'>Money-back policy</span>
                        </div>
                        </Link>
                    </div>
                    <div className='flex items-center gap-8'>
                    <Link to={STATIC_PAGES_ROUTES.TRADE_ASSURANCE}>
                        <div className={`${classes.memberOptions} w-full px-6 py-8 text-center`}>
                            <span className='text-base text-center'>Shipping & logistics services</span>
                        </div>
                        </Link>
                        <Link to={STATIC_PAGES_ROUTES.TRADE_ASSURANCE}>
                        <div className={`${classes.memberOptions} w-full px-6 py-8 text-center`}>
                            <span className='text-base text-center '>After-sales protections</span>
                        </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TradeDropdown;

