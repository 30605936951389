import React, { useEffect, useState } from 'react'
import Button from '../../atoms/Button/Button'
import { createUseStyles } from 'react-jss';
import { IStatutoryDetailProps } from './StatutoryDetail.template';
import Checkbox from '@mui/material/Checkbox';
import deleteOutlinedPrimary800 from '../../../assets/icons/deleteOutlinedPrimary800.svg';
import { useBusinessProfileService } from '../../../services/useBusinessProfileService';
import { useSnackbar } from '../../../hooks/useSnackBar';
import AlertPopupModelTemplate from './AlertPopupModel.template';
import TextFieldV2 from '../../atoms/Input/TextFieldV2';
import Table, { ColumnType } from '../../organisms/table';
import { IPagination } from '../../../utils/types';
import { initialPaginationValues } from '../../../utils/constant';
import EmptyBankDetailsTemplate from './EmptyBankDetails.template';
import ConfirmPrimaryChangeModal from './ConfirmPrimaryChangeModal.templeate';

interface Column {
    label: string;
    key: string;
    type: ColumnType;
    props: { className: string };
}
interface IBankAccounts {
    ifsc: string;
    branch: string;
    bankName: string;
    isDefault: boolean;
    accountName: string;
    accountNumber: string
}

const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid ${theme.palette.border.neutral.neutral100}`,
    },
    borderBottom: {
        borderBottom: `1px solid ${theme.palette.border.neutral.neutral100}`,
    },
    headingText: {
        color: theme.palette.text.primary.primary900
    },
    primaryBankAccoutText: {
        color: theme.palette.text.primary.primary800
    },
    primaryTextBackground: {
        color: theme.palette.text.primary.primary600,
        backgroundColor: theme.palette.background.complementary.complementary50,
    },
    errorMessage: {
        color: theme.palette.text.secondary.secondary500,
    },
    checkedInputColor: {
        color: theme.palette.text.primary.primary400,
        '&.Mui-checked': {
            color: theme.palette.text.primary.primary500,
        },
    }
}));

const UploadBankDetailsTemplate: React.FC<IStatutoryDetailProps> = ({ formik, refreshBusinessProfile, showEditFormBank }) => {
    const classes = useStyles();
    const [onEdit, setOnEdit] = useState<boolean>(false);
    const [onPrimarychange, setOnPrimarychange] = useState<boolean>(false);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const bussinessProfileService = useBusinessProfileService();
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const [isAlertModalOpen, setIsAlertModalOpen] = useState<boolean>(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
    const [pagination, setPagination] = useState<IPagination>(initialPaginationValues);
    const [deleteIndex, setDeleteIndex] = useState<number | null>(null);
    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = event.target;
        if (checked) {
            const hasDefaultBankDetails = formik.values.bankDetails.some((detail: any) => detail.isDefault === true);
            if (hasDefaultBankDetails) { setIsAlertModalOpen(true) }
            formik.setFieldValue("isDefault", true);
        } else {
            formik.setFieldValue("isDefault", false);
            formik.setSubmitting(true);
            setTimeout(() => formik.setSubmitting(false), 100);
        }
    };

    const handleDelete = async (index: number) => {
        setIsDeleteModalOpen(true);
        setDeleteIndex(index);
    };
    const displayedBankDetails = formik.values.bankDetails.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).reverse();

    const handleDeleteDialogClose = async (event: boolean) => {
        setIsDeleteModalOpen(false);
        if (event && deleteIndex != null) {
            const newBankDetails = [...formik.values.bankDetails].reverse();
            newBankDetails.splice(deleteIndex, 1);
            formik.setFieldValue('bankDetails', newBankDetails);
            const body = [
                ...newBankDetails,
            ];
            try {
                await bussinessProfileService.updateBusinessProfile(body, 'BANK_ACCOUNTS');
                refreshBusinessProfile();
            } catch (error) {
                showSnackbar("error", `Business profile update  failed ${error}`);
            }
        }
    };
    const handleDialogClose = (event: boolean) => {
        if (event === true) {
            setIsAlertModalOpen(false);
            formik.setFieldValue("isDefault", true);
            const updatedBankDetails = formik.values.bankDetails.map((detail: any) => ({
                ...detail,
                isDefault: false
            }));
            formik.setFieldValue("bankDetails", updatedBankDetails);
        } else {
            setIsAlertModalOpen(false);
            formik.setFieldValue("isDefault", false);
        }
    };
    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setRowsPerPage(newRowsPerPage);
        setPage(0);
    };


    const Action = (id: number) => (
        <div className='flex items-center justify-center gap-x-3'>
            <button onClick={() => handleDelete(id)}><img src={deleteOutlinedPrimary800} alt={deleteOutlinedPrimary800} /></button>
        </div>
    )

    const getTableColumns = (edit: boolean): Column[] => {
        const columns: Column[] = [
            { label: "S No.", key: "id", type: "number" as ColumnType, props: { className: '' } },
            { label: "Account holder name", key: "accountName", type: "custom" as ColumnType, props: { className: '' } },
            { label: "Account Number", key: "accountNumber", type: "customm" as ColumnType, props: { className: '' } },
            { label: "IFSC Code", key: "ifsc", type: "custom" as ColumnType, props: { className: '' } },
            { label: "Bank Name", key: "bankName", type: "custom" as ColumnType, props: { className: '' } },
            { label: "Branch Name", key: "branch", type: "custom" as ColumnType, props: { className: '' } },
            { label: "Action", key: "action", type: "custom" as ColumnType, props: { className: '' } }
        ];
        if (edit) {
            return columns.slice(0, -1);
        }
        return columns;
    };

    const getRecords = () => {
        const startIndex = page * rowsPerPage;
        const endIndex = startIndex + rowsPerPage;
        const paginatedDetails = formik.values.bankDetails
            .slice()
            .reverse()
            .slice(startIndex, endIndex);
        return paginatedDetails.map((displayedBankDetail: IBankAccounts, index: any) => [
            startIndex + index + 1,
            <div key={index} className="flex gap-x-3 justify-center">
                <div className="my-auto">{displayedBankDetail.accountName}</div>
                {displayedBankDetail.isDefault && (
                    <div className={`px-3 py-1.5 rounded-3xl font-medium text-xs ${classes.primaryTextBackground}`}>
                        Primary
                    </div>
                )}
            </div>,
            displayedBankDetail.accountNumber,
            displayedBankDetail.ifsc,
            displayedBankDetail.bankName,
            displayedBankDetail.branch,
            Action(index),
        ]);
    };

    const getSchema = () => ({
        id: "1",
        pagination: {
            total: formik.values.bankDetails.length,
            currentPage: page,
            isVisible: true,
            limit: rowsPerPage,
            handleChangePage: handleChangePage,
            handleChangeRowsPerPage: handleChangeRowsPerPage
        },
        columns: getTableColumns(!onEdit) ?? []
    })

    useEffect(() => {
        getRecords();
    }, [page, rowsPerPage]);

    return (
        (formik.values.bankDetails.length != 0 || onEdit) ?
            <div className={`${classes.container} p-4 grid gap-y-6 rounded-xl w-full`}>
                <div className={`flex justify-between items-center pb-6 ${classes.borderBottom}`}>
                    <div className={`${classes.headingText} text-lg font-medium`}>Bank Details</div>
                    <div className='flex'>
                        <Checkbox
                            checked={formik.values.isDefault}
                            onChange={handleCheckboxChange}
                            name="isDefault"
                            className={`${classes.checkedInputColor}`}
                            sx={{ padding: 0, width: "18px", height: "18px", margin: "auto", marginRight: "8px", }}
                        />
                        <div className={`${classes.primaryBankAccoutText} text-base my-auto font-normal mr-6`}>Primary Bank Account</div>
                        <Button
                            variant={onEdit ? 'primaryContained' : 'tertiaryContained'}
                            label={onEdit ? 'Save' : 'Edit'}
                            size='medium'
                            onClick={() => setOnEdit(!onEdit)}
                        />
                    </div>
                </div>
                {
                    isAlertModalOpen &&
                    <ConfirmPrimaryChangeModal
                        dialogOpen={isAlertModalOpen}
                        heading={"Change Primary Bank Account"}
                        text={"You have already added the primary bank account are you sure you want to change the "}
                        handleDialogClose={handleDialogClose}
                    />
                }
                {
                    onEdit && <>
                        <div className='grid grid-cols-4 gap-x-6 gap-y-6'>
                            <div className='grid gap-1'>
                                <TextFieldV2
                                    label="Account Holder Name"
                                    placeholder="Enter Name"
                                    fullWidth
                                    {...formik.getFieldProps('accountName')}
                                    error={
                                        formik.touched.accountName &&
                                        Boolean(formik.errors.accountName)
                                    }
                                />
                                {formik.touched && formik.touched.accountName && formik.errors?.accountName && (
                                    <div className={classes.errorMessage}>
                                        <small>{formik.errors?.accountName}</small>
                                    </div>
                                )}
                            </div>
                            <div className='grid gap-1'>
                                <TextFieldV2
                                    label="Account Number"
                                    placeholder="Enter Number"
                                    fullWidth
                                    {...formik.getFieldProps('accountNumber')}
                                    error={
                                        formik.touched.accountNumber &&
                                        Boolean(formik.errors.accountNumber)
                                    }
                                />
                                {formik.touched && formik.touched.accountNumber && formik.errors?.accountNumber && (
                                    <div className={classes.errorMessage}>
                                        <small>{formik.errors?.accountNumber}</small>
                                    </div>
                                )}
                            </div>
                            <div className='grid gap-1'>
                                <TextFieldV2
                                    label="IFSC Code"
                                    placeholder="Enter IFSC Code"
                                    fullWidth
                                    {...formik.getFieldProps('ifsc')}
                                    onChange={(event: any) => {
                                        formik.handleChange({
                                            target: { name: 'ifsc', value: (event.target.value).toUpperCase() },
                                        });
                                    }}
                                    error={
                                        formik.touched.ifsc &&
                                        Boolean(formik.errors.ifsc)
                                    }
                                />
                                {formik.touched && formik.touched.ifsc && formik.errors?.ifsc && (
                                    <div className={classes.errorMessage}>
                                        <small>{formik.errors?.ifsc}</small>
                                    </div>
                                )}
                            </div>
                            <div className='grid gap-1'>
                                <TextFieldV2
                                    label="Bank Name"
                                    placeholder="Enter bank Name"
                                    fullWidth
                                    {...formik.getFieldProps('bankName')}
                                    error={
                                        formik.touched.bankName &&
                                        Boolean(formik.errors.bankName)
                                    }
                                />
                                {formik.touched && formik.touched.bankName && formik.errors?.bankName && (
                                    <div className={classes.errorMessage}>
                                        <small>{formik.errors?.bankName}</small>
                                    </div>
                                )}
                            </div>
                            <div className='grid gap-1'>
                                <TextFieldV2
                                    label="Branch Name"
                                    placeholder="Enter Branch Name"
                                    fullWidth
                                    {...formik.getFieldProps('branch')}
                                    error={
                                        formik.touched.branch &&
                                        Boolean(formik.errors.branch)
                                    }
                                />
                                {formik.touched && formik.touched.branch && formik.errors?.branch && (
                                    <div className={classes.errorMessage}>
                                        <small>{formik.errors?.branch}</small>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='flex justify-end '>
                            <Button
                                variant={'primaryContained'}
                                label={'Add'}
                                onClick={() => formik.handleSubmit()}
                            />
                        </div>
                    </>
                }
                {formik.values.bankDetails.length != 0 &&
                    <div className='w-full'>
                        <Table schema={getSchema()} records={getRecords()} />
                    </div>
                }
                {
                    isDeleteModalOpen &&
                    <AlertPopupModelTemplate
                        dialogOpen={isDeleteModalOpen}
                        heading={"Delete Bank Account"}
                        text={"Are you sure want to delete this bank account?"}
                        handleDialogClose={handleDeleteDialogClose}
                    />
                }

            </div> :
            showEditFormBank && <EmptyBankDetailsTemplate setEdit={setOnEdit} />
    )
}

export default UploadBankDetailsTemplate