import React, { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss';
import { AUTH_STATES } from '../../../utils/types';

interface CompanyInformationTemplateProps {
    section: AUTH_STATES;
    sentEmailType: AUTH_STATES
}

const useStyles = createUseStyles((theme: any) => ({
    headingColor: {
        color: theme.palette.text.primary.primary800,
        fontSize: "32px",
        lineHeight: "38px"
    },
    content: {
        color: theme.palette.text.neutral.neutral600,
        lineHeight: "20px"
    }
}));

const CompanyInformationTemplate: React.FC<CompanyInformationTemplateProps> = ({ section, sentEmailType }) => {
    const classes = useStyles();
    const [ content, setContent ] = useState({ heading: "", discription: "" });
    useEffect(() => {
        switch (section) {
            case AUTH_STATES.LOGIN_WITH_EMAIL:
            case AUTH_STATES.LOGIN_WITH_MOBILE:
                setContent({ heading: "Welcome Back!", discription: "You need to be signed in to access the dashboard. " })
                break;
            case AUTH_STATES.USER_SIGNUP:
                setContent({ heading: "Welcome to SteelBazaar Steels", discription: "Sign up is simple, free and fast. " })
                break;
            case AUTH_STATES.OTP_VERIFICATION:
                setContent({ heading: "OTP Verification", discription: "You need to be verify OTP to complete verification. " })
                break;
            case AUTH_STATES.SET_PASSWORD:
                setContent({ heading: "Set New Password", discription: "Please enter your new password. " })
                break;
            case AUTH_STATES.FORGOT_PASSWORD:
                setContent({ heading: "Forgot Password?", discription: "Please enter your registered email. " })
                break;
            case AUTH_STATES.EMAIL_CONFIRMAITON:
                setContent({ heading: `${sentEmailType===AUTH_STATES.FORGOT_PASSWORD ? "Forgot Password?" : "Welcome to SteelBazaar Steels"}`, 
                discription: `${sentEmailType===AUTH_STATES.FORGOT_PASSWORD ? "" : "Sign up is simple, free and fast."}` })
                break;
            default:
            setContent({ heading: "Welcome to SteelBazaar Steels", discription: "Sign up is simple, free and fast. " })
            break;
        }
    }, [section])
    return (
        <div className="grid gap-y-2 items-center w-full">
            <div className={`text-center font-semibold ${classes.headingColor}`}>{content.heading}</div>
            <div className={`${classes.content} text-base text-center`}>{content.discription}</div>
        </div>
    )
}

export default CompanyInformationTemplate