import { useState } from "react";
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router-dom";
import { isArray } from "lodash";
import Dialog from '@mui/material/Dialog';
import closeIcon from "../../../assets/icons/closeRoundIcon.svg";
import SuccessFailurePopup from "../../molecules/SuccessFailurePopup/SuccessFailurePopup";
import RequestForQuotationFormTemplate from "./RequestForQuotationForm.template";
import { useAuthenticatedUser } from "../../../hooks/useAuthenticatedUser";
import { IRfqRequestBody, useRfqService } from "../../../services/useRfqService";
import { IUserRequestBody, useUserService } from "../../../services/useUserService";
import { HTTP_STATUS } from "../../../utils/types";

interface RequestForQuotationModalTemplateProps {
    dialogOpen: boolean;
    setDialogOpen: (open: boolean) => void;
}

const useStyles = createUseStyles((theme: any) => ({
    mainContainer: {
        "& .MuiDialog-paper": {
            width: "917px !important",
            maxWidth: "917px !important",
            height: "736px",
            borderRadius: "16px"
        }
    },
    container: {
        padding: "24px"
    },
    heading: {
        color: theme.palette.text.primary.primary800,
        fontSize: "28px",
        borderBottom: `1px solid ${theme.palette.border.neutral.neutral200}`,
    },
    "@media (max-width: 480px)": {
    },
}));

const RequestForQuotationModalTemplate: React.FC<RequestForQuotationModalTemplateProps> = ({ dialogOpen, setDialogOpen }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const rfqService = useRfqService();
    const userService = useUserService();
    const { user } = useAuthenticatedUser();
    const [thanksDialogOpen, setThanksDialogOpen] = useState<boolean>(false);
    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const createRfq = async (requestBody: any) => {
        const payload: IRfqRequestBody = {
            customerId: requestBody.customerId,
            productCategoryId: requestBody.productCategoryId,
            manufacturerId: requestBody.manufacturerId,
            gradeId: requestBody.gradeId,
            standardId: requestBody.standardId,
            shape: requestBody.shape,
            attributes: requestBody.attributes,
            requiredQuantity: requestBody.requiredQuantity,
            expectedDeliveryDate: requestBody.expectedDeliveryDate,
            specificRequirement: requestBody.specificRequirement.join(","),
            paymentTerm: requestBody.paymentTerm,
        };
        if (user !== null) {
            try {
                const enquiryResponse = await rfqService.createRfq(payload);
                if (enquiryResponse?.status === HTTP_STATUS.OK) {
                    setDialogOpen(false)
                    setThanksDialogOpen(true)
                } else {
                    throw new Error("Rfq creation failed");
                }
            } catch (error) {
                return false;
            }
        } else {
            const userRequestBody: IUserRequestBody = {
                mobileNumber: requestBody.mobileNumber,
                fullName: requestBody.name,
                email: !!requestBody.email ? requestBody.email : null,
                gstin: !!requestBody.gst ? requestBody.gst : null,
                companyProfile: {
                    address: {
                        city: requestBody.city,
                        email: !!requestBody.email ? requestBody.email : null,
                        state: requestBody.state,
                        pincode: requestBody.pinCode,
                        line1: requestBody.addressLine1,
                        line2: requestBody.addressLine2,
                        mobileNumber: requestBody.mobileNumber,
                        resourceType: "BUSINESS"
                    },
                    business: {
                        name: requestBody.companyName,
                        gstin: !!requestBody.gst ? requestBody.gst : null,
                        statutory: {}
                    }
                }
            }
            const userResponse = await userService.createUser(userRequestBody);
            if (userResponse?.status === HTTP_STATUS.OK) {
                payload.customerId = userResponse.data.data.data.userId
                const enquiryResponse = await rfqService.createRfq({ ...payload, specificRequirement: (isArray(payload.specificRequirement) ? payload.specificRequirement.join(",") : payload.specificRequirement) });
                if (enquiryResponse?.status === HTTP_STATUS.OK) {
                    setDialogOpen(false)
                    setThanksDialogOpen(true)
                }
            } else {
                throw new Error("User creation failed");
            }
        }
    };

    const continueToHome = () => {
        navigate("/");
    };

    return (
        <>
            <Dialog fullWidth className={classes.mainContainer} open={dialogOpen} onClose={handleDialogClose}>
                <div className={`grid gap-y-8 ${classes.container}`} >
                    <div className={`${classes.heading} flex justify-between pb-4`}>
                        <div className={`font-semibold`}>Tell us About your Customised Requirements</div>
                        <div className={`cursor-pointer`} onClick={handleDialogClose} >
                            <img src={closeIcon} alt="closeIcon" />
                        </div>
                    </div>
                    <RequestForQuotationFormTemplate onSubmit={createRfq} />
                </div>
            </Dialog >
            {thanksDialogOpen &&
                <SuccessFailurePopup
                    width="664px"
                    height="640px"
                    variant="Success"
                    heading="Thank you for reaching out to us!🌟"
                    description={   <div className={`grid gap-y-4 text-lg text-center leading-[22px]`}>
                    <div>Your inquiry means a lot to us, and we're excited to assist you. Our team is dedicated to providing top-notch service and will respond to your query promptly.</div>
                    <div>In the meantime, feel free to explore more of our website to discover our products/services and learn about what makes us stand out. If you have any further questions or need assistance with anything else, don't hesitate to reach out.</div>
                    <div>Once again, thank you for choosing us. We look forward to serving you!</div>
                    <div>
                        <div>Best regards,</div>
                        <div>SteelBazaar Team</div>
                    </div>
                </div>}
                    setShowPopup={setThanksDialogOpen}
                    button1={{
                        text: 'Continue',
                        variant: "tertiaryContained",
                        size: "large",
                        onClick: continueToHome,
                    }}
                />
            }
        </>
    );
};

export default RequestForQuotationModalTemplate;