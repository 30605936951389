import React, { useState } from 'react';
import { createUseStyles } from "react-jss";
import { Select as MuiSelect, MenuItem, SelectProps as MuiSelectProps } from "@mui/material";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import { ReactComponent as DownArrow } from "../../../assets/icons/downArrowFilled.svg";
import { capitalizeFirstLetter } from '../../../utils/helper';

const useStyles = createUseStyles((theme: any) => ({
    input: {
        border: "1px solid",
        borderColor: theme.palette.border.neutral.neutral200,
        fontSize: "16px",
        fontWeight: 500,
        borderRadius: "12px",
        color: theme.palette.text.neutral.neutral800,
        "&:hover": {
            borderColor: theme.palette.border.primary.primary300,
            background: "white",
            borderWidth: "1px",
            outline: "none",
        },
        "& .MuiOutlinedInput-notchedOutline": {
            outline: "none",
            borderWidth: 0,
            borderColor: theme.palette.border.primary.primary300,
        },
        "&:focus-within": {
            outline: "none",
            borderColor: `${theme.palette.border.primary.primary300} !important`,
            borderWidth: 2,
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            outline: "none",
            borderWidth: 0,
            borderColor: `${theme.palette.border.primary.primary300} !important`,
        },
        "& .MuiInputBase-input": {
            padding: "13px 12px !important"
        },
    },
    label: {
        color: theme.palette.text.neutral.neutral700,
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "16px"
    },
    placeholder: {
        color: `${theme.palette.text.neutral.neutral400} !important`,
        fontWeight: 400
    },
    icon: {
        right: 8,
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        pointerEvents: "none",
        transition: "transform 0.3s ease",
    },
    iconOpen: {
        transform: "translateY(-50%) rotate(180deg)",
    },
    "@media (max-width: 480px)": {
        input: {
            border: "1px solid",
            borderColor: theme.palette.border.neutral.neutral200,
            fontSize: "14px",
            fontWeight: 500,
            borderRadius: 8,
            color: theme.palette.text.neutral.neutral800,
            "&:hover": {
                borderColor: theme.palette.border.primary.primary300,
                borderWidth: 2,
                outline: "none",
            },
            "& .MuiOutlinedInput-notchedOutline": {
                outline: "none",
                borderWidth: 0,
                borderColor: theme.palette.border.neutral.neutral200,
            },
            "&:focus-within": {
                outline: "none",
                borderColor: `${theme.palette.border.primary.primary300} !important`,
                borderWidth: "2px !important",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                outline: "none",
                borderWidth: 0,
                borderColor: `${theme.palette.border.primary.primary300} !important`,
            },
            "& .MuiInputBase-input": {
                padding: "16px !important"
            },
        },
        label: {
            color: theme.palette.text.neutral.neutral700,
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "16.1px"
        },
    },
}));

interface Option {
    value: string | number;
    label: string;
}

interface SelectProps extends Omit<MuiSelectProps, "label"> {
    options: Option[];
    label: string;
    helperText?: string;
}

const SelectV2: React.FC<SelectProps> = ({ options, label, helperText, ...props }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    return (
        <div className={`flex flex-col w-full relative gap-1 ${props.disabled ? 'pointer-events-none select-none': ''}`}>
            {label && ( <div className={classes.label}>{label}</div> )}
            <MuiSelect
                id={`select-${label}`}
                label=""
                defaultValue=""
                defaultChecked={true}
                {...props}
                className={classes.input}
                displayEmpty
                renderValue={() => { return  (props.value && typeof props.value == 'string') ? (props.value as string)?.split("_")?.map((el: string) => capitalizeFirstLetter(el)).join(" ") : <span className={`${classes.placeholder} !font-normal `}>{props.placeholder}</span> }}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                IconComponent={(props) => ( <DownArrow {...props} className={`${classes.icon} ${open ? classes.iconOpen : ''}`} /> )}
            >
                {options.map(option => (
                    <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                ))}
            </MuiSelect>
            {props.error && <ErrorMessage message={helperText} />}
        </div>
    );
};

export default SelectV2;