import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { TablePagination, Input } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { useTheme } from '@mui/material/styles';
import { ICategory } from "../../pages/CatalogueList/CatalogueList.page";
import GridView from "../../organisms/GridView/GridView.organism";
import CatalogueGridItemSkeleton from "../CatalogueList/CatalogueGridItemSkeleton.template";
import NoProduct from "../../molecules/NoProduct/NoProduct";
import StoreFrontGridItem from "./StoreFrontGridItem.template";

interface CatalogueListTemplateProps {
    category: ICategory | null;
    catalogues: Catalogue[];
    onPaginationChange: (event: any, currentPage: number) => void;
    handleChangeRowsPerPage: (event: any) => void;
    isLoading: boolean;
    pagination: IPagination;
}

export interface Catalogue {
    id: number;
    name: string;
    brand: string;
    catalogueImagePath: string[];
    attributes: {
        [key: string]: string[];
    };
}
interface IPagination {
    size: number;
    totalRecords: number;
    page: number;
}

export interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (
        event: React.MouseEvent<HTMLButtonElement>,
        newPage: number,
    ) => void;
}

const gridTemplate = {
    xs: 12,
    sm: 6,
    md: 6,
    lg: 4,
    xl: 4,
};

const useStyles = createUseStyles((theme: any) => ({
    paginationTable: {
        "& .MuiTablePagination-selectLabel": {
            color: "#333",
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "20.3px",
            letterSpacing: "0.21px"
        },
        "& .MuiTablePagination-input": {
            borderRadius: '8px',
            border: `1px solid ${theme.palette.border.neutral.neutral200}`,
            width: '80px',
            paddingRight: '10px',
            marginRight: "24px",
            height: "30px"
        },
        "& .MuiTablePagination-displayedRows": {
            color: "#333",
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "20.3px",
            letterSpacing: "0.21px",
        },
        "& .MuiTablePagination-spacer": {
            flex: 0
        },
        "& .MuiToolbar-root": {
            paddingLeft: "0px !important",
            paddingRight: "0px",
            width: "100%"
        },
    },
    paginationComponent: {
        color: theme.palette.text.neutral.neutral700,
        fontWeight: 500,
        fontSize: "14px",
        width: "100%",
        justifyContent: 'end',
    },
    pageButton: {
    },
    "@media (max-width: 480px)": {
        paginationTable: {
            width: '100%',
            display: 'flex',
            '& .MuiTablePagination-selectLabel': {
                display: 'none',
            },
            "& .MuiTablePagination-displayedRows": {
                display: 'none',
            },
            "& .MuiTablePagination-input": {
                display: 'none',
            },
        },
        paginationComponent: {
            color: theme.palette.text.neutral.neutral700,
            fontWeight: 500,
            fontSize: "14px",
            width: "100%",
            justifyContent: 'space-between'
        },
        pageButton: {
            columnGap: "12px",
            "& .MuiButtonBase-root": {
                padding: "0px"
            }
        }
    }
}));

const StoreFrontTemplate: React.FC<CatalogueListTemplateProps> = ({
    category,
    catalogues,
    onPaginationChange,
    handleChangeRowsPerPage,
    pagination,
    isLoading
}) => {
    const classes = useStyles();

    function TablePaginationActions(props: TablePaginationActionsProps) {
        const theme = useTheme();
        const { count, page, rowsPerPage, onPageChange } = props;
        const [inputPage, setInputPage] = useState(page + 1);

        const handleFirstPageButtonClick = (
            event: React.MouseEvent<HTMLButtonElement>,
        ) => {
            onPageChange(event, 0);
        };

        const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            onPageChange(event, page - 1);
        };

        const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            onPageChange(event, page + 1);
        };

        const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        };

        const handleInputPageChange = (event: any) => {
            setInputPage(parseInt(event.target.value, 10));

        };
        const handleInputBlur = (event: any) => {
            onPageChange(event, inputPage - 1);
        };

        return (
            <div className={`flex gap-x-6 ${classes.paginationComponent}`}  >
                <div className="flex gap-x-2.5">
                    <div className='my-auto'>Page</div>
                    <div className='my-auto'>
                        <Input
                            type="number"
                            value={inputPage}
                            onChange={handleInputPageChange}
                            onBlur={handleInputBlur}
                            disableUnderline={true}
                            inputProps={{ min: 1, max: Math.ceil(count / rowsPerPage) }}
                            style={{ width: '54px', height: "28px", borderRadius: '8px', border: '1px solid #E6E6E6', paddingLeft: '16px' }}
                        />
                    </div>
                    <div className='my-auto'>of {Math.ceil(count / rowsPerPage)}</div>
                </div>

                <div className={`${classes.pageButton} flex`}>
                    <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
                        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
                    </IconButton>
                    <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                    </IconButton>
                    <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
                        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                    </IconButton>
                    <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
                        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
                    </IconButton>
                </div>
            </div>
        );
    }

    return (
        <div className="grid gap-y-4">
            {isLoading ? (
                <GridView spacing={2} items={[null, null, null, null]} itemComponent={(item) => <CatalogueGridItemSkeleton />} gridTemplate={gridTemplate} />
              
            ) : catalogues.length > 0 ? (
                <>
                    <GridView spacing={2} items={catalogues} itemComponent={(item) => <StoreFrontGridItem data={item} />} gridTemplate={gridTemplate} />
                    <TablePagination
                        className={`${classes.paginationTable} w-full flex`}
                        component="div"
                        rowsPerPageOptions={[12, 24, 36]}
                        count={pagination.totalRecords}
                        page={pagination.page}
                        onPageChange={onPaginationChange}
                        rowsPerPage={pagination.size}
                        ActionsComponent={TablePaginationActions}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage="Rows per page"
                    />
                </>
            ) : (
                <div className="mt-14">
                    <NoProduct text="No Products Available" variant="Product List" />
                </div>
            )}
        </div>
    );
};
export default StoreFrontTemplate;
