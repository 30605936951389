import React, { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss';
import Tabs, { ITabsSchema } from '../../molecules/Tabs/Tabs';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { HTTP_STATUS, INVENTORY_MANAGEMENT_LISTING } from '../../../utils/types';
import Button from '../../atoms/Button/Button';
import { Label } from '@mui/icons-material';
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';
import { usePostProductService } from '../../../services/usePostProductService';
import { IVendorStats, initialStatsValue } from '../PostProduct/PostProductList.page';
import ComingSoon from '../../atoms/ComingSoon/ComingSoon';
import TabsV2 from '../../molecules/TabsV2/TabsV2';
import InventoryMangementTemplate from '../../template/DashBoard/Inventory Management/InventoryMangement.template';


export interface IInventoryManagementForm {
  skuCode: string;
  productName: string;
  noOfUsn: number | null;
  availableStocks: number | null;
  freeStocks: number | null;
  minimumOrderQuantity: number | null;
  deliveryTime: number | null;
  status: string;
}

export interface IInventoryManagementProps {
  activeTab : string;
  stats: number;
}

const useStyles = createUseStyles((theme: any) => ({
  section: {
    color: theme.palette.text.primary.primary900,
  }
}));
const InventoryManagementPage: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState(INVENTORY_MANAGEMENT_LISTING.STANDARD_CATALOGUE);
  const [inventoryStats, setInventoryStats] = useState<IVendorStats>(initialStatsValue);
  const productService = usePostProductService();
  const { user } = useAuthenticatedUser();

  const loadVendorInventoryStats = () => {
      if (user?.businessId) {
          productService.getVendorProductStats(user?.businessId, "INVENTORY")
              .then(res => {
                  if (res?.status === HTTP_STATUS.OK) {
                    setInventoryStats(res?.data?.data);
                  }
              }).catch((error) => {
                  console.error("Error Fetching Stats: ", error);
              });
      }
  }

  useEffect(() => {
    if (isNaN(activeTab)) return;
    const level = Object.keys(INVENTORY_MANAGEMENT_LISTING).filter(key => isNaN(parseInt(key)))[activeTab - 1];
    setSearchParams((params: URLSearchParams) => {
      params.set('type', level)
      return params;
    })
  }, [activeTab])

  useEffect(() => {
    loadVendorInventoryStats();
  }, [])


  const schema: ITabsSchema[] = [
    { label: `Standard `, component: <InventoryMangementTemplate activeTab={"STANDARD"} stats={inventoryStats?.STANDARD ?? 0}/> },
    { label: `Non Standard`, component: <ComingSoon /> },
    { label: 'Commercial Scrap', component:  <ComingSoon /> },
    { label: 'Melting Scrap', component:  <ComingSoon /> }
  ]

  return (
    <div className={"grid gap-y-6 w-full "}>
      <div className='flex '>
        <div className={`${classes.section} text-lg font-semibold w-full`}>Inventory Management</div>
        <Button
          className={`w-1/6 `}
          variant="primaryContained"
          label="+Add USN"
          onClick={() => { navigate('/dashboard/inventory-management/add'); }}
        />
      </div>
      <div className='w-full '>
        <TabsV2 schema={schema} value={(activeTab).toString()} setValue={setActiveTab} />
      </div>
    </div>
  )
}

export default InventoryManagementPage