import React from 'react';
import { createUseStyles } from 'react-jss';
import footerLogo from '../../../../src/assets/images/footerLogo.svg';
import TextField from '../../atoms/Input/TextField';
import rightArrow from '../../../assets/icons/rightArrowButton.svg';
import TextFieldV2 from '../../atoms/Input/TextFieldV2';
import { CUSTOMER_ROUTES, REGEX, STATIC_PAGES_ROUTES } from '../../../utils/constant';
import { useEmailSubscriptionService } from '../../../services/useEmailService';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { EMAIL_SUBSCRIPTION_CONTEXT } from '../../../utils/types';
import { Link, useNavigate } from 'react-router-dom';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        background: theme.palette.background.primary.primary50,
        padding: "56px 70px",
    },
    topContainer: {
        borderBottom: `1px solid ${theme.palette.border.primary.primary500}`,
    },
    footerHeading: {
        fontSize: '40px',
        color: theme.palette.text.primary.primary900,
        lineHeight: '48px',
    },
    btn: {
        background: theme.palette.background.primary.primary500,
        color: "white",
        borderRadius: '50px',
        height: '48px',
        width: '152px',
    },
    inputSearch: {
        border: `0.8px solid ${theme.palette.border.primary.primary500}`,
        borderRadius: '45px',
        overflow: 'hidden',
        background: 'white',
        width: '512px',
        "& .MuiInputBase-root": {
            border: 'none',
            background: '#fff',
            width: '350px',
        },
    },
    middleContainer: {
        // Additional styles for middle container (if needed)
    },
    tagLine: {
        color: theme.palette.text.primary.primary800,
        lineHeight: '16px',
    },
    companyName: {
        color: theme.palette.text.primary.primary800,
        lineHeight: 'normal',
    },
    address: {
        color: theme.palette.text.primary.primary900,
        lineHeight: '16px',
    },
    footerContent: {
        "& ul": {
            "& li": {
                position: 'relative',
                color: theme.palette.text.primary.primary500,
                fontSize: '14px',
                fontWeight: '500',
                marginBottom: '8px',
                textDecoration: 'none',
                transition: 'padding-left 0.3s ease',
                "&::before": {
                    content: `''`,
                    position: 'absolute',
                    left: '-5px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    width: '20px',
                    height: '20px',
                    background: `url(${rightArrow}) no-repeat center center`,
                    backgroundSize: 'contain',
                    opacity: 0,
                    transition: 'opacity 0.3s ease, left 0.3s ease',
                },
                "&:hover": {
                    paddingLeft: '25px',
                    color: theme.palette.text.primary.primary900,
                },
                "&:hover::before": {
                    left: '0',
                    opacity: 1,
                },
                "& a": {
                    textDecoration: 'none',
                    color: 'inherit',
                },
            },
        },
    },
    sectionTitle: {
        color: theme.palette.text.primary.primary600,
        lineHeight: "20px",
    },
    bottomContainer: {
        borderTop: `1px solid ${theme.palette.border.primary.primary500}`,
    },
    copyRight: {
        color: theme.palette.text.primary.primary800,
    },
    text: {
        color: theme.palette.text.primary.primary500,
        '&:hover': {
            color: theme.palette.text.primary.primary900,
        },
    },
}));

const footerSections = [
    {
        title: 'Sell on SteelBazaar',
        items: [
            { name: 'Sell on SteelBazaar', route: '/sell-on-steelbazaar' },
            { name: 'Participate in RFQs', route: '/sell/rfqs' },
            { name: 'Create Sale Auction', route: '/sell/sale-auction' },
            { name: 'Post Your Lead', route: '/sell/post-lead' },
            { name: 'Become Verified Seller', route: '/sell/verified-seller' },
            { name: 'Channel Partners', route: '/sell/channel-partners' }
        ]
    },
    {
        title: 'Buy on SteelBazaar',
        items: [
            { name: 'Search and Find', route: '/buy-on-steelbazaar' },
            { name: 'Compare the Brands', route: '/buy-on-steelbazaar' },
            { name: 'Pool and Save', route: '/buy-on-steelbazaar' },
            { name: 'Post Your Lead', route: '/buy-on-steelbazaar' }
        ]
    },
    {
        title: 'Other Services',
        items: [
            { name: 'Manufacturing', route: '/services' },
            { name: 'Project Management', route: '/services' },
            { name: 'Customization', route: '/services' },
            { name: 'Fabrication', route: '/services' },
            { name: 'Dismantling', route: '/services' }
        ]
    },
    {
        title: 'Support and Care',
        items: [
            { name: 'Help Centre', route: '/support-and-care' },
            { name: 'Live Chat', route: '/support-and-care' },
            { name: 'Whatsapp Us', route: '/support-and-care' },
            { name: 'Order Status', route: '/support-and-care' },
            { name: 'Refunds', route: '/support-and-care' },
            { name: 'Complaints', route: '/support-and-care' }
        ]
    },
    {
        title: 'Contact Us',
        items: [
            { name: 'About Us', route: '/about-us' },
            { name: 'Careers with Us', route: '/contact-us' },
            { name: 'News Centre', route: '/contact-us' },
            { name: 'Lending Partners', route: '/contact-us' }
        ]
    }
];

const validationSchema = Yup.object().shape({
    email: Yup.string().required('Please enter your email address').matches(REGEX.EMAIL, 'Invalid email address'),
});

const initialValues = {
    email: ''
};

const FooterTemplate: React.FC = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const emailSubscriptionService = useEmailSubscriptionService();
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const formik = useFormik({
        initialValues,
        validationSchema,
        validateOnMount: true,
        onSubmit: (values, { setSubmitting, setFieldError, resetForm }) => {
            setSubmitting(true);
            emailSubscriptionService.createEmailSubscription({ email: values.email, context: EMAIL_SUBSCRIPTION_CONTEXT.EMAIL_SUBSCRIPTION })
                .then(res => {
                    showSnackbar('success', 'Successfully subscribed to newsletter');
                }).catch(err => {
                    setFieldError('email', 'Failed to subscribe this email');
                    showSnackbar('error', 'Failed to subscribe to newsletter');
                }).finally(() => {
                    setSubmitting(false);
                });
            resetForm();
        },
    });

    const handleClick = (route: string) => {
        navigate(route);
    };

    return (
        <>
            <div className={`${classes.container}`}>
                <div className={`${classes.topContainer} flex justify-between pb-10`}>
                    <div className={`${classes.footerHeading} font-semibold`}>Subscribe to our Newsletter</div>
                    <form onSubmit={formik.handleSubmit}>
                        <div className={`${classes.inputSearch} flex justify-between items-center h-fit w-80`}>
                            <TextField
                                fullWidth
                                className='!py-0 !px-0 !w-80'
                                placeholder='Enter your Email'
                                {...formik.getFieldProps("email")}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && Boolean(formik.errors.email) && formik.errors.email}
                            />
                            <button className={`${classes.btn} !text-base !mr-1 flex justify-center items-center`} type='submit'>Submit</button>
                        </div>
                    </form>
                </div>

                <div className={`${classes.middleContainer} py-10 flex gap-x-2 justify-between`}>
                    <div className='grid gap-y-8 h-fit'>
                        <div className='grid gap-y-3'>
                            <img className='w-[204px]' src={footerLogo} alt="footerLogo" />
                            <div className={`${classes.tagLine} text-sm font-semibold`}>Smart Trade With Technology</div>
                        </div>

                        <div className='grid gap-y-2 h-fit'>
                            <div className={`text-sm font-bold ${classes.companyName}`}>FutureCom Technologies Pvt. Ltd.</div>
                            <div className={`${classes.address} grid gap-y-1`}>
                                <div className={`text-sm font-medium`}>Registered Office</div>
                                <div className={`text-sm`}>710, Ring Road Mall, Manglam Palace, Rohini, New Delhi 85</div>
                            </div>
                        </div>
                    </div>

                    {footerSections.map((section, index) => (
                        <div key={index}>
                            <div className={`${classes.sectionTitle} font-bold text-sm mb-5`}>{section.title}</div>
                            <div className={`${classes.footerContent} grid gap-y-2`}>
                                <ul>
                                    {section.items.map((item, idx) => (
                                        <li key={idx} onClick={() => handleClick(item.route)}>
                                            <Link to={item.route}>{item.name}</Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    ))}
                </div>

                <div className={`${classes.bottomContainer} flex justify-between pt-2.5 items-center`}>
                    <div className={`${classes.copyRight} text-sm font-bold`}>© 2024 FutureCom Technologies Pvt. Ltd., All Rights Reserved</div>
                    <div className={`${classes.footerContent} flex gap-x-6`}>
                        <ul className='flex gap-3'>
                            <li><Link to={STATIC_PAGES_ROUTES.TERMS_AND_CONDITIONS} className={`${classes.text} cursor-pointer px-1 py-2.5`}>Terms & Conditions</Link></li>
                            <li><Link to={STATIC_PAGES_ROUTES.PRIVACY_POLICY} className={`${classes.text} cursor-pointer px-1 py-2.5`}>Privacy Policy</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FooterTemplate;
