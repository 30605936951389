import * as React from 'react';
import * as yup from "yup";
import { createUseStyles } from 'react-jss';
import Button from '../../atoms/Button/Button';
import { useFormik } from 'formik';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { useEffect } from 'react';
import { useBusinessProfileService } from '../../../services/useBusinessProfileService';
import { INDUSTRY_TYPES } from '../../../utils/constant';
import TextFieldV2 from '../../atoms/Input/TextFieldV2';
import SelectV2 from '../../atoms/Select/SelectV2';
import { convertDate } from '../../../utils/helper';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid ${theme.palette.border.neutral.neutral100}`,
    },
    container_heading: {
        color: theme.palette.text.primary.primary900,
    },
    borderBottom: {
        borderBottom: `1px solid ${theme.palette.border.neutral.neutral100}`,
    }
}));

export interface otherInformation {
    dateOfEstablishment: number | string;
    totalEmployees: number;
    websiteUrl: string;
    industryType: string;
    businessType: string;
    brand: string;
}

interface OtherInformationEditTemplateProps {
    otherInformationformik?: any;
    updateBusinessProfileSection: any;
    businessProfile: any;
    onSuccess: () => void;
    onBack: () => void;
}

const OtherInformationEditTemplate: React.FC<OtherInformationEditTemplateProps> = ({ updateBusinessProfileSection, businessProfile, onSuccess, onBack }) => {
    const classes = useStyles();
    const bussinessProfileService = useBusinessProfileService();
    const { showSnackbar } = useSnackbar();

    const otherInformationSchema = yup.object().shape({
        dateOfEstablishment: yup.string(),
        totalEmployees: yup.number().required('Number of Employees is required'),
        websiteUrl: yup.string().required('Website URL is required'),
        industryType: yup.string().required('Industry Type is required'),
        businessType: yup.string().required('Business Type is required'),
        brand: yup.string().required('Brand is required'),
    });

    const handleSubmit = () => {
        otherInformationformik.handleSubmit();
    };
    
    const otherInformationformik = useFormik<otherInformation>({
        initialValues: {
            dateOfEstablishment: businessProfile?.dateOfEstablishment || 0,
            totalEmployees: businessProfile?.totalEmployees || 0,
            websiteUrl: businessProfile?.websiteUrl || '',
            industryType: businessProfile?.industry || '',
            businessType: businessProfile?.businessType || '',
            brand: businessProfile?.brand || '',
        },
        validationSchema: otherInformationSchema,
        onSubmit: async (values: any, { setSubmitting }) => {
            try {
                setSubmitting(true);
                const requestBody = {
                    dateOfEstablishment: values?.dateOfEstablishment,
                    totalEmployees: values?.totalEmployees,
                    websiteUrl: values?.websiteUrl,
                    industryType: values?.industryType,
                    businessType: values?.businessType,
                    brand: values?.brand,
                }
                updateBusinessProfileSection("OTHER_INFORMATION", requestBody)
                showSnackbar("success", "Business profile updated successfully");
                onSuccess();
            } catch (error) {
                showSnackbar("error", `Business profile update failed: ${error}`);
            } finally {
                otherInformationformik.setSubmitting(false);
            }
        },
    });
    
    const handleUpdateDetails = (field: string, value: any) => {
        otherInformationformik.setFieldValue(field, value);
    };
    
    useEffect(() => {
    }, [otherInformationformik]);

    const businessType = [
        { id: 'Distributor', name: 'Distributor' },
        { id: 'Partnership', name: 'Partnership' },
        { id: 'Limited Liability Company', name: 'Limited Liability Company' },
        { id: 'Sole proprietorship', name: 'Sole proprietorship' },
    ];

    return (
        <form onSubmit={otherInformationformik.handleSubmit}>
              <div className={`${classes.container} p-4 grid gap-y-6 rounded-xl w-full`}>
                <div className={`flex justify-between items-center pb-6 ${classes.borderBottom}`}>
                    <h2 className={`${classes.container_heading} text-2xl font-medium`}>Other Information</h2>
                    <div className='flex gap-4'>
                        <Button variant={'tertiaryContained'} label={'Back'} type="button" onClick={onBack} />
                        <Button variant={'primaryContained'} label={'Save'} type="submit" />
                    </div>
                </div>
                <div className='grid gap-y-6'>
                    <div className='grid grid-cols-2 gap-x-6'>
                        <TextFieldV2
                            label="Year of Establishment"
                            fullWidth
                            value={convertDate(businessProfile?.dateOfEstablishment)}
                            error={
                                otherInformationformik.touched?.dateOfEstablishment &&
                                Boolean(otherInformationformik.errors?.dateOfEstablishment)
                            }
                            inputProps={{
                                readOnly: true
                              }}
                        />
                        <TextFieldV2
                            label="Number of Employees"
                            fullWidth
                            {...otherInformationformik.getFieldProps("totalEmployees")}
                            error={
                                otherInformationformik.touched?.totalEmployees &&
                                Boolean(otherInformationformik.errors?.totalEmployees)
                            }
                            inputProps={{
                                readOnly: false
                            }}
                            required
                            helperText={(otherInformationformik.touched?.totalEmployees &&
                                Boolean(otherInformationformik.errors?.totalEmployees)) && otherInformationformik.errors?.totalEmployees}
                        />
                    </div>
                    <div className='grid grid-cols-3 gap-x-6'>
                        <SelectV2
                            label='Industry Type'
                            fullWidth
                            {...otherInformationformik.getFieldProps("industryType")}
                            error={
                                otherInformationformik.touched?.industryType &&
                                Boolean(otherInformationformik.errors?.industryType)
                            }
                            options={
                                INDUSTRY_TYPES?.map((industryData) => ({
                                    value: String(industryData.value),
                                    label: industryData.label,
                                })) || []
                            }
                        />
                        <SelectV2
                            label='Business Type'
                            fullWidth
                            {...otherInformationformik.getFieldProps("businessType")}
                            error={
                                otherInformationformik.touched?.businessType &&
                                Boolean(otherInformationformik.errors?.businessType)
                            }
                            options={
                                businessType?.map((businessType) => ({
                                    value: String(businessType.id),
                                    label: businessType.name,
                                })) || []
                            }
                        />
                        <TextFieldV2
                            label="Brand"
                            fullWidth
                            {...otherInformationformik.getFieldProps("brand")}
                            error={
                                otherInformationformik.touched?.brand &&
                                Boolean(otherInformationformik.errors?.brand)
                            }
                            helperText={(otherInformationformik.touched?.brand &&
                                Boolean(otherInformationformik.errors?.brand)) && otherInformationformik.errors?.brand}
                            inputProps={{ readOnly: false }}
                        />
                    </div>

                    <TextFieldV2
                        label="Website URL"
                        fullWidth
                        {...otherInformationformik.getFieldProps("websiteUrl")}
                        error={
                            otherInformationformik.touched.websiteUrl &&
                            Boolean(otherInformationformik.errors.websiteUrl)
                        }
                        helperText={(otherInformationformik.touched?.websiteUrl &&
                            Boolean(otherInformationformik.errors?.websiteUrl)) && otherInformationformik.errors?.websiteUrl}
                        inputProps={{ readOnly: false }}
                    />
                </div>
            </div>
        </form>
    );
};

export default OtherInformationEditTemplate;