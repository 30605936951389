import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme: any) => ({
    container: {
    },
    primaryContainer: {
       width: '1297px',
       margin: '0 auto',
       background: "#fff",
       
    },
    categories: {
        "& h3": {
            color: theme.palette.text.neutral.neutral600,
        }
    },
    categoriesContent: {
        borderTop : `1px solid ${theme.palette.border.neutral.neutral200}`,
        "& ul": {
            "& li": {
                color: theme.palette.text.primary.primary500,
            },
        },
    },
}));

const categoriesData = [
    {
        title: "CARBON FLAT ROLLED",
        items: ["Hot Rolled", "Cold Rolled", "Coated Products"]
    },
    {
        title: "CARBON LONG PRODUCTS",
        items: ["Construction Steel", "Angle", "Channel", "Beams", "Pipes and Tubes"]
    },
    {
        title: "STAINLESS",
        items: ["Stainless Hot Rolled", "Stainless Cold Rolled"]
    },
    {
        title: "RAW MATERIAL",
        items: ["Iron Ore", "Coal", "Scraps"]
    },
    {
        title: "SEMI FINISHED",
        items: ["Sponge", "Billets", "Pig Iron"]
    }
];

const CategoriesDropdown: React.FC = () => {
    const classes = useStyles();

    return (
        <div className={`${classes.container} py-10`}>
            <div className={`${classes.primaryContainer} flex justify-between px-1 py-5`}>
                {categoriesData.map((category, index) => (
                    <div className={`${classes.categories}`} key={index}>
                        <h3 className='text-lg mb-4 font-normal'>{category.title}</h3>
                        <div className={classes.categoriesContent}>
                            <ul className='mt-10'>
                                {category.items.map((item, idx) => (
                                    <li className='mb-6' key={idx}><a href="" className='text-lg font-medium'>{item}</a></li>
                                ))}
                            </ul>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CategoriesDropdown;
