import WhatsAppIcon from "../../../assets/icons/whatsapp.svg";
import { Link } from 'react-router-dom';
import { NUMBER_URL } from "../../../utils/constant";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: any) => ({
    img: {
        padding: "13px",
        backgroundColor: theme.palette.background.primary.primary100,
    },
    "@media (max-width: 480px)": {
        img: {
            padding: "12px",
        }
    },
}));

const Whatsapp: React.FC = () => {
    const classes = useStyles();
    return (
        <Link to={NUMBER_URL.WHATSAPP} target="_blank" rel="noopener noreferrer" style={{ margin: 0 }}>
            <img src={WhatsAppIcon} alt="icon" className={`${classes.img} cursor-pointer rounded-xl`} />
        </Link>
    )
}

export default Whatsapp;