import React, { ReactNode } from 'react'
import { createUseStyles } from 'react-jss';
import steelBazaarLogo from "../../../assets/images/steelBazaarLogo.svg"
import tin from "../../../assets/images/onBoardingTin.jpg"
import { ONBOARDING_STATES } from '../../../utils/types';
import { useNavigate } from 'react-router-dom';

interface OnBoardingInformationTemplateProps {
    children: ReactNode;
    formState: ONBOARDING_STATES;
    setFormState: (value: ONBOARDING_STATES) => void;
};

const useStyles = createUseStyles((theme: any) => ({
    leftContainer:{
        width: "59.47%",
        padding: "24px 70px 0px 70px"
    },
    rightContainer: {
        backgroundColor: theme.palette.background.neutral.neutral50,
        width: "41.53%"
    },
    headingContainer: {
        padding: "120px 186px 150px 70px"
    },
    complete: {
        color: theme.palette.text.neutral.neutral800,
        fontSize: "32px",
        lineHeight: "38px",
    },
    onboarding: {
        color: theme.palette.text.primary.primary600,
        lineHeight: "58px"
    },
    description: {
        color: theme.palette.text.neutral.neutral800,
        lineHeight: "28px",
    },
    sectionText: {
        color: theme.palette.text.primaryDark
    },
    card: {
        background: theme.palette.background.neutral.neutral100,
        height: "538px",
        borderRadius: "32px"
    },
    dullText: {
        color: theme.palette.text.secondaryLightAccent
    },
}))

const OnBoardingInformationTemplate: React.FC<OnBoardingInformationTemplateProps> = ({ children, formState, setFormState }) => {
    const classes = useStyles();
    const navigate = useNavigate();

    // const getStateIcon = (state: ONBOARDING_STATES, subState?: ONBOARDING_STATES) => {
    //     let checkStatus = (state === formState || subState === formState) ? checkActive : (state < formState) ? checkCompleted : (state > formState) ? checkInactive : checkActive;
    //     return <img src={checkStatus} alt={`${checkStatus}`} />
    // }

    const getStateIcon = (state: ONBOARDING_STATES, subState?: ONBOARDING_STATES) => {
        let checkStatus = (state === formState || subState === formState)
            ? 'active'
            : (state < formState)
                ? 'completed'
                : 'inactive';

        let backgroundColor;
        switch (checkStatus) {
            case 'completed':
                backgroundColor = '#849AE1';
                break;
            case 'active':
                backgroundColor = '#2743A0';
                break;
            case 'inactive':
                backgroundColor = '#EAEEFA';
                break;
            default:
                backgroundColor = '#EAEEFA';
        }

        return (
            <div className='w-full h-2.5 rounded' style={{ backgroundColor: backgroundColor }} />
        );
    }

    const getCurrentSectionName = (state: ONBOARDING_STATES) => {
        return ['Business Profile', 'Business Profile', 'Entity Type', 'Product', 'Requirements'][state - 1]
    }

    const getCurrentStep = (state: ONBOARDING_STATES) => state === 1 ? state : state - 1;

    const getSectionProgress = (state: ONBOARDING_STATES) => ((getCurrentStep(state) / 4) * 100) - 25;

    const onBack = () => {
        setFormState(formState - 1)
    }

    const handleSkip = () => {
        navigate('/');
    }

    return (
        <div className='w-full h-full flex'>
            {/* <div className={`w-3/5 flex justify-center overflow-y-scroll`} >
                <div className='w-8/12 flex flex-col gap-8'>
                    <div className='flex justify-between mt-2'>
                        <div>
                            {formState !== 1 && <span className={`flex items-center cursor-pointer text-xl ${classes.sectionText}`} onClick={onBack}><ArrowBackIosIcon fontSize='small' />Back</span>}
                        </div>
                        {formState !== 1 && <span className={`${classes.sectionText} text-xl cursor-pointer`} onClick={handleSkip}>Skip for now</span>}
                    </div>
                    <div className='flex flex-col gap-y-2 w-full text-lg '>
                        <div className={`flex gap-1`}>
                            <span className={`font-light ${classes.dullText}`} >Steps</span>
                            <span>{getCurrentStep(formState)}/4</span>
                        </div>
                        <div className={` ${classes.sectionText} flex justify-between text-xl`}>
                            <span className='flex gap-1 items-center capitalize'><BusinessCenterOutlinedIcon fontSize='medium' />{getCurrentSectionName(formState)}</span>
                            <span >{getSectionProgress(formState)}%</span>
                        </div>
                        <div className='mt-3 flex items-center'>
                            <div>{getStateIcon(ONBOARDING_STATES.GST_INFORMATION, ONBOARDING_STATES.BUSINESS_DETAILS)}</div>
                            <span className={`flex-1 border-t ${classes.sectionText}`}></span>
                            <div>{getStateIcon(ONBOARDING_STATES.ENTITY_TYPE)}</div>
                            <span className={`flex-1 border-t ${classes.sectionText}`}></span>
                            <div>{getStateIcon(ONBOARDING_STATES.PRODUCT)}</div>
                            <span className={`flex-1 border-t ${classes.sectionText}`}></span>
                            <div>{getStateIcon(ONBOARDING_STATES.REQUIREMENTS)}</div>
                        </div>
                    </div>
                    <div className='pb-16 border'>
                        {children}
                    </div>
                </div>
            </div> */}


            <div className={`grid gap-y-10 w-full h-fit ${classes.leftContainer}`} >
                <img src={steelBazaarLogo} alt="SteelBazaar" />
                <div className='grid grid-cols-5 gap-x-3 w-full mt-4'>
                    <div>{getStateIcon(ONBOARDING_STATES.GST_INFORMATION)}</div>
                    <div>{getStateIcon(ONBOARDING_STATES.BUSINESS_DETAILS)}</div>
                    <div>{getStateIcon(ONBOARDING_STATES.ENTITY_TYPE)}</div>
                    <div>{getStateIcon(ONBOARDING_STATES.PRODUCT)}</div>
                    <div>{getStateIcon(ONBOARDING_STATES.REQUIREMENTS)}</div>
                </div>
                {children}
            </div>

            <div className={`h-full ${classes.rightContainer}`}>
                <div className={`${classes.headingContainer} flex flex-col`}>
                    <span className={`${classes.complete} font-medium`}>Complete</span>
                    <span className={`${classes.onboarding} font-semibold text-5xl mt-1`}>Onboarding</span>
                    <p className={`${classes.description} text-2xl mt-6`}>Your Ultimate Destination for Steel Raw Material Excellence</p>
                </div>
                <img src={tin} alt="TIN KA DABBA" className='w-full' />
            </div>
        </div>
    )
}

export default OnBoardingInformationTemplate