import React, { useEffect } from 'react'
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router-dom';
import { FormHelperText } from '@mui/material';
import { ADD_VENDOR_PRICE_STATES, CLASS_STATES } from '../../../utils/types';
import CustomRadioGroup from '../../molecules/CustomRadioGroup/CustomRadioGroup';
import Button from '../../atoms/Button/Button';
import { IVendorProductPriceSectionProps, IVendorProductPriceForm } from '../../pages/VendorManagement/VendorProductPriceCreate.page';
import { CUSTOMER_ROUTES, VENDOR_PRODUCT_CLASS_TYPE } from '../../../utils/constant';

const useStyles = createUseStyles((theme: any) => ({
    webContainer: {
        display: "grid"
    },
    activatedProgressBar: {
        backgroundColor: theme.palette.background.primary.primary500
    },
    UnTouchProgressBar: {
        backgroundColor: theme.palette.border.neutral.neutral100
    },
    barHeading: {
        color: theme.palette.text.primary.primary500
    },
    textHeading:{
        color: theme.palette.text.primary.primary900
    },
    selectBox:{
        border: `1px solid ${theme.palette.background.neutral.neutral100}`,
    },
    search: {
        margin: "0",
        background: theme.palette.background.neutral.neutral400,
    },
    searchIcon: {
        color: theme.palette.background.neutral.neutral400
    },
    subHeading: {
        color: theme.palette.text.neutral.neutral600
    },
    textHeading2: {
        color: theme.palette.text.primary.primary900
    },
    radioColor: {
        color: theme.palette.text.primary.primary900,
        width: '23%'
    }
}));

const SelectVendorProductTypeTemplate: React.FC<IVendorProductPriceSectionProps> = ({ formik, setCurrentSectionTo }) => {
    const classes = useStyles();
    const navigate = useNavigate();

    const handleCancel = () => {
        navigate(`${CUSTOMER_ROUTES.VENDOR_PRODUCT_PRICE_LISTING}`);
    };

    const onNext = (event: React.FormEvent) => {
        event.preventDefault();
        const requiredFields = ["productType"];
        let fieldError = false;
        for (const key of requiredFields) {
            if (key in formik.errors) {
                formik.setFieldTouched(key, true);
                fieldError = true;
            }
            if (formik.values.productType == '') {
                formik.setFieldTouched(key, true);
                fieldError = true;
            }
        }
        formik.setValues((prevValues: IVendorProductPriceForm) => ({
            ...prevValues,
        }));
        if (fieldError === true) return;
        setCurrentSectionTo(ADD_VENDOR_PRICE_STATES.PRODUCT);
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value == CLASS_STATES.STANDARD) {
            formik.setFieldValue("productType", event.target.value);
        }
    };

    useEffect(() => {
        document.getElementById("main")?.scroll(0, 0); 
    }, []);

    return (
        <div className={`${classes.webContainer} grid gap-y-8 `}>
            <div className={`w-full m-auto flex justify-between gap-x-2 text-var(--black, #4D4D4D) text-center text-sm `}>
                <div className="grid gap-y-3 w-1/2">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.activatedProgressBar} w-3 h-3 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto font-semibold`}>Select Product Type </div>
                    </div>
                    <div className={`${classes.activatedProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-1/2">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.UnTouchProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto`}>Select Product</div>
                    </div>
                    <div className={`${classes.UnTouchProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-1/2">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.UnTouchProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto`}>Add Pricing Details </div>
                    </div>
                    <div className={`${classes.UnTouchProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-1/2">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.UnTouchProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto`}>Review</div>
                    </div>
                    <div className={`${classes.UnTouchProgressBar} h-2.5 rounded `}></div>
                </div>
            </div>

            <div className=' grid gap-4'>
                <div className=' grid gap-1/2'>
                    <text className={`${classes.textHeading} text-base font-medium`}> Select Product Type</text>
                    <text className={`${classes.subHeading} text-xs font-normal`}> Please select the product type you want to add</text>
                </div>
                <div className={`${classes.selectBox} p-6 rounded-xl grid gap-4 border-3`}>
                    <text className={`${classes.textHeading2} text-base font-medium`}>Select Product Type</text>
                    <CustomRadioGroup
                        options={VENDOR_PRODUCT_CLASS_TYPE}
                        value={formik.values.productType}
                        onChange={handleChange}
                        className={`${classes.radioColor} `} />
                    {formik.touched?.productType && formik.errors?.productType &&
                        <FormHelperText error className='top-half '>{formik.errors?.productType}</FormHelperText>
                    }
                </div>
            </div>

            <div className='flex items-end gap-6 justify-self-end'>
                <Button
                    variant="tertiaryContained"
                    label="Cancel"
                    onClick={handleCancel}
                />
                <Button
                    variant="primaryContained"
                    label="Next"
                    onClick={onNext}
                />
            </div>
        </div>
    )
}

export default SelectVendorProductTypeTemplate