import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { createUseStyles } from 'react-jss';
import { useSnackbar } from '../../../../../hooks/useSnackBar';
import { USCPayload, useVASMasterCategoryService } from '../../../../../services/useVASMasterCategoryService';
import { GENERIC_EXCEPTION_CODE, MODE, STATUS, VALUE_ADDED_SERVICE_ROUTES } from '../../../../../utils/constant';
import { makeRoute } from '../../../../../utils/helper';
import { HTTP_STATUS } from '../../../../../utils/types';
import ReviewFormTemplate from '../../../../template/DashBoard/ValueAddedService/USC/ReviewForm.template';
import USCAndAttributeFormTemplate from '../../../../template/DashBoard/ValueAddedService/USC/USCAndAttributeDetailsForm.template';
import USCFormTemplate from '../../../../template/DashBoard/ValueAddedService/USC/USCForm.template';
import { useAuthenticatedUser } from '../../../../../hooks/useAuthenticatedUser';

export enum USC_FORM_STATE {
	USC_DETAILS = 1,
	REVIEW = 2
}

const useStyles = createUseStyles((theme: any) => ({
    mainHeading: {
        color: theme.palette.text.primary.primary900,
    },
}));

const USCCreatePage: React.FC = () => {
    const classes = useStyles();
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const { user } = useAuthenticatedUser();
    const vasMasterCategoryService = useVASMasterCategoryService();
    const navigate = useNavigate();

    const [formState, setFormState] = useState<USC_FORM_STATE>(1);

    const onClose = () => {
        navigate(makeRoute(VALUE_ADDED_SERVICE_ROUTES.VALUE_ADDED_SERVICE_LIST, { query: { type: 'USC' } }));
    }

    const validationSchema = Yup.object().shape({
        name: Yup.string().max(30, "USC Name cannot be more than 30 characters").required("USC Name is required"),
        description: Yup.string().max(500, "Description cannot be more than 500 characters").required("Description is required"),
        superService: Yup.object().shape({
            id: Yup.number().required('Super Service is required'),
            label: Yup.string().required('Super Service is required')
        }).required('Super Service is required'),
        mainService: Yup.object().shape({
            id: Yup.number().required('Main Service is required'),
            label: Yup.string().required('Main Service is required')
        }).required('Main Service is required'),
        productService: Yup.object().shape({
            id: Yup.number().required('Service Category is required'),
            label: Yup.string().required('Service Category is required')
        }).required('Service Category is required'),
        subAttributeIds: Yup.object()
        .test(
          'hasAtLeastOneKey',
          'Check at least one checkbox',
          value => value && Object.keys(value).length > 0
        )
        .required('Sub Attribute is required'),
    });

    const createUSC = async (usc: USCPayload) => {
        const requestBody: any = {
            serviceCategoryId: usc?.productServiceId,
            name: usc?.name,
            description: usc?.description,
            status: usc?.status,
            subAttributeIds: usc?.subAttributeIds,
            vendorId: user?.businessId,
        }
        vasMasterCategoryService.createUSC(requestBody)
        .then(res => {
            if (res.status === HTTP_STATUS.OK) {
                showSnackbar('success', "New USC Created");
                onClose();
            } else if (res.data.exceptionCode === GENERIC_EXCEPTION_CODE.DUPLICATE_ENTRY) {
                showSnackbar('error', "USC already exists. Please try again");
            }
        }).catch((error: any) => {
            showSnackbar('error', "Failed to create USC");
        })
    }

    const formik = useFormik<USCPayload>({
        initialValues: {
            name: "",
            uscCode: "",
            status: STATUS.ACTIVE,
            description: "",
            productServiceId: null,
            superService: {
                label: "",
                id: ""
            },
            mainService: {
                label: "",
                id: ""
            },
            productService: {
                label: "",
                id: ""
            },
            attributes:[],
            subAttributeIds: {}
        },
        validationSchema,
        validateOnMount: true,
        onSubmit: (values) => { createUSC(values) }
    })

    const setFormStateTo = (formState: USC_FORM_STATE) => () => {
        setFormState(formState)
    }

    const activeFormStep = useMemo(() => {
        const props = { mode: MODE.ADD, onClose, formik, setFormStateTo }
        switch (formState) {
            case USC_FORM_STATE.REVIEW: return <ReviewFormTemplate {...props} />;
            default: return <USCAndAttributeFormTemplate {...props} />;
        }
    }, [formState, formik])

    return (
        <div>
            {SnackBarComponent}
            <div className="grid gap-y-4" >
                <div className={`${classes.mainHeading} text-lg font-medium`}>Add USC</div>
                <USCFormTemplate {...{ mode: MODE.ADD, onClose, component: activeFormStep }} />
            </div>
        </div>
    )
}

export default USCCreatePage