import React, { useEffect, useMemo, useState } from 'react'
import { useFormik } from 'formik';
import { createUseStyles } from 'react-jss';
import * as Yup from "yup";
import { ADD_VENDOR_PRICE_STATES, HTTP_STATUS, IPagination, STATUS } from '../../../utils/types';
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { usePostProductService } from '../../../services/usePostProductService';
import { useVendorInventoryService } from '../../../services/useVendorInventoryService';
import { useNavigate, useParams } from 'react-router-dom';
import { IProductPrice, useVendorPriceService } from '../../../services/useVendorPriceSerivce';
import { CUSTOMER_ROUTES, REGEX, initialPaginationValues } from '../../../utils/constant';
import { IVendorProductPriceDetails, IVendorProductPriceForm, IVendorProductPriceSectionProps } from './VendorProductPriceCreate.page';
import moment from 'moment';
import AddPriceDetailTemplate from '../../template/VendorManagement/AddPriceDetail.template.';
import PriceManagementReviewTemplate from '../../template/VendorManagement/PriceManagementReview.template';

const useStyles = createUseStyles((theme: any) => ({
    titleText: {
        color: theme.palette.text.primary.primary950,
    },
    webContainer: {
        display: "grid"
    },
    activatedProgressBar: {
        backgroundColor: theme.palette.background.primary.primary500
    },
    UnTouchProgressBar: {
        backgroundColor: theme.palette.border.neutral.neutral100
    },
    barHeading: {
        color: theme.palette.text.primary.primary500
    },
    selectBox: {
        border: `1px solid ${theme.palette.background.neutral.neutral100}`,
    },
}));

const VendorProductPriceUpdatePage: React.FC = () => {

    const classes = useStyles();
    const { user } = useAuthenticatedUser();
    const navigate = useNavigate();
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const params = useParams();
    const productId = Number(params.id);

    const postProductService = usePostProductService();
    const vendorPriceService = useVendorPriceService();
    const vendorInventoryService = useVendorInventoryService();

    const [currentSection, setCurrentSection] = useState<ADD_VENDOR_PRICE_STATES>(ADD_VENDOR_PRICE_STATES.ADD_PRICE);
    const [pagination, setPagination] = useState<IPagination>(initialPaginationValues);
    const [productType, setProductType] = useState<string>('')
    const [warehouses, setWarehousesTo] = useState<{ id: number, name: string }[]>([{ id: 0, name: '' }]);
    const today = moment().startOf('day');

    const validationSchema = Yup.object().shape({
        productType: Yup.string().required('Product Type is required'),
        productId: Yup.number().required('Product ID is required').positive('Product ID must be a positive number').integer('Product ID must be an integer'),
        vendorProductPriceDetails: Yup.array().of(
            Yup.object().shape({
                marketPrice: Yup.number()
                    .required('Market Price is required')
                    .min(0, 'Market Price must be greater than or equal to zero.').test('is-decimal-precision', 'Market Price can only have up to two decimal places', (value) => {
                        return REGEX.TWO_DECIMAL.test(value.toString());
                    }),
                reserveSalesPrice: Yup.number()
                    .required('Reserve Sales Price is required')
                    .min(0, 'Reserve Sales Price must be greater than or equal to zero.')
                    .test('is-less-than-market', 'Reserve Sales Price must be less than Market Price', function (value) {
                        const { marketPrice } = this.parent;
                        return value <= marketPrice;
                    }).test('is-decimal-precision', 'Reserve Sales Price can only have up to two decimal places', (value) => {
                        return REGEX.TWO_DECIMAL.test(value.toString())
                    }),
                validityFrom: Yup.date().required('Price Validity From is required'),
                validityTo: Yup.date().required('Price Validity To is required').min(Yup.ref('validityFrom'), 'Validity To date must be after Validity From date.'),
            })
        )
    });

    const initialValues: IVendorProductPriceForm = {
        productType: '',
        productId: null,
        vendorProductPriceDetails: [],
    };

    const formik = useFormik<IVendorProductPriceForm>({
        initialValues,
        validationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            const { vendorProductPriceDetails } = formik.values;
            const filteredVendorProductPriceDetails = vendorProductPriceDetails.filter(warehouse => 
                !(warehouse.marketPrice === 0 && warehouse.reserveSalesPrice === 0)
            );
            setSubmitting(true);
            const requestBody = filteredVendorProductPriceDetails.map(vendorProductPrice => ({
                vendorId: user?.businessId,
                vendorProductId: Number(formik?.values?.productId),
                warehouseId: vendorProductPrice?.warehouseId,
                marketPrice: vendorProductPrice?.marketPrice,
                validityFrom: vendorProductPrice?.validityFrom,
                validityTo: vendorProductPrice?.validityTo,
                reserveSalesPrice: vendorProductPrice?.reserveSalesPrice,
                status: vendorProductPrice?.status
            }));
            try {
                const vendorCreateResponse = await vendorPriceService.createProductPrices(requestBody);
                if (vendorCreateResponse.status === HTTP_STATUS.OK) {
                    navigate(`${CUSTOMER_ROUTES.VENDOR_PRODUCT_PRICE_LISTING}`);
                }
                else {
                    showSnackbar("error", "Vendor Product Price update failed.");
                }
            }
            catch (error) {
                showSnackbar("error", `Vendor Product Price  update failed ${error}`)
            }
        },
    });

    const fetchWarehouses = async () => {
        if (user?.businessId) {
            try {
                const warehousesResponse = await vendorInventoryService.getAllWarehouseMappedVendor({ vendorId: user?.businessId, status: STATUS.ACTIVE });
                if (warehousesResponse.status === HTTP_STATUS.OK) {
                    const warehouses = warehousesResponse?.data?.data?.content;
                    setWarehousesTo(warehouses);
                }
            } catch (error) {
                showSnackbar('error', 'Warehouses fetch failed');
            }
        }
    };

    const getVendorProductDetails = async () => {
        if (user?.businessId) {
            try {
                const productResponse = await postProductService.getProductById(user?.businessId, productId);
                setProductType(productResponse?.data?.data?.productType);
                if (productResponse?.data?.data) {
                    setProductType(productResponse?.data?.data?.productType);
                } else {
                    throw new Error("Products fetch failed");
                }
            } catch (error) {
                console.error("Warehouse fetch failed:", error);
            }
        }
    };

    const formatProductPrices = (warehouses: { id: number, name: string }[], vendorInventoryData: any) => {
        const formattedProductPrices = warehouses.map((warehouse) => {
            const matchedProductPrice = vendorInventoryData.find((inventory: any) => inventory.warehouseId === warehouse.id);
            return matchedProductPrice
                ? {
                    warehouseId: matchedProductPrice.warehouseId,
                    marketPrice: matchedProductPrice.marketPrice,
                    validityFrom: moment(matchedProductPrice.validityFrom).format('YYYY-MM-DD'),
                    validityTo: moment(matchedProductPrice.validityTo).format('YYYY-MM-DD'),
                    reserveSalesPrice: matchedProductPrice.reserveSalesPrice,
                    name: matchedProductPrice.name,
                    status: matchedProductPrice.status
                }
                : {
                    warehouseId: warehouse.id,
                    marketPrice: 0,
                    validityFrom: today.format('YYYY-MM-DD'),
                    validityTo: today.format('YYYY-MM-DD'),
                    reserveSalesPrice: 0,
                    name: warehouse.name,
                    status: STATUS.ACTIVE
                };
        });
        return formattedProductPrices;
    };

    const getVedorProductPrices = async () => {
        if (user?.businessId) {
            try {
                const params: IProductPrice = {
                    page: pagination.page,
                    size: pagination.size,
                };
                const vedorInventoriesResponse = await vendorPriceService.getAllPriceProducts(productId, params);
                if (vedorInventoriesResponse.status === HTTP_STATUS.OK) {
                    const vendorInventoryData: IVendorProductPriceDetails[] = vedorInventoriesResponse?.data?.data?.content.map((item: any) => ({
                        warehouseId: item.warehouseId,
                        marketPrice: item.marketPrice,
                        reserveSalesPrice: item.reserveSalesPrice,
                        validityFrom: item.validityFrom,
                        validityTo: item.validityTo,
                        name: item.warehouseLocation,
                        status: item.status
                    }));
                    const formattedProductPrices = formatProductPrices(warehouses, vendorInventoryData)
                    formik.setFieldValue('vendorProductPriceDetails', formattedProductPrices);
                    setPagination((prevPagination: IPagination) => ({
                        ...prevPagination,
                        totalRecords: vedorInventoriesResponse?.data?.data?.totalElements,
                    }));
                }
            } catch (error) {
                showSnackbar('error', 'Vendor Product Price fetch failed');
            }
        }
    };

    const vendorProductPriceSectionProps: IVendorProductPriceSectionProps = {
        setCurrentSectionTo: setCurrentSection,
        formik: formik,
        warehouses: warehouses,
    };

    const VendorInventorySectionView = useMemo(() => {
        switch (currentSection) {
            case ADD_VENDOR_PRICE_STATES.ADD_PRICE:
                return <AddPriceDetailTemplate {...vendorProductPriceSectionProps} mode="EDIT" />;
            case ADD_VENDOR_PRICE_STATES.REVIEW:
                return <PriceManagementReviewTemplate {...vendorProductPriceSectionProps} mode="EDIT" />;
            default:
                return <AddPriceDetailTemplate {...vendorProductPriceSectionProps} mode="EDIT" />;
        }
    }, [currentSection, formik]);

    useEffect(() => {
        getVendorProductDetails();
    }, [productId])

    useEffect(() => {
        formik.setFieldValue('productType', productType);
        formik.setFieldValue('productId', productId);
    }, [productType]);

    useEffect(() => {
        if (warehouses.length > 1 && productId && user?.businessId) {
            getVedorProductPrices();
        }
    }, [warehouses, productId, pagination.size, pagination.page]);

    useEffect(() => {
        fetchWarehouses();
    }, [user?.businessId]);

    return (
        <div className='grid gap-6 justify-items-stretch '>
            <div className={`${classes.titleText} font-semibold text-lg`}>Update Price</div>
            {VendorInventorySectionView}
        </div>
    )
}

export default VendorProductPriceUpdatePage