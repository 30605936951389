import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from '@mui/icons-material/Close';
import CategoriesDropdown from './NavbarDropdowns/CategoriesDropdown';
import { useNavigate } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NavbarDropdownTemplate from './NavbarDropdowns/NavbarDropdown.template';


const useStyles = createUseStyles((theme: any) => ({
    container: {
        borderTop: `1px solid ${theme.palette.border.neutral.neutral200}`,
    },
    primaryContainer: {
    },
    homeSection: {
        borderRight: `1px solid ${theme.palette.border.neutral.neutral400}`,
        cursor: 'pointer',
    },
    categories: {
        color: theme.palette.text.neutral.neutral600,
    },
    homemenu: {
        position: 'relative',
        color: theme.palette.text.neutral.neutral600,
        cursor: 'pointer',
        '&:hover $dropDownContent': {
            display: 'block',
        },
    },
    dropDownContent: {
        display: 'none',
        position: 'absolute',
        left: 0,
        backgroundColor: theme.palette.background.paper,
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        zIndex: 1000,
        width: '200px',
        top: '0px',
    },
}));




const NavbarOptions: React.FC = () => {
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();

    const handleClose = () => {
        setIsOpen(false);
    };
    const handleClick = () => {
        setIsOpen(true);
    };
    const navigateToHomePage = () => {
        navigate("/");
    };

    // const handleClickOutside = (event: MouseEvent) => {
    //     if (headerRef.current && !headerRef.current.contains(event.target as Node)) {
    //         handleClose();
    //     }
    // };


    const classes = useStyles();
    return (
        <div className={`${classes.container} w-full`}>
            <div className={`${classes.primaryContainer} flex items-center py-4 w-[1300px] mx-auto`}>
                <div className="flex gap-x-2 items-center pr-4">
                    <div className={`${classes.homemenu}`}>
                        <MenuIcon />
                        <div className={`${classes.dropDownContent}`}>
                            <CategoriesDropdown />
                        </div>
                    </div>
                    <div
                        className={`font-medium text-base ${classes.homeSection} pr-3 relative`}
                        onClick={navigateToHomePage}
                    >
                        Categories
                    </div>
                </div>
                <NavbarDropdownTemplate />
            </div>
        </div>
    );
};

export default NavbarOptions;