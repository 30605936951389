import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import { ReactComponent as DownArrow } from "../../../assets/icons/downArrowFilled.svg";
import { Select as MuiMultiSelect,  MenuItem, SelectProps as MuiMultiSelectProps} from "@mui/material";
import ErrorMessage from '../ErrorMessage/ErrorMessage';

const useStyles = createUseStyles((theme: any) => ({
    select: {
        "& .MuiInputBase-input": {
            fontWeight: 400,
            fontSize: "16px",
        },
        border: "1px solid",
        borderColor: theme.palette.border.neutral.neutral100,
        borderRadius: '8px !important',
        height: "50px",
        cursor: 'pointer !important'
    },
    label: {
        color: theme.palette.text.neutral.neutral700,
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "20px"
    },
    placeholder: {
        color: `${theme.palette.text.neutral.neutral400} !important`
    },
    checkedInputColor: {
        color: theme.palette.text.primary.primary400,
        '&.Mui-checked': {
            color: theme.palette.text.primary.primary500,
        },
    },
    primaryHighlighter: {
        background: theme.palette.background.primary.primary500,
    },
    icon: {
        right: 8,
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        pointerEvents: "none",
        transition: "transform 0.3s ease",
    },
    iconOpen: {
        transform: "translateY(-50%) rotate(180deg)",
    }
}));

export interface IMultiSelectOption {
    label: string;
    value: string | number;
}

interface MultiSelectInputProps extends Omit<MuiMultiSelectProps, "label"> {
    options: IMultiSelectOption[];
    label: string;
    helperText?: string;
    value: IMultiSelectOption[];
    onchange: (selected: IMultiSelectOption[]) => void;
    placeholder?: string;
}

const MultiSelectInput2: React.FC<MultiSelectInputProps> = ({ options, label, helperText, value, onchange, placeholder, ...props }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const handleChange = (event: any) => {
        const selectedValues = event.target.value as (string | number)[];
        const selectedOptions = options.filter(option => selectedValues.includes(option.value));
        onchange(selectedOptions);
    };

    const renderValue = (selected: unknown) => {
        const selectedValues = selected as (string | number)[];
        const selectedOptions = options.filter(option => selectedValues.includes(option.value));
        if (selectedOptions.length === 0) {
            return <span className={`${classes.placeholder}`}>{placeholder}</span>
        } else if (selectedOptions.length === 1) {
            return selectedOptions[0].label;
        } else if (selectedOptions.length === 2) {
            return selectedOptions.map(option => option.label).join(', ');
        } else {
            const firstTwo = selectedOptions.slice(0, 2).map(option => option.label).join(', ');
            return ( 
                <div>{firstTwo} <span className={`py-0.5 px-1 text-sm rounded-xl ${classes.primaryHighlighter}`}>+{selectedOptions.length - 2}</span></div>
            )
        }
    };

    return (
        <div className='flex flex-col gap-[4px] w-full relative' >
            {label && (
                <div className={classes.label}>
                    {label}
                </div>
            )}
            <MuiMultiSelect
                id={`multi-select-${label}`}
                variant='standard'
                disableUnderline={true}
                displayEmpty
                
                {...props}
                value={value?.map(option => option?.value)}
                multiple
                size='medium'
                className={`${classes.select} p-4 relative`}
                renderValue={(selected) => {
                    return  (value?.length || (selected as (number | string)[])?.length) ? renderValue(selected) : <span className={`${classes.placeholder} !font-normal `}>{placeholder}</span>;
                }}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                onChange={handleChange}
                IconComponent={(iconProps) => (
                    <DownArrow {...iconProps} className={`${classes.icon} ${open ? classes.iconOpen : ''}`} />
                )}
            >
                {options.map((option: any) => (
                    <MenuItem key={option.value} value={option.value} >
                        <Checkbox className={classes.checkedInputColor} checked={value.some(selectedOption => selectedOption.value === option.value)} />
                        <ListItemText primary={option.label} />
                    </MenuItem>
                ))}
            </MuiMultiSelect>
            {
                props.error === true && <ErrorMessage message={helperText} />
            }
        </div>
    )
}

export default MultiSelectInput2;
