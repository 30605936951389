import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import Button from "../../atoms/Button/Button";
import ProductViewTemplate from "../DashBoard/Inventory Management/ProductView.template";
import TabsV2, { ITabsSchema } from "../../molecules/TabsV2/TabsV2";
import { IProductData } from "../DashBoard/Inventory Management/SelectProduct.template";
import { ADD_EXISTING_PRODUCT, HTTP_STATUS } from "../../../utils/types";
import ProductWarehouseDetailsTemplate from "./ProductWarehouseDetails.template";
import { AddVendorProductSectionProps } from "./AddExistingProduct.template";
import { useSnackbar } from "../../../hooks/useSnackBar";
import { useAuthenticatedUser } from "../../../hooks/useAuthenticatedUser";
import { capitalizeFirstLetter } from "../../../utils/helper";

const useStyles = createUseStyles((theme: any) => ({
    activatedProgressBar: {
        backgroundColor: theme.palette.background.primary.primary500
    },
    activeProgressBar: {
        backgroundColor: theme.palette.background.primary.primary400
    },
    UnTouchProgressBar: {
        backgroundColor: theme.palette.border.neutral.neutral100
    },
    barHeading: {
        color: theme.palette.text.primary.primary500
    },
    textHeading: {
        color: theme.palette.text.primary.primary900
    },
    heading: {
        color: theme.palette.text.primary.primary900,
    },
    heading1: {
        color: theme.palette.text.primary.primary400,
    },
    mainHeading: {
        color: theme.palette.text.primary.primary900,
    },
    subHeading: {
        color: theme.palette.text.neutral.neutral600
    },
    previousActiveHeading: {
        color: theme.palette.background.primary.primary400
    },
    container: {
        border: `1px solid ${theme.palette.border.neutral.neutral100}`
    },
}));

const ProductReviewTemplate: React.FC<AddVendorProductSectionProps> = ({ formik, setCurrentSectionTo, warehouses, onBack, handleCancel, productDetails }) => {

    const classes = useStyles();

    const [activeTab, setActiveTabTo] = useState("1");

    const schema: ITabsSchema[] = warehouses?.map((warehouse: any, index: number) => ({
        label: capitalizeFirstLetter(warehouse?.name),
        component: <ProductWarehouseDetailsTemplate warehouseId={warehouse.id} formik={formik} index={index} />,
    }));

    const handleBack = () => {
        setCurrentSectionTo(ADD_EXISTING_PRODUCT.PRODUCT_DETAILS);
    }
    const handleTabChange = (tab: string | number) => {
        setActiveTabTo(tab.toString());
    };

    const handleOnSaveDraft = () => {
        formik.setFieldValue("status", "DRAFT");
        formik.handleSubmit();
    };

    useEffect(() => {
        document.getElementById("main")?.scroll(0, 0);
    }, []);

    return (
        <div>
            <div className={`w-full m-auto flex justify-between gap-x-2 text-var(--black, #4D4D4D) text-center text-sm`}>
                <div className="grid gap-y-3 w-1/2">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.activeProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.previousActiveHeading} my-auto`}>Select Product Type </div>
                    </div>
                    <div className={`${classes.activatedProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-1/2">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.activeProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.previousActiveHeading} my-auto`}>Select Product</div>
                    </div>
                    <div className={`${classes.activatedProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-1/2">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.activeProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.previousActiveHeading} my-auto`}>Add Product Details</div>
                    </div>
                    <div className={`${classes.activatedProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-1/2">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.activatedProgressBar} w-3 h-3 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto font-semibold`}>Review</div>
                    </div>
                    <div className={`${classes.activatedProgressBar} h-2.5 rounded `}></div>
                </div>
            </div>
            <div className={`${classes.mainHeading} text-base font-medium mt-8`}>Review</div>
            <div className={`${classes.subHeading} text-xs mb-4`}>Please review the data you have entered</div>
            <div className="mt-6">
                <ProductViewTemplate product={productDetails} showButton={false} formik={formik} />
            </div>
            <div className="grid mt-6 gap-y-3">
                <span className="font-medium text-2xl">Product Details</span>
                <div className={` ${classes.container} p-4 rounded-xl`}>
                    {schema.length > 0 && (
                        <TabsV2 schema={schema} value={activeTab} setValue={handleTabChange} />
                    )}
                </div>
            </div>
            <div className='flex justify-between pt-6'>
                <Button
                    variant="tertiaryContained"
                    label="Cancel"
                    onClick={handleCancel}
                />
                <div className='flex gap-6 '>
                    <Button
                        variant="tertiaryContained"
                        label="Back"
                        onClick={handleBack}
                    />
                    <Button
                        variant="secondaryContained"
                        label="Save As Draft"
                        onClick={handleOnSaveDraft}
                    />
                    <Button
                        variant="primaryContained"
                        label="+Add"
                        onClick={() => formik.handleSubmit()}
                    />
                </div>
            </div>
        </div>
    );
};

export default ProductReviewTemplate;