import React from 'react'
import emptyInventoryIcon from '../../../../assets/images/emptyInventoryIcon.svg';
import { createUseStyles } from 'react-jss';
import Button from '../../../atoms/Button/Button';

interface IEmptyInventoryState {
    handleChange: () => void;
}
const useStyles = createUseStyles((theme: any) => ({
    selectBox: {
        border: `1px solid ${theme.palette.border.neutral.neutral200}`,
    },
    heading:{
        color: theme.palette.text.primary.priomary900
    }
    
}));
const EmptyInventoryStateTemplate: React.FC<IEmptyInventoryState> = ({ handleChange }) => {
    const classes = useStyles();
    return (
        <div className={`${classes.selectBox} rounded-xl grid justify-center py-14 `}>
            <div className='grid gap-5 justify-items-center '>
                <img src={emptyInventoryIcon} alt="emptyInventoryIcon" className="" />
                <div className='grid gap-4 justify-items-center '>
                    <text className={` ${classes.heading} text-2xl font-medium `} > You've not added any inventory yet!</text>
                    <Button
                        className={`w-1/2 `}
                        variant="primaryContained"
                        label="+Add Inventory"
                        onClick={handleChange}
                    />
                </div>
            </div>
        </div>
    )
}

export default EmptyInventoryStateTemplate