import React, { useEffect, useState, useRef } from 'react';
import { createUseStyles } from "react-jss";
import Dialog from '@mui/material/Dialog';
import Button from "../../atoms/Button/Button";
import CloseIcon from '@mui/icons-material/Close';
import noImage from '../../../assets/icons/noImage.svg';
import ProgressBarTemplate from "./ProgressBar.template";

const useStyles = createUseStyles((theme: any) => ({
    titleText: {
        color: theme.palette.text.primary.primary900
    },
    lightText: {
        color: theme.palette.text.neutral.neutral200
    },
    container: {
        "& .MuiDialog-paper": {
            width: "894px !important",
            maxWidth: "894px !important",
            borderRadius: "16px"
        },
    },
    closeIcon: {
        color: theme.palette.background.primary.primary900
    },
    headingText: {
        color: theme.palette.text.neutral.neutral700
    },
    dashedBorder: {
        borderColor: theme.palette.border.primary.primary500
    },
    dropAreaActive: { 
        borderColor: theme.palette.border.primary.primary50,
        backgroundColor: 'white',
    },
    progressBar: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        top: 0,
        left: 0,
    },
    progressLine: {
        height: '100%',
        background: theme.palette.border.primary.primary900,
        transition: 'width 1s ease-in-out',
    },
}));

interface IImageUpdateModalTemplateProps {
    dialogOpen: boolean;
    setDialogOpen: (open: boolean) => void;
    onSubmit: (event: any) => void;
    selectedFile: File | null;
    setSelectedFile: (file: File | null) => void;
    isLocalUpload?: boolean
}

const ImageUploadModalTemplate: React.FC<IImageUpdateModalTemplateProps> = ({
    dialogOpen, setDialogOpen, onSubmit: handleSubmit, selectedFile, setSelectedFile, isLocalUpload = false
}) => {
    const classes = useStyles();
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [isUploading, setIsUploading] = useState(false);
    const [resetProgress, setResetProgress] = useState(false); 
    const [isDragActive, setIsDragActive] = useState(false); 
    const fileInputRef = useRef<HTMLInputElement | null>(null); 
    const [imageDisabled, setImageDisabled] = useState(false);

    const handleDialogClose = () => {
        setDialogOpen(false);
        setErrorMessage('');
        setSelectedFile(null);
        setIsUploading(false); 
        setImageDisabled(false);
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            const validTypes = ['application/pdf', 'image/jpeg', 'image/jpg', 'image/png'];
            const maxSize = 10 * 1024 * 1024; 

            if (!validTypes.includes(file.type)) {
                setErrorMessage('Invalid file type. Only PDF, JPEG, JPG, and PNG are allowed.');
                return;
            }

            if (file.size > maxSize) {
                setErrorMessage('File size exceeds the 10MB limit.');
                return;
            }

            setErrorMessage(''); 
            setSelectedFile(file);
            setIsUploading(true); 
            setImageDisabled(false);
        }
    };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDragActive(false); 

        if (event.dataTransfer.files && event.dataTransfer.files[0]) {
            const file = event.dataTransfer.files[0];
            const validTypes = ['application/pdf', 'image/jpeg', 'image/jpg', 'image/png'];
            const maxSize = 10 * 1024 * 1024; 

            if (!validTypes.includes(file.type)) {
                setErrorMessage('Invalid file type. Only PDF, JPEG, JPG, and PNG are allowed.');
                return;
            }

            if (file.size > maxSize) {
                setErrorMessage('File size exceeds the 10MB limit.');
                return;
            }

            setErrorMessage(''); 
            setSelectedFile(file);
            setIsUploading(true); 
            setImageDisabled(false);
        }
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDragActive(true); 
    };

    const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDragActive(false); 
    };

    const handleFormSubmit = (event: any) => {
        if (selectedFile) {
            handleSubmit(event);
            setErrorMessage(""); 
            // setIsUploading(false); 
            setImageDisabled(true);
            // setResetProgress(true); 
        } else {
            setErrorMessage('Please select a file before submitting.');
        }
    };

    return (
        <Dialog fullWidth className={classes.container} PaperProps={{ style: { height: 'auto' } }} open={dialogOpen} onClose={handleDialogClose}>
            <div className="grid gap-y-12 p-10">
                <div className="flex justify-between">
                    <p className={`${classes.headingText} self-center text-lg font-medium`}>Upload Certification /Document/Brochure</p>
                    <div className="p-0.5" onClick={handleDialogClose}>
                        <CloseIcon className={classes.closeIcon} />
                    </div>
                </div>
                <div className="grid gap-y-6">
                    <div
                        className={`${classes.dashedBorder} ${isDragActive ? classes.dropAreaActive : ''} border-2 p-12 justify-items-center rounded-2xl border-dashed grid gap-6`}
                        onDrop={handleDrop}
                        onDragOver={handleDragOver}
                        onDragLeave={handleDragLeave}
                    >
                        <div className="relative w-[63.273px] h-[50.182px] flex-shrink-0 rounded-[13.666px] bg-gradient-to-r from-[#93B8FF] to-[#1F4690] backdrop-blur-[4.555464267730713px]">
                            <img
                                src={noImage}
                                alt="imageIcon"
                                className="absolute left-1 top-1 cursor-pointer"
                                onClick={() => fileInputRef.current?.click()} 
                            />
                        </div>
                        <p className={`${classes.titleText} self-center text-2xl font-medium`}>Drag and Drop files here</p>
                        <div className="flex w-full items-center">
                            <div className="flex-grow border-t border-gray-400"></div>
                            <span className="mx-2 text-gray-500">or</span>
                            <div className="flex-grow border-t border-gray-400"></div>
                        </div>
                        <Button
                            variant={'primaryContained'}
                            label={'Browse Files'}
                            onClick={() => fileInputRef.current?.click()} 
                        />
                        <p className={`${classes.lightText} self-center text-base font-normal`}>Support: jpg, jpeg, png, pdf | Maximum File Size is 10 MB.</p>
                        <input
                            type="file"
                            ref={fileInputRef} 
                            style={{ display: 'none' }}
                            onChange={handleFileChange}
                        />
                    </div>
                    <div className="grid gap-y-3">
                        {selectedFile && (<p className="text-lg">{selectedFile.name}</p>)}
                        {selectedFile && <ProgressBarTemplate isUploading={isUploading} reset={resetProgress} />} 
                    </div>
                    {errorMessage && <p className="text-red-500">{errorMessage}</p>}
                    <div className="flex justify-end gap-2">
                        <Button
                            variant={'tertiaryContained'}
                            label={'Cancel'}
                            onClick={handleDialogClose}
                        />
                        <Button
                            variant={'primaryContained'}
                            label={'Submit'}
                            onClick={handleFormSubmit}
                            disabled={imageDisabled}
                        />
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

export default ImageUploadModalTemplate;
