import { useEffect, useRef, useState } from "react";
import { useSnackbar } from "../../../../hooks/useSnackBar";
import { createUseStyles } from "react-jss";
import { SCRAP_PRODUCTS, SCRAP_PRODUCTS_CATEGORY, SCRAP_PRODUCTS_TYPE, SCRAP_PRODUCT_ROUTES } from "../../../../utils/constant";
import Carousel from "react-multi-carousel";
import CarouselRef from "react-multi-carousel";
import deleteOutlinedPrimary800 from "../../../../assets/icons/deleteOutlinedPrimary800.svg";
import { useNavigate } from "react-router-dom";
import noImage from '../../../../assets/images/noImage.svg'
import editPenOutlinedIconPrimary500 from "../../../../assets/icons/editPenOutlinedIconPrimary500.svg";
import { ScrapProductsProps } from "./ScrapProduct.template";
import { capitalizeFirstLetter } from "../../../../utils/helper";
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { useTheme } from '@mui/material/styles';
import { TablePagination, Input } from "@mui/material";
import { HTTP_STATUS, IPagination } from '../../../../utils/types';
import { useScrapProductService } from "../../../../services/useScrapProductService";
import { TablePaginationActionsProps } from "../../CatalogueList/CatalogueList.template";
import SuccessFailurePopup from "../../../molecules/SuccessFailurePopup/SuccessFailurePopup";

interface ScrapProductListingTemplateProps {
    selectedFile?: (string | null)[];
    loadScrapProduct: () => void;
    scrapProducts: ScrapProductsProps[];
    setPaginationTo: (value: any) => void;
    pagination: IPagination;
}

const useStyles = createUseStyles((theme: any) => ({
    contentWrapper: {
        marginTop: '-56px',
        background: 'white',
        borderRadius: "16px 16px 10px 10px",
        border: `1px solid ${theme.palette.border.neutral.neutral100}`,
    },
    wrapper: {
        borderBottom: `1px solid ${theme.palette.border.neutral.neutral100}`,
    },
    name: {
        color: theme.palette.text.primary.primary900,
        lineHeight: "20px"
    },
    description: {
        color: theme.palette.text.neutral.neutral700,
    },
    applicationType: {
        color: theme.palette.text.complementary.complementary600,
        background: theme.palette.background.complementary.complementary50,
        border: `1px solid ${theme.palette.border.complementary.complementary100}`,
    },
    customButtonContainer: {
        position: "relative",
        bottom: "130px !important",
    },
    customArrow: {
        borderRadius: "50%",
        width: "24px",
        height: "24px",
        cursor: "pointer",
        color: theme.palette.text.neutral.neutral700,
        transition: "box-shadow 0.3s ease-in-out",
        // boxShadow: `0px 0px 6px 0px ${theme.palette.boxShadow.secondaryDark}`,
        // "&:hover": {
        //     boxShadow: `0px 0px 6px 0px ${theme.palette.boxShadow.primaryDark}`
        // }
    },
    customLeftArrow: {
        paddingLeft: "2px",
        background: '#fff',
    },
    customRightArrow: {
        paddingLeft: "2px",
        background: '#fff',
    },
    customDots: {
        "& .react-multi-carousel-dot-list": {
            bottom: '32px !important'
        },
        "& .react-multi-carousel-dot > button": {
            width: '10px',
            height: '10px',
            borderRadius: '50%',
            border: 'none',
            background: theme.palette.background.neutral.neutral100
        },
        "& .react-multi-carousel-dot--active > button": {
            width: '12px',
            height: '12px',
            borderRadius: '50%',
            border: 'none',
            background: 'white'
        }
    },
    actionButton: {
        background: '#fff',
        padding: '8px',
        border: `1px solid ${theme.palette.border.primary.primary800}`,
    },
    mode: {
        color: theme.palette.text.complementary.complementary600,
        background: theme.palette.background.complementary.complementary50,
    },
    quantity: {
        background: theme.palette.background.neutral.neutral50,
        color: theme.palette.text.neutral.neutral600,
        border: `1px solid ${theme.palette.border.neutral.neutral100}`,
    },
    imageContainer: {
        // width: '258px',
        height: '217px'
    },
    cardContainer: {
        // width: '258px'
    },
    paginationTable: {
        "& .MuiTablePagination-selectLabel": {
            color: "#333",
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "20.3px",
            letterSpacing: "0.21px"
        },
        "& .MuiTablePagination-input": {
            borderRadius: '8px',
            border: '1px solid #E6E6E6',
            width: '80px',
            paddingRight: '10px',
            marginRight: "24px",
            height: "30px"
        },
        "& .MuiTablePagination-displayedRows": {
            color: "#333",
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "20.3px",
            letterSpacing: "0.21px"
        },
        "& .MuiTablePagination-spacer": {
            flex: 0
        },
        "& .MuiToolbar-root": {
            paddingLeft: "0px !important",
            paddingRight: "0px",
            width: "100%"
        },
    },
    paginationComponent: {
        color: theme.palette.text.neutral.neutral700,
        fontWeight: 500,
        fontSize: "14px",
        width: "100%"
    },
}))

const ScrapProductListingTemplate: React.FC<ScrapProductListingTemplateProps> = ({ selectedFile, loadScrapProduct, scrapProducts, setPaginationTo, pagination }) => {

    const classes = useStyles();
    const carouselRef = useRef<CarouselRef>(null);
    const navigate = useNavigate();
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const scrapProductService = useScrapProductService();
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [showDeleteAllPopUp, setShowDeleteAllPopUp] = useState(false);
    const [selectedItemId, setSelectedItemId] = useState<number | null>(null);

    const [hovered, setHovered] = useState<number | null>(null);
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1280 },
            items: 1,
            partialVisibilityGutter: 10,
        },
        tablet: {
            breakpoint: { max: 1280, min: 980 },
            items: 1,
            partialVisibilityGutter: 10,
        },
        mobile: {
            breakpoint: { max: 980, min: 670 },
            items: 1,
            partialVisibilityGutter: 10,
        },
        "sm-mobile": {
            breakpoint: { max: 670, min: 0 },
            items: 1,
        },
    };



    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPaginationTo((prevPagination: IPagination) => ({
            ...prevPagination,
            page: newPage
        }));
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setPaginationTo((prevPagination: IPagination) => ({
            ...prevPagination,
            page: 0,
            size: parseInt(event.target.value, 10)
        }));
    };

    const ButtonGroup = ({ next, previous }: any) => {
        return (
            <div className={`${classes.customButtonContainer} flex items-center w-full px-3 `}>
                <div className="flex w-full justify-between">
                    <button className={`${classes.customArrow} ${classes.customLeftArrow} flex items-center`} onClick={previous}>
                        <KeyboardArrowLeft sx={{ fontSize: 20 }} />
                    </button>
                    <button className={`${classes.customArrow} ${classes.customRightArrow} flex items-center mr-0`} onClick={next}>
                        <KeyboardArrowRight sx={{ fontSize: 20 }} />
                    </button>
                </div>
            </div>
        );
    };
    const getImageIds = (id: number) => {
        return scrapProducts.filter(scrapProduct => scrapProduct.id === id)?.[0].images.map(image => image.id) ?? [];
    }

    const handleDeleteConfirmed = async () => {
        if (selectedItemId === null) return;
        try {
            const imageIds = getImageIds(selectedItemId);
            const deletedItemResponse = await scrapProductService.deleteScrapProductItem(selectedItemId, imageIds.join(","));
            if (deletedItemResponse.status === HTTP_STATUS.OK) {
                await loadScrapProduct();
            }
        } catch (error) {
            showSnackbar("error", "Unable to delete item");
        }
        setShowDeleteAllPopUp(false);
    };

    const handleDeleteItem = (id: number) => {
        setSelectedItemId(id);
        setShowDeleteAllPopUp(true);
    }

    const handleDeleteCancel = () => {
        setShowDeleteAllPopUp(false);
        setSelectedItemId(null);
    };

    const handleUpdateItem = async (id: number) => {
        navigate(`${SCRAP_PRODUCT_ROUTES.EDIT_SCRAP_PRODUCT}?id=${id}`);
    }

    function TablePaginationActions(props: TablePaginationActionsProps) {
        const theme = useTheme();
        const { count, page, rowsPerPage, onPageChange } = props;
        const [inputPage, setInputPage] = useState(page + 1);

        const handleFirstPageButtonClick = (
            event: React.MouseEvent<HTMLButtonElement>,
        ) => {
            onPageChange(event, 0);
        };

        const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            onPageChange(event, page - 1);
        };

        const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            onPageChange(event, page + 1);
        };

        const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        };

        const handleInputPageChange = (event: any) => {
            setInputPage(parseInt(event.target.value, 10));

        };
        const handleInputBlur = (event: any) => {
            onPageChange(event, inputPage - 1);
        };

        return (
            <div className={`flex gap-x-6 justify-end  ${classes.paginationComponent}`}  >
                <div className="flex gap-x-2.5">
                    <div className='my-auto'>Page</div>
                    <div className='my-auto'>
                        <Input
                            type="number"
                            value={inputPage}
                            onChange={handleInputPageChange}
                            onBlur={handleInputBlur}
                            disableUnderline={true}
                            size="small"
                            inputProps={{ min: 1, max: Math.ceil(count / rowsPerPage) }}
                            style={{ width: '60px', borderRadius: '8px', border: '1px solid #D6DEF5', paddingLeft: '20px' }}
                        />
                    </div>

                    <div className='my-auto'>of</div>
                    <div className='my-auto'>{Math.ceil(count / rowsPerPage)}</div>
                </div>

                <div className='flex'>
                    <IconButton
                        onClick={handleFirstPageButtonClick}
                        disabled={page === 0}
                        aria-label="first page"
                    >
                        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
                    </IconButton>
                    <IconButton
                        onClick={handleBackButtonClick}
                        disabled={page === 0}
                        aria-label="previous page"
                    >
                        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                    </IconButton>
                    <IconButton
                        onClick={handleNextButtonClick}
                        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                        aria-label="next page"
                    >
                        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                    </IconButton>
                    <IconButton
                        onClick={handleLastPageButtonClick}
                        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                        aria-label="last page"
                    >
                        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
                    </IconButton>
                </div>
            </div>

        );
    }

    return (
        <>
            {SnackBarComponent}
            <div className="grid gap-4 grid-cols-4 ">
                {scrapProducts?.length && scrapProducts.map(({ id, scrapCategory, type, monthlyProduction, saleMode }, index) => {
                    const scrapCategoryValue = SCRAP_PRODUCTS_CATEGORY.find((item) => item.value === scrapCategory);
                    const scrapTypeValue = SCRAP_PRODUCTS_TYPE.find((item) => item.value === type);
                    return (
                        <div className={`relative ${classes.cardContainer}`} key={id} onMouseEnter={() => setHovered(id)} onMouseLeave={() => setHovered(null)}>
                            <div className={`!h-fit`} id="asdf">
                                <Carousel
                                    responsive={responsive}
                                    infinite={true}
                                    partialVisible={false}
                                    ref={carouselRef}
                                    showDots={true}
                                    arrows={false}
                                    removeArrowOnDeviceType={["sm-mobile"]}
                                    renderButtonGroupOutside={true}
                                    customButtonGroup={<ButtonGroup />}
                                    className={`z-0 relative ${classes.customDots}`}
                                >
                                    {
                                        !!scrapProducts[index].images.length ? scrapProducts[index].images.map((file) => (
                                            <div className={`overflow-hidden rounded-t-2xl ${classes.imageContainer} overflow-hidden`}>
                                                <img src={file?.path ?? noImage} alt="icon" className="w-fit h-fill" />
                                            </div>
                                        )) : <div className={`overflow-hidden rounded-t-2xl ${classes.imageContainer} overflow-hidden`}>
                                            <img src={noImage} alt="loading" className="w-fit h-fill" />
                                        </div>
                                    }
                                </Carousel>
                            </div>
                            <div className={`p-4 ${classes.contentWrapper} z-10 relative`}>
                                <div className={`flex justify-between mb-4 ${classes.wrapper}`}>
                                    <div className="grid">
                                        <span className={`text-base font-semibold mb-1 ${classes.name}`}>{scrapTypeValue?.label}</span>
                                        <span className={`text-sm font-normal mb-4 ${classes.description}`}>{scrapCategoryValue?.label}</span>
                                    </div>

                                    <div className={`text-xs font-medium rounded px-2 py-1 h-fit ${classes.quantity}`}>
                                        {monthlyProduction} MT
                                    </div>
                                </div>
                                <div className="flex flex-col">
                                    <span className={`text-sm font-semibold ${classes.name}`}>{SCRAP_PRODUCTS.CURRENT_SALES_MODE}</span>
                                    <div key={index} className="mt-1">
                                        <span className={`rounded-3xl px-3 py-1.5 text-xs font-medium text-center w-fit ${classes.mode}`}>{saleMode?.split("_").map(el => capitalizeFirstLetter(el)).join(" ")}</span>
                                    </div>
                                </div>
                            </div>
                            {hovered === id && <div className="flex justify-end z-1 absolute top-0 right-0 mt-4 mr-4 gap-4">
                                <div className={`${classes.actionButton} rounded-lg cursor-pointer`}>
                                    <img className="w-4 h-4" src={editPenOutlinedIconPrimary500} alt="icon" onClick={() => handleUpdateItem(id)} />
                                </div>
                                <div className={`${classes.actionButton} rounded-lg cursor-pointer`}>
                                    <img className="w-4 h-4" src={deleteOutlinedPrimary800} alt="icon" onClick={() => handleDeleteItem(id)} />
                                </div>
                            </div>}

                        </div>
                    )
                })}
            </div>
            <div className="w-full">
                <TablePagination
                    className={`${classes.paginationTable} w-full flex`}
                    component="div"
                    rowsPerPageOptions={[3, 5, 10]}
                    count={pagination.totalRecords}
                    page={pagination.page}
                    onPageChange={handleChangePage}
                    rowsPerPage={pagination.size}
                    ActionsComponent={TablePaginationActions}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage="Rows per page"
                />
            </div>

            {
                showDeleteAllPopUp &&
                <SuccessFailurePopup
                    setShowPopup={setShowDeleteAllPopUp}
                    variant="Failure"
                    heading="Delete Product?"
                    description="Are you sure you want to delete this Product?"
                    button1={{
                        text: "Yes",
                        variant: "tertiaryContained",
                        size: "large",
                        onClick: handleDeleteConfirmed,
                    }}
                    button2={{
                        text: "No",
                        variant: "secondaryContained",
                        size: "large",
                        onClick: handleDeleteCancel,
                    }}
                />

            }
        </>
    )
}

export default ScrapProductListingTemplate;