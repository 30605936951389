import React from 'react'
import yellowVerifiedIcon from "../../../assets/icons/yellowVerifiedUser.svg"
import blueVerifiedIcon from "../../../assets/icons/blueVerifiedUser.svg"
import WhatsappLabel from "../../atoms/Whatsapp/WhatsappLabel";
import CallButton from '../../atoms/Button/CallButton';
import { createUseStyles } from 'react-jss';
import userAvatar from "../../../assets/icons/userAvatar.svg"

const useStyles = createUseStyles((theme: any) => ({
    vendorDetails: {
        color: theme.palette.text.neutral.neutral600,
    },
    borderBottom: {
        borderBottom: `1px solid ${theme.palette.border.neutral.neutral100}`
    },
}));

export const StoreFrontGstDetailsTemplate: React.FC = () => {

    const classes = useStyles();

    return (
        <div className={`${classes.borderBottom} flex justify-between py-6`}>
            <div className="flex gap-4">
                <div>
                    <img className="rounded-xl" width={"54px"} height={"54px"} src={userAvatar} alt="user avatar" />
                </div>
                <div className="flex flex-col justify-center">
                    <div className="flex text-2xl font-semibold items-center">
                        <div>BLD Ispat Pvt. Ltd.</div>
                        <img width={"24px"} height={"24px"} src={blueVerifiedIcon} alt="" />
                    </div>
                    <div className={`${classes.vendorDetails} flex gap-1`}>
                        <div>{`Delhi, India |`}</div>
                        <div>{`Manufacturer/Factory |`}</div>
                        <div>{`GST No. 24AABCE8347C1ZX |`}</div>
                        <div className="flex gap-1">
                            <img width={"24px"} height={"24px"} src={yellowVerifiedIcon} alt="verified icon" />
                            <div>{`Trusted Supplier`}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex gap-2">
                <WhatsappLabel label="Whatsapp 8642839173" />
                <CallButton label="Call 8642839173" />
            </div>
        </div>
    )
}
