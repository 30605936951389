import React from 'react';
import { createUseStyles } from 'react-jss';
import visionBanner from '../../../assets/images/visionBanner.svg';
import coil1 from '../../../assets/images/coil1.svg';
import coil2 from '../../../assets/images/coil2.svg';
import coil3 from '../../../assets/images/coil3.svg';
import { ClassNames } from '@emotion/react';

const useStyles = createUseStyles((theme: any) => ({
    bannerContainer: {
        width: '100%',
        height: '650px',
        backgroundImage: `url(${visionBanner})`,
        backgroundSize: 'cover',
        position: 'relative',
        marginTop: '0px',
    },
    visionContainer: {
        width: '1297px',
        margin: '0 auto',
    },
    heading: {
        fontSize: '46px',
        color: theme.palette.text.primary.primary50,
        lineHeight: '66px',
        position: 'relative',
        top: '150px',
    },
    primaryText: {
        color: theme.palette.text.primary.primary50,
        fontSize: '18px',
        lineHeight: '15px',
    },
    sectionHeading: {
        color: theme.palette.text.primary.primary800,
        fontSize: '40px',
        lineHeight: 'normal',
    },
    secondaryContainer: {
        width: '800px',
    },
    description: {

    },
    cardDescription: {
        color: theme.palette.text.primary.primary900,
        lineHeight: '28px',
    },
    container: {

    },
    bannerBtn: {

    },
}));

const AboutUsTemplate: React.FC = () => {

    const classes = useStyles();

    return (
        <>
            <div className={`${classes.container}`}>
                <div className={`${classes.bannerContainer} relative`}>
                    <div className='w-120'>
                        <h1 className={`${classes.heading} ${classes.visionContainer} mt-9 font-semibold`}>Excellence through<br />Advanced Sourcing,<br />Custom Manufacturing<br />& Digital Supply Chain Solutions
                        </h1>
                    </div>
                </div>

                <div className={`${classes.visionContainer} flex gap-16 pt-16`}>
                    <div className='w-fit'>
                        <div className='w-full'><img src={coil1} alt="" /></div>
                        <div className='flex gap-10 mt-6'>
                            <img src={coil2} alt="" className='w-56' />
                            <img src={coil3} alt="" className='w-56' />
                        </div>
                    </div>
                    <div className={`${classes.secondaryContainer}`}>
                        <h2 className={`${classes.sectionHeading} mb-3 font-semibold`}>About Us</h2>
                        <div className={`${classes.description}`}>
                            <p className={`${classes.cardDescription} text-2xl mb-7`}>At SteelBazaar, we are transforming the steel industry by integrating advanced sourcing, custom manufacturing, and cutting-edge digital solutions. More than just a marketplace , we offer a full suite of value-added services designed to streamline the entire steel procurement and manufacturing process. From raw materials to finished products, SteelBazaar delivers tailored solutions to match every customer's specific needs.</p>
                            <p className={`${classes.cardDescription} text-2xl`}>We prioritize exceptional sourcing through a global network of trusted suppliers and manufacturers, ensuring that our customers receive high-quality steel at the most competitive prices. By embracing technology, we simplify procurement with real-time tracking, transparent processes, and seamless order management, giving businesses the efficiency and clarity they need.</p>
                        </div>
                    </div>
                </div>

                <div className={`${classes.visionContainer} flex gap-16 items-center justify-between pt-10`}>
                    <div className={`${classes.secondaryContainer}`}>
                        <h2 className={`${classes.sectionHeading} mb-6 font-semibold`}>Our Vision</h2>
                        <div className={`${classes.description}`}>
                            <p className={`${classes.cardDescription} text-2xl`}>Our vision is to be the most trusted and innovative partner in the steel industry, enabling seamless procurement and manufacturing while promoting sustainability and growth for our customers globally. SteelBazaar is committed to setting new standards in transparency, customer satisfaction, and technological advancement.
                            </p>
                        </div>
                    </div>
                    <div className='w-fit'>
                        <div className='w-full'><img src={coil1} alt="" /></div>
                        <div className='flex gap-10 mt-6'>
                            <img src={coil2} alt="" className='w-56' />
                            <img src={coil3} alt="" className='w-56' />
                        </div>
                    </div>
                </div>

                <div className={`${classes.visionContainer} flex items-center gap-16 pt-16 pb-14`}>
                    <div className='w-fit'>
                        <div className='w-full'><img src={coil1} alt="" /></div>
                        <div className='flex gap-10 mt-6'>
                            <img src={coil2} alt="" className='w-56' />
                            <img src={coil3} alt="" className='w-56' />
                        </div>
                    </div>
                    <div className={`${classes.secondaryContainer}`}>
                        <h2 className={`${classes.sectionHeading} mb-3 font-semibold`}>Our Mission</h2>
                        <div className={`${classes.description}`}>
                            <p className={`${classes.cardDescription} text-2xl`}>To optimize steel procurement and manufacturing through integration of innovative sourcing, customized solutions, and supply chain digitization We aim to empower businesses driving efficiency and high-quality steel products.
                            </p>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default AboutUsTemplate;
