import { createUseStyles } from "react-jss";
import TextFieldV2 from "../../atoms/Input/TextFieldV2";
import { FormikErrors, FormikProps } from "formik";
import { IVendorProductPriceDetails, IVendorProductPriceForm } from "../../pages/VendorManagement/VendorProductPriceCreate.page";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { useEffect, useState } from "react";
import SelectV2 from "../../atoms/Select/SelectV2";
import { MODE, STATUS } from "../../../utils/types";
import InputAdornment from '@mui/material/InputAdornment';
import { PriceInformationstatusOptions } from "../../../utils/constant";

const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid ${theme.palette.border.neutral.neutral100}`
    },
    title: {
        color: theme.palette.text.primary.primary900,
    },
    textHeading3: {
        color: theme.palette.text.primary.primary900
    },
    radioColor: {
        color: theme.palette.background.primary.primary500,
        width: "18%",
        display: "block",
        " & .MuiFormControlLabel-label": {
            display: "block",
            color: theme.palette.text.primary.primary800,
            fontSize: "18px",
            fontWeight: "600",
            marginTop: "12px"
        }
    },
    warningAsterisk: {
        color: theme.palette.text.primary.primary500
    },
    datePicker: {
        border: "1px solid",
        borderColor: "#DDD",
        fontSize: "16px",
        fontWeight: 500,
        borderRadius: 8,
        "&:hover": {
            borderColor: theme.palette.border.primary.primary500,
            borderWidth: 2,
            outline: "none",
        },
        "& .MuiOutlinedInput-notchedOutline": {
            outline: "none",
            borderWidth: 0,
            borderColor: "#DDD",
        },
        "&:focus-within": {
            outline: "none",
            borderColor: `${theme.palette.border.primary.primary500} !important`,
            borderWidth: "2px !important",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            outline: "none",
            borderWidth: 0,
            borderColor: `${theme.palette.border.primary.primary500} !important`,
        },
    },
    errorMessage: {
        color: theme.palette.text.secondary.secondary500,
    },
}));

interface IPriceInformationProps {
    formik: FormikProps<IVendorProductPriceForm>;
    warehouseId: number;
    index: number;
    warehouseName: any;
    mode: string
}

const PriceInformationTemplate: React.FC<IPriceInformationProps> = ({ warehouseId, formik, index, warehouseName, mode = MODE.EDIT }) => {

    const classes = useStyles();

    const [validityTo, setValidityTo] = useState<moment.Moment | null>(formik.values.vendorProductPriceDetails[index]?.validityTo ? moment(formik.values.vendorProductPriceDetails[index]?.validityTo, 'YYYY-MM-DD') : null);
    const [validityFrom, setValidityFrom] = useState<moment.Moment | null>(formik.values.vendorProductPriceDetails[index]?.validityFrom ? moment(formik.values.vendorProductPriceDetails[index]?.validityFrom, 'YYYY-MM-DD') : null);
    
    useEffect(() => {
        if (!formik.values.vendorProductPriceDetails[index]?.status) {
            formik.setFieldValue(`vendorProductPriceDetails[${index}].status`, STATUS.ACTIVE);
        }    
        formik.setFieldValue(`vendorProductPriceDetails[${index}].name`, warehouseName);
        formik.setFieldValue(`vendorProductPriceDetails[${index}].id`, warehouseId);
    }, [warehouseName, warehouseId, formik?.values?.vendorProductPriceDetails]);
      
    const validateDates = (type: string) => {
        const today = moment().format('YYYY-MM-DD'); 

        switch (type) {
            case 'VALIDITY_TO':
                if (validityTo && moment(validityTo).isBefore(today)) {
                    return "Validity To must be today or later";
                }
                else if (validityFrom && validityTo && moment(validityTo).isBefore(moment(validityFrom))) {
                    return "Validity To should not be before Validity To date.";
                }
                else return "";

            case 'VALIDITY_FROM':
                if (validityTo && moment(validityFrom).isBefore(today)) {
                    return "Validity From must be today or later";
                }
                else if (validityFrom && validityTo && moment(validityFrom).isAfter(moment(validityTo))) {
                    return "Validity From should not be after Validity To";
                }
                else return "";

            default:
                return "";
        }
    };

    useEffect(() => {
        if (formik.values.vendorProductPriceDetails[index]) {
            const detail = formik.values.vendorProductPriceDetails[index];
            setValidityTo(detail.validityTo ? moment.utc(detail.validityTo, 'YYYY-MM-DD') : null);
            setValidityFrom(detail.validityFrom ? moment.utc(detail.validityFrom, 'YYYY-MM-DD') : null);
        }
    }, [formik.values.vendorProductPriceDetails, index]);

    useEffect(() => {
        if (validityFrom) {
            const formattedValidityFrom = moment(validityFrom).format('YYYY-MM-DD');
            formik.setFieldValue(`vendorProductPriceDetails[${index}].validityFrom`, formattedValidityFrom);
            const errorMessage = validateDates('VALIDITY_FROM');
            if (errorMessage) {
                formik.setFieldError(`vendorProductPriceDetails[${index}].validityFrom`, errorMessage);
            } else {
                formik.setFieldError(`vendorProductPriceDetails[${index}].validityFrom`, '');
            }
        }
    }, [validityFrom]);

    useEffect(() => {
        if (validityTo) {
            const formattedValidityTo = moment(validityTo).format('YYYY-MM-DD');
            formik.setFieldValue(`vendorProductPriceDetails[${index}].validityTo`, formattedValidityTo);
            const errorMessage = validateDates('VALIDITY_TO');
            if (errorMessage) {
                formik.setFieldError(`vendorProductPriceDetails[${index}].validityTo`, errorMessage);
            } else {
                formik.setFieldError(`vendorProductPriceDetails[${index}].validityTo`, '');
            }
        }
    }, [validityTo]);

    return (
        <div className="border-3 grid gap-6">
            <text className={`${classes.textHeading3} text-base font-medium `}>Product Pricing (per MT)</text>
            <div className="flex gap-6">
                <div className='w-1/4'>
                    <TextFieldV2
                        variant="outlined"
                        placeholder="Enter Quantity"
                        label="Market Price (CDP2)"
                        {...formik.getFieldProps(`vendorProductPriceDetails[${index}].marketPrice`)}
                        type="number"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    ₹
                                </InputAdornment>
                            ),
                        }}
                    />
                    {formik.touched?.vendorProductPriceDetails?.[index]?.marketPrice &&
                        formik.errors.vendorProductPriceDetails as FormikErrors<IVendorProductPriceDetails>[] && (
                            <div className={classes.errorMessage}>
                                <small>{(formik.errors.vendorProductPriceDetails as FormikErrors<IVendorProductPriceDetails>[])[index]?.marketPrice}</small>
                            </div>
                        )}
                </div>
                <div className='w-1/4'>
                    <TextFieldV2
                        variant="outlined"
                        placeholder="Enter Price"
                        label="Reserve Sales Price (RSP)"
                        {...formik.getFieldProps(`vendorProductPriceDetails[${index}].reserveSalesPrice`)}
                        type="number"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    ₹
                                </InputAdornment>
                            ),
                        }}
                    />
                    {formik.touched?.vendorProductPriceDetails?.[index]?.reserveSalesPrice &&
                        formik.errors.vendorProductPriceDetails as FormikErrors<IVendorProductPriceDetails>[] && (
                            <div className={classes.errorMessage}>
                                <small>{(formik.errors.vendorProductPriceDetails as FormikErrors<IVendorProductPriceDetails>[])[index]?.reserveSalesPrice}</small>
                            </div>
                        )}
                </div>
                <div className="w-1/4">
                    <label>Price Validity (From)</label>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                            value={validityFrom}
                            format="DD-MM-YYYY"
                            onChange={(newValue) => setValidityFrom(newValue)}
                            className={classes.datePicker}
                            shouldDisableDate={(date) => moment(date).isBefore(moment(), 'day')}
                        />
                    </LocalizationProvider>
                    {formik.touched?.vendorProductPriceDetails?.[index]?.validityFrom &&
                        formik.errors.vendorProductPriceDetails as FormikErrors<IVendorProductPriceDetails>[] && (
                            <div className={classes.errorMessage}>
                                <small>{(formik.errors.vendorProductPriceDetails as FormikErrors<IVendorProductPriceDetails>[])[index]?.validityFrom}</small>
                            </div>
                        )}
                </div>
                <div className="w-1/4">
                    <label>Price Validity (To)</label>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                            value={validityTo}
                            format="DD-MM-YYYY"
                            onChange={(newValue) => setValidityTo(newValue)}
                            className={classes.datePicker}
                            shouldDisableDate={(date) => moment(date).isBefore(moment(validityFrom), 'day')}
                        />
                    </LocalizationProvider>
                    {formik.touched?.vendorProductPriceDetails?.[index]?.validityTo &&
                        formik.errors.vendorProductPriceDetails as FormikErrors<IVendorProductPriceDetails>[] && (
                            <div className={classes.errorMessage}>
                                <small>{(formik.errors.vendorProductPriceDetails as FormikErrors<IVendorProductPriceDetails>[])[index]?.validityTo}</small>
                            </div>
                        )}
                </div>
                {mode === MODE.EDIT && (
                    <div className='w-1/5'>
                        <SelectV2
                            label="Status"
                            value={formik.values.vendorProductPriceDetails[index]?.status || STATUS.ACTIVE}
                            onChange={(event: any) => formik.setFieldValue(`vendorProductPriceDetails[${index}].status`, event.target.value)}
                            variant="outlined"
                            options={PriceInformationstatusOptions}
                        />
                    </div>
                )}
            </div>
        </div>
    )
}

export default PriceInformationTemplate;
