import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router-dom';
import AboutUsDropdown from './AboutUsDropdown';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PlateformDropdown from './PlateformDropdown';
import ServiceDropDown from './ServiceDropDown';
import TradeDropdown from './TradeDropdown';
import MembershipDropdown from './MembershipDropdown';
import dropDown from "../../../../assets/icons/dropDown.svg";
import dropUp from "../../../../assets/icons/dropUp.svg";

const useStyles = createUseStyles((theme: any) => ({
    container: {},
    homeSection: {
        color: theme.palette.text.neutral.neutral600,
    },
    dropdown: {
        color: theme.palette.text.primary.primary700,
        transition: 'max-height 4s ease-in-out',
    },
    navbarOptions: {
        color: theme.palette.text.primary.primary700,
    },
    dropdownContainer: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        gap: '2px',
        padding: '4px',
        borderRadius: '4px',
        '&:hover': {
            backgroundColor: theme.palette.background.primary.primary50,
        },
    },
    rightDropdown: {
        color: theme.palette.text.primary.primary700,
        transition: 'max-height 4s ease-in-out',
        "&:hover": {
            background: theme.palette.background.primary.primary50,
        },
    },
    rightDropDownOption: {
        border: `1px solid ${theme.palette.border.primary.primary50}`,
        boxShadow: "0px 4px 24px 0px rgba(29, 54, 121, 0.16)"
    },
    rightDropDownOptionValue: {
        color: theme.palette.text.neutral.neutral400,
    }
}));

const NavbarDropdownTemplate: React.FC = () => {
    const [hoveredDropdown, setHoveredDropdown] = useState<string | null>(null);
    const [hovered, setHovered] = useState<number | null>(null);
    const navigate = useNavigate();

    const classes = useStyles();

    const handleItemClick = (path: string) => {
        navigate(path);
    };

    const dropDownOptions = [
        {
            title: 'Supplier',
            items: [
                { label: 'List Your Brand', path: '/sell-on-steelbazaar' },
                { label: 'Participate in RFQs', path: '/sell-on-steelbazaar' },
                { label: 'Create Sale Auction', path: '/sell-on-steelbazaar' },
                { label: 'Post Your Lead', path: '/sell-on-steelbazaar' },
                { label: 'Become Verified Seller', path: '/sell-on-steelbazaar' },
                { label: 'Channel Partners', path: '/sell-on-steelbazaar' },
            ]
        },
        {
            title: 'Buyer',
            items: [
                { label: 'Search and Find', path: '/buy-on-steelbazaar' },
                { label: 'Compare the Brands', path: '/buy-on-steelbazaar' },
                { label: 'Pool and Save', path: '/buy-on-steelbazaar' },
                { label: 'Post Your Lead', path: '/buy-on-steelbazaar' },
            ]
        },
        {
            title: 'Help Center',
            items: [
                { label: 'Help Center', path: '/help-center' },
            ]
        },
    ];

    const renderDropdown = (
        label: string,
        hoveredDropdown: string | null,
        setHoveredDropdown: (label: string | null) => void,
        DropdownComponent: React.FC
    ) => {
        const isOpen = hoveredDropdown === label;
        return (
            <div
                className={classes.dropdownContainer}
                onMouseEnter={() => setHoveredDropdown(label)}
                onMouseLeave={() => setHoveredDropdown(null)}
                style={{ backgroundColor: isOpen ? '#E0F7FA' : 'transparent' }}
            >
                <div className={`font-medium text-base ${classes.homeSection}`}>{label}</div>
                {isOpen ? (
                    <ExpandLessIcon className={classes.homeSection} />
                ) : (
                    <ExpandMoreIcon className={classes.homeSection} />
                )}
                <div className={`${classes.dropdown}`}>
                    <div className="m-auto max-container">
                        {isOpen && <DropdownComponent />}
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="flex justify-between w-full items-center">
            <div className={`${classes.navbarOptions} flex gap-x-4 items-center`}>
                {renderDropdown("About Us", hoveredDropdown, setHoveredDropdown, AboutUsDropdown)}
                {renderDropdown("Platform", hoveredDropdown, setHoveredDropdown, PlateformDropdown)}
                {renderDropdown("Services", hoveredDropdown, setHoveredDropdown, ServiceDropDown)}
                {renderDropdown("Trade Assurance", hoveredDropdown, setHoveredDropdown, TradeDropdown)}
                {renderDropdown("Membership", hoveredDropdown, setHoveredDropdown, MembershipDropdown)}
            </div>

            <div className="flex gap-x-4">
                {dropDownOptions.map((dropdown: any, index: any) => (
                    <div
                        key={index}
                        className={`relative ${classes.rightDropdown} flex gap-x-0.5 text-sm font-medium p-1 rounded`}
                        onMouseEnter={() => setHovered(index)}
                        onMouseLeave={() => setHovered(null)}
                        style={{ minWidth: 'fit-content', whiteSpace: 'nowrap' }}
                    >
                        <div className='my-auto'>{dropdown.title}</div>
                        <img src={hovered === index ? dropUp : dropDown} alt="dropIcon" />

                        {hovered === index && (
                            <div className='absolute top-full -left-14 pt-4 z-10'>
                                <div className={`${classes.rightDropDownOption} bg-white p-6 grid gap-y-4`}>
                                    <div className='grid'>
                                        {dropdown.items.map((item: any, i: any) => (
                                            <div
                                                key={i}
                                                className={`${classes.rightDropDownOptionValue} py-2 px-3 text-sm font-medium cursor-pointer`}
                                                onClick={() => handleItemClick(item.path)} 
                                            >
                                                {item.label}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default NavbarDropdownTemplate;
