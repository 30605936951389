import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import BusinessAddressTemplate from './BusinessAddress.template';
import SocialMediaLinks from '../../molecules/socialMediaLinks/SocialMediaLinks';
import Twitter from '../../../assets/icons/xMedium.svg';
import Facebook from "../../../assets/icons/facebookMedium.svg";
import Instagram from "../../../assets/icons/instagramMedium.svg";
import LinkedIn from "../../../assets/icons/linkedinMedium.svg";
import Whatsapp from "../../../assets/icons/whatsappMedium.svg"
import { useBusinessService } from '../../../services/useBusinessService';
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';
import { IBusinessProfile } from '../../pages/DashBoard/ProfileOverview.page';
import { INDUSTRY_STATES } from '../../../utils/constant';
import { convertDate, getProfileDisplayData } from '../../../utils/helper';

const useStyles = createUseStyles((theme: any) => ({
    section: {
        color: theme.palette.text.primary.primary900,
    },
    container: {
        border: `1px solid ${theme.palette.border.neutral.neutral100}`,
        width: "100%"
    },
    title: {
        color: theme.palette.text.primary.primary900,
    },
    value: {
        color: theme.palette.text.neutral.neutral600,
    },
    property: {
        color: theme.palette.text.neutral.neutral700,
    },
    icon: {
        color: theme.palette.button.primaryContained,
        width: "33px",
    },
    borderBottom: {
        borderBottom: `1px solid ${theme.palette.border.neutral.neutral100}`,
    }
}));

const pocDetailsGridTemplate = { xs: 6, sm: 6, md: 6, lg: 4, xl: 4 };
const businessInformationGridTemplate = { xs: 4, sm: 4, md: 3, lg: 3, xl: 3 };

interface ProfileOverviewTemplateProps {
    businessProfile: IBusinessProfile;
}

export interface IRegisteredAddress {
    addressLine1: string;
    addressLine2: string;
    pincode: string;
    city: string;
    state: string;
}

export interface Address {
    id: number;
    type: string;
    email: string;
    city: string;
    state: string;
    country: string;
    pincode: string;
    gmapUrl: string;
    line1: string;
    line2: string;
    countryCode: string;
    mobileNumber: string;
    stdCode: string;
    landline: string;
    resourceType: string;
    createdBy: string;
    updatedBy: string;
    resourceId: number;
    createdAt: string;
    updatedAt: string;
}

interface Business {
    id: number;
    name: string;
    gstin: string;
    legalStatus: string;
    dateOfEstablishment: string;
    userId: number;
    categories: string;
    lastAnnualTurnover: string;
    websiteUrl: string;
    whatsappNo: string;
    businessType: string;
    tags: string;
    relationshipManager: string;
    customerType: string;
    entityType: string;
    industry: string;
    totalEmployees: number;
    serviceRequirements: string;
    materialRequirements: string;
    statutory: string;
    bankDetails: string;
    pocs: string;
}

interface IBusinessDetails {
    address: Address;
    business: Business;
    warehouses: any[];
}

const ProfileOverviewTemplate: React.FC<ProfileOverviewTemplateProps> = ({ businessProfile }) => {

    const classes = useStyles();
    const { user } = useAuthenticatedUser();
    const businesssService = useBusinessService();
    const [businessDetails, setBusinessDetailsTo] = useState<IBusinessDetails | null>(null);
    const [gstRegisteredAddress, setGstRegisteredAddressTo] = useState<IRegisteredAddress | null>(null);
    const [cinRegisteredAddress, setCinRegisteredAddressTo] = useState<IRegisteredAddress | null>(null);

    const USER_DETAILS_SCHEMA = [{ label: "Full Name", value: "fullName" }, { label: "Department", value: "department" }, { label: "Designation", value: "designation" }, { label: "Email Address", value: "email" }, { label: "Phone", value: "mobileNumber" }, { label: "Social Media Link", value: "socialLinks" }];
    const BUSINESS_INFORMATION_SCHEMA = [{ label: "Company Name", value: "name" }, { label: "Year of Establishment", value: "dateOfEstablishment" }, { label: "Number of Employees", value: "totalEmployees" }, { label: "Business Classification", value: "businessType" }, { label: "Entity Type", value: "entityType" }, { label: "Business Type", value: "businessType" }, { label: "Industry Type", value: "industry" }, { label: "Website", value: "websiteUrl" }];

    const getIndustryType = (value: string): string => {
        const industry = INDUSTRY_STATES[value as keyof typeof INDUSTRY_STATES];
        return industry !== undefined ? industry : value;
    }

    const getBusinessDetailsById = async () => {
        if (user?.businessId) {
            const response = await businesssService.getBusiness(user?.businessId);
            if (response?.data?.data) {
                setBusinessDetailsTo(response.data.data);
            } else {
                setBusinessDetailsTo(null);
            }
        }
        return;
    }

    const formatSocialLinks = (socialLinks: any) => {
        if (!socialLinks) return null;
        const logoMapping: { [key: string]: string } = {
            Twitter,
            Facebook,
            Instagram,
            LinkedIn,
            Whatsapp
        };

        const socialMediaSchema = Object.keys(socialLinks).map((key) => ({
            key: key,
            link: socialLinks[key] || "",
            logo: logoMapping[key] || `path/to/${key}Logo.png`
        }));
        return <SocialMediaLinks schema={socialMediaSchema} />;
    };

    useEffect(() => {
        if (user?.businessId)
            getBusinessDetailsById()
    }, [user?.businessId]);

    useEffect(() => {
        if (businessProfile) {
            setGstRegisteredAddressTo({
                addressLine1: businessProfile?.address?.line1 || "",
                addressLine2: businessProfile?.address?.line2 || "",
                pincode: businessProfile?.address?.pincode || "",
                city: businessProfile?.address?.city || "",
                state: businessProfile?.address?.state || "",
            });
            setCinRegisteredAddressTo({
                addressLine1: businessProfile?.cinRegisteredAddress?.line1 || "",
                addressLine2: businessProfile?.cinRegisteredAddress?.line2 || "",
                pincode: businessProfile?.cinRegisteredAddress?.pincode || "",
                city: businessProfile?.cinRegisteredAddress?.city || "",
                state: businessProfile?.cinRegisteredAddress?.state || "",
            });
        }
    }, [businessProfile]);

    return (
        <div className='grid gap-y-5'>
            <div className={`${classes.section} text-lg font-medium`}>My Profile</div>
            <div className='grid gap-y-4'>
                <div className={`p-4 grid gap-y-6 rounded-lg ${classes.container}`}>
                <div className={`${classes.title} pb-6 ${classes.borderBottom} text-lg font-medium`}>User Basic Information</div>
                    <div className='flex '>
                        <div className='w-full grid gap-y-6'>
                            <div className='flex w-full justify-between gap-x-6'>
                                {USER_DETAILS_SCHEMA.slice(0, 3).map((key: any, index: number) => (
                                    <div key={key.label} className={` w-1/3`}>
                                        <div className="w-5">
                                            <img src={key.icon} className="m-auto" alt="" />
                                        </div>
                                        <div className="grid gap-y-1">
                                            <div className={`${classes.value} font-normal text-sm`}>{key.label}</div>
                                            <div className={`${classes.property} font-medium text-base`}>
                                                {key.value === 'socialLinks' ? (
                                                    <div className="flex gap-x-2">
                                                        {getProfileDisplayData(businessProfile?.user?.socialLinks) === "No Data" ? getProfileDisplayData(businessProfile?.user?.socialLinks) : formatSocialLinks(businessProfile?.user?.socialLinks)}
                                                    </div>
                                                ) : (
                                                    (businessProfile?.user as any)?.[key.value] || getProfileDisplayData(null)
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className='flex w-full justify-between gap-x-9'>
                                {USER_DETAILS_SCHEMA.slice(3).map((key: any, index: number) => (
                                    <div key={key.label} className={` w-1/3`}>
                                        <div className="w-5">
                                            <img src={key.icon} className="m-auto" alt="" />
                                        </div>
                                        <div className="grid gap-y-1">
                                            <div className={`${classes.value} font-normal text-sm`}>{key.label}</div>
                                            <div className={`${classes.property} font-medium text-base`}>
                                                {key.value === 'socialLinks' ? (
                                                    <div className="flex gap-x-2">
                                                        {getProfileDisplayData(businessProfile?.user?.socialLinks) === "No Data" ? getProfileDisplayData(businessProfile?.user?.socialLinks) : formatSocialLinks(businessProfile?.user?.socialLinks)}
                                                    </div>
                                                ) : (
                                                    (businessProfile?.user as any)?.[key.value] || getProfileDisplayData(null)
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`p-4 grid gap-y-6 rounded-xl ${classes.container}`}>
                    <div className={`${classes.title} pb-6 ${classes.borderBottom} text-lg font-medium`}>Business Information</div>
                    <div className='w-full'>
                        <div className='w-full grid gap-y-6'>
                            <div className='flex w-full justify-between gap-x-6'>
                                {BUSINESS_INFORMATION_SCHEMA.slice(0, 4).map((key: any, index: number) => (
                                    <div key={key.label} className={` w-1/4`}>
                                        <div className="my-auto w-5"><img src={key.icon} className="m-auto" alt="" /></div>
                                        <div className="grid gap-y-1">
                                            <div className={`${classes.value} font-normal text-sm`}>{key.label}</div>
                                            <div className={`${classes.property} font-medium text-base`}>
                                                {key.label === 'Business Classification'
                                                    ? getProfileDisplayData(businessProfile?.udhyam?.businessType)
                                                    : key.label === 'Business Type'
                                                        ? getProfileDisplayData(businessProfile?.businessType)
                                                        : key.value === 'entityType'
                                                            ? businessProfile?.cin?.entityType || businessProfile?.entityType
                                                            : key.value === 'dateOfEstablishment'
                                                                ? convertDate((businessProfile as any)?.[key.value])
                                                                : typeof (businessProfile as any)?.[key.value] === 'string'
                                                                    ? (businessProfile as any)?.[key.value]
                                                                    : (businessProfile as any)?.[key.value] || "No Data"
                                                }
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className='flex w-full justify-between gap-x-6'>
                                {BUSINESS_INFORMATION_SCHEMA.slice(4).map((key: any, index: number) => (
                                    <div key={key.label} className={` w-1/4`}>
                                        <div className="my-auto w-5"><img src={key.icon} className="m-auto" alt="" /></div>
                                        <div className="grid gap-y-1">
                                            <div className={`${classes.value} font-normal text-sm`}>{key.label}</div>
                                            <div className={`${classes.property} font-medium text-base break-all`}>
                                                {
                                                    key.label === 'Business Classification'
                                                        ? getProfileDisplayData(businessProfile?.udhyam?.businessType)
                                                        : key.label === 'Business Type'
                                                            ? getProfileDisplayData(businessProfile?.businessType)
                                                            : key.value === 'entityType'
                                                                ? businessProfile?.cin?.entityType || businessProfile?.entityType
                                                                : key.label === 'Website'
                                                                    ? (
                                                                        businessProfile?.websiteUrl
                                                                            ? <a href={businessProfile.websiteUrl} target="_blank" rel="noopener noreferrer">
                                                                                {businessProfile.websiteUrl}
                                                                            </a>
                                                                            : "No Data"
                                                                    )
                                                                    : key.label === 'Industry Type'
                                                                        ? getIndustryType(businessProfile?.industry) || "No Data"
                                                                        : typeof (businessProfile as any)?.[key.value] === 'string'
                                                                            ? (businessProfile as any)?.[key.value]
                                                                            : "No Data"
                                                }
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`p-4 grid gap-y-6 rounded-xl ${classes.container}`}>
                    <div className={`${classes.title} pb-6 ${classes.borderBottom} text-lg font-medium`}>Business Places</div>
                    <div className='flex gap-x-6 w-full '>
                        <div className='w-1/2'>
                            {cinRegisteredAddress && <BusinessAddressTemplate title="Registered Office Address" address={cinRegisteredAddress} />}
                        </div>
                        <div className='w-1/2'>
                            {gstRegisteredAddress && <BusinessAddressTemplate title="Principle Office Address (as per GST)" address={gstRegisteredAddress} />}
                        </div>
                    </div>
                </div>
                <div className={`p-4 grid gap-y-6 rounded-xl ${classes.container}`}>
                    <div className={`${classes.title} pb-6 ${classes.borderBottom} text-lg font-medium`}>About Us</div>
                    <div className={` ${classes.property} font-medium text-base`}>{getProfileDisplayData(businessProfile?.description)}</div>
                </div>
            </div>
        </div>
    )
}

export default ProfileOverviewTemplate;
