import { FormHelperText } from '@mui/material';
import React, { useState } from 'react'
import { createUseStyles } from 'react-jss'
import Button from '../../atoms/Button/Button';
import { HTTP_STATUS, ONBOARDING_STATES } from '../../../utils/types';
import { IOnBoardingPayload } from '../../pages/OnBoarding/OnBoarding.page';
import { useBusinessService } from '../../../services/useBusinessService';
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { FormikProps } from 'formik';
import { ENTITY_TYPES, INDUSTRY_TYPES } from '../../../utils/constant';
import { useNavigate } from 'react-router-dom';

interface EntityTypeTemplateProps {
    formik: FormikProps<IOnBoardingPayload>;
    setFormState: (value: ONBOARDING_STATES) => void;
};

const useStyles = createUseStyles((theme: any) => ({
    heading: {
        color: theme.palette.text.neutral.neutral800,
        fontSize: "28px",
        lineHeight: "34px"
    },
    description: {
        color: theme.palette.text.neutral.neutral700,
        lineHeight: "20px"
    },
    label: {
        color: theme.palette.text.neutral.neutral700,
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "16px"
    },
    unSelected: {
        color: theme.palette.text.primary.primary800,
        border: `1px solid ${theme.palette.border.primary.primary200}`,
        "&:hover": {
            background: theme.palette.background.primary.primary50,
        },
    },
    selected: {
        border: `1px solid ${theme.palette.border.primary.primary200}`,
        background: theme.palette.background.primary.primary50,
    }
}))

export interface IEntityType {
    business: {
        entityType: string,
        industry: string,
    }
}

const EntityTypeTemplate: React.FC<EntityTypeTemplateProps> = ({ formik, setFormState }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const businessService = useBusinessService();
    const { user } = useAuthenticatedUser();
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const [error, setError] = useState(false);

    const [ errors, setErrors ] = useState({ industry: false, product: false });

    const handleSelected = (option: string) => {
        setError(false)
        formik.setFieldValue('entityType', option)
    }

    const handleSelectIndustry = (option: any, existingSelection: string[], formik: any, section: string, field: string) => {
        const selection = new Set(existingSelection);
        if (selection.has(option)) {
            selection.delete(option);
        } else {
            if (field !== 'categories') {
                selection.clear();
            }
            selection.add(option);
        }
        formik.setFieldValue(field, Array.from(selection));
        setErrors(prev => ({ ...prev, [section]: false }));
    };

    const handleSubmit = () => {
        const entityTypeFieldValue = formik.getFieldProps("entityType").value;
        if (!entityTypeFieldValue.length) {
            setError(true);
            return;
        }
        const selectedCategories = formik.getFieldProps("categories").value;
        const selectedIndustry = formik.getFieldProps("industry").value;
        if (selectedIndustry.length === 0 || selectedCategories.length === 0) {
            setErrors({ industry: !!!selectedIndustry.length, product: !!!selectedCategories.length })
            return;
        }
        user && businessService.updateBusiness({ business: { entityType: entityTypeFieldValue, industry: selectedIndustry.join(",") } }, user.businessId)
            .then(res => {
                if (res?.status === HTTP_STATUS.OK) {
                    formik.setFieldValue('entityType', entityTypeFieldValue);
                    formik.setFieldValue('industry', selectedIndustry);
                    setFormState(ONBOARDING_STATES.PRODUCT)
                }
                else showSnackbar('error', 'Something went wrong');
            }).catch(error => {
                console.error(error)
                showSnackbar('error', 'Something went wrong')
            }).finally();
    }

    const onBack = () => {
        setFormState(ONBOARDING_STATES.BUSINESS_DETAILS)
    }

    const handleSkip = () => {
        navigate('/');
    }

    return (
        <div className='grid gap-y-10'>
            {SnackBarComponent}
            <div className={`grid gap-y-1`}>
                <div className={`${classes.heading} font-medium`} >Business Profile</div>
                <div className={`${classes.description} text-base font-medium`}>Please verify your business details</div>
            </div>

            <div className='grid gap-y-10 '>
                <div className='grid gap-y-3'>
                    <div className='grid gap-y-2'>
                        <div className={classes.label}>Entity Type</div>
                        <div className=''>
                            <div className='flex gap-2 flex-wrap'>
                                {
                                    ENTITY_TYPES.map((option, index) => (
                                        <div className={`py-2.5 px-4 text-base font-medium rounded-md w-fit cursor-pointer ${formik.getFieldProps("entityType").value === option?.name ? classes.selected : classes.unSelected}`}
                                            onClick={() => handleSelected(option?.name)} key={index}>{option?.name}</div>
                                    ))
                                }
                            </div>
                            {error &&
                                <FormHelperText error className='top-half '>Entity Type is required</FormHelperText>
                            }
                        </div>
                    </div>

                    <div className='grid gap-y-2'>
                        <div className={classes.label}>Industry</div>
                        <div className=''>
                            <div className='flex gap-2 flex-wrap'>
                                {
                                    INDUSTRY_TYPES.map((option, index) => (
                                        <div className={`py-2.5 px-4 text-base font-medium rounded-md w-fit cursor-pointer ${formik.getFieldProps("industry").value.includes((option.value)) ? classes.selected : classes.unSelected}`}
                                        onClick={() => handleSelectIndustry(option.value, formik.getFieldProps("industry").value, formik, 'industry', 'industry')} key={index}>{option.label}</div>
                                    ))
                                }
                            </div>
                            {errors.industry &&
                                <FormHelperText error className='top-half '>Industry is required</FormHelperText>
                            }
                        </div>
                    </div>

                    <div>Product Category</div>
                </div>

                <div className='flex justify-between'>
                    <Button size='medium' variant='tertiaryContained' label={"Back"} className='' onClick={onBack} />
                    <div className='flex justify-end gap-x-3'>
                        <Button size='medium' variant='tertiaryContained' label={"Skip for now"} onClick={handleSkip} />
                        <Button size='medium' variant="primaryContained" label="Next" className='w-[179px]' onClick={handleSubmit} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EntityTypeTemplate