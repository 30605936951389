import { FC, createContext, useEffect, useState } from "react";
import { API_METHOD, CLASS_TYPES, SHAPE_TYPES, CATALOGUE_TYPES, SECONDARY_CATALOGUE_TYPES, NON_STANDARD_CATALOGUE_TYPES, ATTRIBUTES_WITH_RANGE } from "../utils/constant";
import { request } from "../services";
import { useAuthenticatedUser } from "../hooks/useAuthenticatedUser";
import { CATALOGUE_TYPE_STATES, CLASS_STATES, ResourceOption } from "../utils/types";
import { useLocation } from "react-router-dom";

export const FILTER_URLS = {
    GET_FILTERS: "/filters",
};

interface MetaDataProviderType {
    children: React.ReactNode;
}

export interface ICatelogueFilter {
    standards: ResourceOption[];
    grades: ResourceOption[];
    manufacturers: ResourceOption[];
    brands: ResourceOption[];
    classes: ResourceOption[];
    shapes: ResourceOption[];
    catalogueTypes: ResourceOption[];
    secondaryCatalogueTypes: ResourceOption[];
    nonStandardCatalogueTypes: ResourceOption[];
    attributes: ResourceOption[];
    productAttributes: ResourceOption[],
    attributeFilters: { [key: string]: ResourceOption[] | string[] }
}

export interface FilterContextType {
    metaData: ICatelogueFilter;
    setMetaData: (metaData: ICatelogueFilter) => void;
    loadFilters: (params: FilterRequestParams) => Promise<void>;
}

export interface FilterRequestParams {
    classType?: string,
    catalogueType?: string,
    categoryId?: number
}

const convertAttributeValueToResourceType = (attributes: any) => {
    const filterAttributes = attributes.filter((attribute: any) => !ATTRIBUTES_WITH_RANGE.includes(attribute?.name?.trim().split(" ")?.join("_")?.toUpperCase()))
    const attributeValues: any = {}
    for (let attribute of attributes) {
        const attributeName = attribute.name?.trim();
        if (!filterAttributes.includes(attribute)) {
            attributeValues[attributeName] = ['', ''];
        } else {
            try {
                let parsedData: Set<string> = new Set(JSON.parse(attribute?.attributeOptions));
                attributeValues[attributeName] = Array.from(parsedData).map((value: string) => ({ id: value, name: value }));
            } catch (error) {
                continue;
            }
        }
    }
    return attributeValues;
}

const MetaDataContext = createContext<FilterContextType>({
    metaData: {
        standards: [],
        grades: [],
        manufacturers: [],
        brands: [],
        classes: [],
        shapes: [],
        catalogueTypes: [],
        secondaryCatalogueTypes: [],
        productAttributes: [],
        nonStandardCatalogueTypes: [],
        attributes: [],
        attributeFilters: {}
    },
    setMetaData: () => { },
    loadFilters: async () => { }
});

const MetaDataProvider: FC<MetaDataProviderType> = ({ children }) => {
    const { user: authenticatedUser } = useAuthenticatedUser();
    const location = useLocation();
    const [metaData, setMetaData] = useState<ICatelogueFilter>({
        standards: [],
        grades: [],
        manufacturers: [],
        brands: [],
        classes: [],
        shapes: [],
        catalogueTypes: [],
        secondaryCatalogueTypes: [],
        nonStandardCatalogueTypes: [],
        productAttributes: [],
        attributes: [],
        attributeFilters: {}
    });
    const loadFilters = async (params: FilterRequestParams) => {
        try {
            const filtersResponse = await request(
                API_METHOD.GET,
                FILTER_URLS.GET_FILTERS,
                authenticatedUser,
                null,
                { params }
            );
            setMetaData({ ...filtersResponse.data.data, classes: CLASS_TYPES, shapes: SHAPE_TYPES, catalogueTypes: CATALOGUE_TYPES, nonStandardCatalogueTypes: NON_STANDARD_CATALOGUE_TYPES, secondaryCatalogueTypes: SECONDARY_CATALOGUE_TYPES, attributeFilters: { ...convertAttributeValueToResourceType(filtersResponse?.data?.data?.productAttributes?.[0]?.value?.SPECIFICATION ?? []) } });
        } catch (error) {
            setMetaData({ standards: [], grades: [], manufacturers: [], brands: [], classes: [], shapes: [], catalogueTypes: [], nonStandardCatalogueTypes: [], secondaryCatalogueTypes: [], attributes: [], productAttributes: [], attributeFilters: {} });
        }
    };

    useEffect(() => {
        const params = {
            classType: CLASS_STATES.STANDARD,
            catalogueType: CATALOGUE_TYPE_STATES.CURRENT_INVENTORY,
        };
        !location.pathname.includes('/catalogue/list') && loadFilters(params);
    }, []);

    return (
        <MetaDataContext.Provider value={{ metaData, setMetaData, loadFilters }}>
            {children}
        </MetaDataContext.Provider>
    );
};

export { MetaDataContext, MetaDataProvider };