import React, { useCallback, useContext, useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { Link, Outlet, useNavigate, useSearchParams } from "react-router-dom";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import logoutIcon from "../../../assets/icons/logout.svg"
import profileIcon from "../../../assets/images/profileIcon.svg"
import dotMenu from "../../../assets/icons/zondicons_dots-horizontal-triple.svg"
import ConfirmDialog from "../../molecules/ConfirmDialog/ConfirmDialog";
import TabsV2, { ITabsSchema } from "../../molecules/Tabs/TabsV2";
import { MenuItem, getDashboardMenuSchema } from './DashBoardMenuSchema';
import { useSnackbar } from "../../../hooks/useSnackBar";
import { useAuthenticatedUser } from "../../../hooks/useAuthenticatedUser";
import { CartContext } from "../../../contexts/CartContext";
import { WishlistContext } from "../../../contexts/WishlistContext";
import { ISellerAccountCreationRequestBody, useBusinessProfileService } from "../../../services/useBusinessProfileService";
import { useUserService } from "../../../services/useUserService";
import { useUserProfileTypeService } from "../../../services/useUserProfileTypeService";
import { DASHBOARD_OPTIONS, DASHBOARD_RESOURCES, GENERIC_EXCEPTION_CODE } from '../../../utils/constant';
import { addToQueue, getLatestItemByKey } from "../../../utils/helper";
import { HTTP_STATUS, USER_PROFILE_TYPE } from "../../../utils/types";

const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid ${theme.palette.background.neutral.neutral100}`
    },
    mobileContainer: {
        display: "none",
    },
    webContainer: {
        display: "grid"
    },
    templateContainer: {

    },
    "@media (max-width: 476px)": {
        mobileContainer: {
            display: "grid",
        },
        webContainer: {
            display: "none"
        },
        templateContainer: {
            marginTop: "-36px"
        },
        container: {

            marginTop: "-15px"
        },
    },
    customListItemButton: {
        width: "100%",
        "&:hover": {
            background: theme.palette.background.primary.primary50,
        },
        padding: "8px 12px",
        borderRadius: "8px",
    },
    ".Mui-selected": {
        color: `#333 !important`
    },
    lastLevelListItemButton: {
        color: "#747474"
    },
    selectedOptionHighlight: {
        background: `${theme.palette.background.primary.primary100} !important`
    },
    selectedOption: {
        background: `${theme.palette.background.primary.primary50} !important`,
        color: theme.palette.background.primary.primary600
    },
    logoutButton: {
        color: "#DA1E27"
    },
    name: {
        color: theme.palette.background.primary.primary900
    }

}));

interface DashboardTab {
    label: string;
    to: string;
    subTab?: {
        label: string;
        link: string;
    }[];
}

const dashDoardAccountTabs: DashboardTab[] = [
    {
        label: DASHBOARD_OPTIONS.LOGOUT.label,
        to: "/",
    },
];

const DashBoard = () => {
    const classes = useStyles();
    const cartContextData = useContext(CartContext);
    const wishlistContextData = useContext(WishlistContext);
    const userService = useUserService();
    const navigate = useNavigate();
    const { user, setAuthenticatedUser } = useAuthenticatedUser();
    const [expandedMenu, setExpandedMenu] = useState<{ [key: string]: boolean }>({});
    const [isUserOptionActive, setIsUserOptionActiveTO] = useState(false);
    const toggleUserOption = () => setIsUserOptionActiveTO(prev => !prev);
    const localHistory = getLatestItemByKey("dashboard");
    const latestDashboardTab = localHistory;
    const [confirmDialogState, setConfirmDialogState] = useState<boolean>(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const userProfileTypeService = useUserProfileTypeService();
    const businessProfileService = useBusinessProfileService();
    const [activeUserProfileTab, setActiveUserProfileTab] = useState((JSON.parse(localStorage.getItem('current-profile-type') as string)) ?? USER_PROFILE_TYPE.BUYER);
    const [sellerType, setSellerType] = useState<string>('');
    const { showSnackbar, SnackBarComponent } = useSnackbar();

    useEffect(() => {
        if (activeUserProfileTab === USER_PROFILE_TYPE.SELLER) {
            verifySellerProfile();
        }
        localStorage.setItem('current-profile-type', JSON.stringify(activeUserProfileTab));
    }, [activeUserProfileTab])

    const verifySellerProfile = async () => {
        if (!user?.isVendor) {
            if (!(await handleVendorConfirmDialog(true)(true)))
                setConfirmDialogState(true);
        }
    }

    function handleOptionClick(option: string, isSubTab: boolean = false) {
        // setSelectedItem(option);
        // if (isSubTab) {
        //   setMenuOpen(false);
        //   return;
        // }
        // setOpenTab((prevOpenTab) => (prevOpenTab === option ? "" : option));
        // setSelectedTabLabel(option);

    }

    function handleAccountOptionClick(option: string, isSubTab: boolean = false) {
        // if (DASHBOARD_OPTIONS.LOGOUT.label === option) {
        //   cartContextData.clearCartState();
        //   setAuthenticatedUser(null);
        // }
    }

    const handleMenuToggler = () => {
        // setMenuOpen((prevIsOpen) => !prevIsOpen);
    };

    const handleMenuChange = (keys: string[]) => () => {
        if (keys.every(key => expandedMenu[key])) {
            delete expandedMenu[keys.pop() as string];
            setExpandedMenu({ ...expandedMenu });
        }
        else {
            const updatedExpandedMenu: { [key: string]: boolean } = keys.reduce(
                (previousValue: { [key: string]: boolean }, currentValue: string) => (
                    { ...previousValue, [currentValue]: true }
                ), {})
            addToQueue(JSON.stringify({ "key": "dashboard", "expandedMenu": updatedExpandedMenu }));
            setExpandedMenu(updatedExpandedMenu);
        }
    };

    const getUserDetails = async () => {
        let res = await userService.getUser();
        if (res.status === HTTP_STATUS.OK) {
            setAuthenticatedUser({ ...user, ...res?.data?.data })
        }
    }

    useEffect(() => {
        if (latestDashboardTab) {
            setExpandedMenu(latestDashboardTab.expandedMenu);
        }
        getUserDetails();
    }, [])

    const handleLogout = () => {
        setAuthenticatedUser(null);
        wishlistContextData.clearWishlistState();
        cartContextData.clearCartState();
        navigate('/')
    };

    const renderMenuItems = useCallback((menuItems: MenuItem[], parent: string[] = []) => {
        return menuItems.map(({ key, route, icon, subList, disabled }) => {
            return <div key={key}  className="">
                <div className="flex flex-row-reverse">
                    <ListItemButton
                        component={route ? Link : "span"}
                        to={route}
                        selected={!!expandedMenu[key]}
                        onClick={handleMenuChange([...parent, key])}
                        className={`
                                ${classes.customListItemButton} 
                                ${!subList?.length && classes.lastLevelListItemButton}
                                ${expandedMenu[key] ? !subList?.length ? classes.selectedOption : '!bg-transparent' : '!bg-none'} 
                            `}
                        disabled={disabled || (!!!user?.email && key === DASHBOARD_RESOURCES.CHANGE_PASSWORD)}
                    >
                        {icon && <ListItemIcon style={{ marginRight: "-28px" }}>
                            <img src={icon} alt="icon" />
                        </ListItemIcon>}

                        <ListItemText primary={key} className={`${['pl-1', 'pl-2', 'pl-3'][parent.length]}`} />
                        {subList?.length && <div >{expandedMenu[key] ? <ExpandLess /> : <ExpandMore />}</div>}
                    </ListItemButton>
                    {!subList?.length && expandedMenu[key] && <div className={`pl-1 rounded-lg  mr-1 ${classes.selectedOptionHighlight}`}></div>}
                </div>
                <Collapse in={expandedMenu[key]} timeout="auto" unmountOnExit>
                    {subList && renderMenuItems(subList, [...parent, key])}
                </Collapse>
            </div>
                ;
        });
    }, [expandedMenu, user, activeUserProfileTab]);

    const schema: ITabsSchema[] = [
        { label: 'Buyer', component: <></> },
        { label: 'Seller', component: <></> },
    ]

    const handleVendorConfirmDialog = (isCheckProfile: boolean) => async (value: boolean) => {
        if (value) {
            if (!user || !user.businessId) {
                showSnackbar("error", "Buyer account doesn't exist or seller profile missing");
                return false;
            }

            if (!sellerType) {
                return false;
            }

            const res = await userProfileTypeService.createVendorProfile({ checkProfile: isCheckProfile });
            if (res?.status === HTTP_STATUS.OK || res?.data.exceptionCode === GENERIC_EXCEPTION_CODE.DUPLICATE_ENTRY) {

                const data: ISellerAccountCreationRequestBody = {
                    "vendorType": sellerType
                }

                const sellerUpdateResponse = await businessProfileService.updateBusinessSellerProfile(data, user.businessId)
                if (sellerUpdateResponse?.status === HTTP_STATUS.OK) {
                    let updatedUser = { ...user, ...{ isVendor: true } };
                    setAuthenticatedUser(JSON.parse(JSON.stringify(updatedUser)));
                    return true;
                }
                showSnackbar("error", "Error creating seller profile")
                return false;

            } else {
                if (!isCheckProfile) {
                    setActiveUserProfileTab(USER_PROFILE_TYPE.BUYER);
                    showSnackbar("error", "Error creating seller profile")
                }
                return false;
            }
        } else {
            !isCheckProfile && setActiveUserProfileTab(USER_PROFILE_TYPE.BUYER);
            return false;
        }
    }

    const getProfileType = () => {
        return (activeUserProfileTab === USER_PROFILE_TYPE.SELLER && user?.isVendor)
            ? USER_PROFILE_TYPE.SELLER : USER_PROFILE_TYPE.BUYER;
    };

    return (
        <>
            {SnackBarComponent}
            <div className={`${classes.webContainer} gap-y-6 m-auto max-container `} style={{ padding: "0px 70px 108px 70px" }}>
                <div>
                    {/* <Breadcrumbs /> */}
                </div>
                <div className="flex gap-x-4 ">
                    <div className={`w-1/5`}>
                        <div className={`py-6 px-2 rounded-2xl mt-0 flex flex-col ${classes.container}`}>
                            <div className="border-b relative">
                                <div className=" mb-4 flex gap-x-3 justify-between">
                                    <div className="rounded-full">
                                        <div className={`${classes.selectedOptionHighlight} flex justify-center items-center font-bold rounded-full w-10 h-10`}>
                                            {user?.fullName?.[0]}
                                        </div>
                                    </div>
                                    <div className="flex flex-col flex-1">
                                        <span className={`${classes.name} text-sm font-medium`}>{user?.fullName}</span>
                                        {/* //TODO - Currently registraionCode is not available in the system */}
                                        {/* <span className={`text-xs ${classes.title}`}>{user?.registrationCode}</span> */}
                                    </div>
                                    <div>
                                        {isUserOptionActive && <div className="absolute  right-6 bottom-1 border px-4 py-5 flex flex-col gap-y-4 rounded-lg bg-white z-10 w-40">
                                            <div className={` ${classes.name} text-sm flex gap-x-2 cursor-pointer`}>
                                                <img src={profileIcon} alt="profileIcon" />
                                                <span>My Profile</span>
                                            </div>
                                            <span className="border-t"></span>
                                            <div className={`text-sm flex gap-x-2 ${classes.logoutButton} cursor-pointer`} onClick={handleLogout}>
                                                <img src={logoutIcon} alt="logoutIcon" />
                                                <span>Logout</span>
                                            </div>
                                        </div>}
                                        <div className="cursor-pointer p-2">
                                        <img src={dotMenu} alt="dotMenu" onClick={toggleUserOption} className="w-4 h-4"/>
                                        </div>
                                            
                                    </div>
                                </div>
                                <TabsV2 schema={schema} value={activeUserProfileTab} setValue={setActiveUserProfileTab} />
                                <ConfirmDialog
                                    dialogOpen={confirmDialogState}
                                    setDialogOpen={setConfirmDialogState}
                                    message="You are currently not registered as a seller"
                                    question="Do you want to register as a seller?"
                                    onSubmit={handleVendorConfirmDialog(false)}
                                    sellerType={sellerType}
                                    setSellerType={setSellerType}
                                />
                            </div>
                            <div className="flex-1 h-auto mt-6">
                                {!!user && <List component="nav" sx={{padding: "0px"}}>
                                    {renderMenuItems(getDashboardMenuSchema(getProfileType()))}
                                </List>}
                            </div>
                        </div>
                    </div>
                    <div className="w-4/5">
                        <Outlet />
                    </div>
                </div>
            </div>

            <div className={`${classes.mobileContainer} gap-y-4 m-auto max-container`}>
                {/* <div className="px-4">
          <Breadcrumbs />
        </div>
        <div className="grid gap-x-6 px-4 ">

          <div className="flex gap-x-2 w-2/3 z-10 relative" onClick={handleMenuToggler}>
            <WindowIcon className={ `rounded-md  cursor-pointer ${classes.title}` }sx={{ fontSize: 30 }} />
            <div className={`${classes.property} text-base font-black my-auto`}>{selectedTabLabel}</div>
            <ArrowDropDownIcon className={`${classes.title} my-auto`} />
          </div>

          {isMenuOpen && (
          <div className={`w-4/5 grid gap-y-2 p-3 rounded-md mt-0 z-20 absolute bg-white ${classes.container}`}>
            <div className={`flex justify-between border-b-2 pb-3 text-xl font-black ${classes.title}`}>
              <div>Overview</div>
              <CloseRoundedIcon sx={{ fontSize: 30}} onClick={handleMenuToggler}/>
            </div>
            <div className="grid gap-y-2 pb-3 border-b">
              <div className={`text-base font-black ${classes.property}`}>Accounts</div>
              <div className="grid">
                {dashDoardAccountTabs.map((tab) => (
                  <div key={tab.label}>
                    <ListItemButton
                      sx={{ padding: "12px", display: "flex", justifyContent: "space-between", borderRight: selectedItem === tab.label ? `2px solid  #1C3F9F` : 'none' }}
                      component={Link}
                      to={tab.to}
                      onClick={() => handleAccountOptionClick(tab.label)}
                      selected={selectedItem === tab.label}
                    >
                      <div>{tab.label}</div>
                    </ListItemButton>
                  </div>
                ))}
              </div>
            </div>
            <div className="grid gap-y-2 pb-3">
              <div className={`text-base font-black ${classes.property}`}>Request / Orders</div>
              <div className="grid">
                {dashDoardRequestTabs.map((tab) => (
                  <div key={tab.label}>
                    <ListItemButton
                      sx={{ padding: "12px", display: "flex", justifyContent: "space-between", borderRight: selectedItem === tab.label ? `2px solid  #1C3F9F` : 'none' }}
                      component={Link}
                      to={tab.to}
                      onClick={() => handleOptionClick(tab.label)}
                      selected={selectedItem === tab.label}
                    >
                      <div>{tab.label}</div>
                      {tab.subTab && <div>{openTab === tab.label ? <ExpandLess /> : <ExpandMore />}</div>}
                    </ListItemButton>
                    <Collapse className="bg-slate-100" in={openTab === tab.label} timeout="auto" unmountOnExit>
                      {tab?.subTab?.map((option, index) => (
                        <List component="div" disablePadding key={index}>
                          <ListItemButton
                            component={Link}
                            to={option.link}
                            sx={{ padding: "12px", borderRight: selectedItem === option.label ? `2px solid  #1C3F9F` : 'none' }}
                            onClick={() => handleOptionClick(option.label, true)}
                            selected={selectedItem === option.label}
                          >
                            {option.label}
                          </ListItemButton>
                        </List>
                      ))}
                    </Collapse>
                  </div>
                ))}
              </div>
            </div>
          </div>
          )}
          <div className={`${classes.templateContainer} w-full`}>
            <Outlet />
          </div>
        </div> */}
            </div>
        </>

    );
};

export default DashBoard;