import { useState } from "react";
import ContactUsPopup from "../../molecules/ContactUsPopup/ContactUsPopup";
import { CONNECT_WITH_US } from "../../../utils/constant";
import callIcon from "../../../assets/icons/callIcon.svg";
import IconButton from "../../atoms/Button/IconButton";

const ContactUs: React.FC = () => {
    const [arrowUpDown, setArrowUpDown] = useState<boolean>(false);

    const handleClick = () => {
        setArrowUpDown(!arrowUpDown);
    }

    return (
        <>
            <IconButton className="!px-2.5" variant="secondaryText" label={CONNECT_WITH_US.TALK_TO_US} size="medium" startIcon={<img src={callIcon} alt="call" />} onClick={handleClick} />
            {arrowUpDown && <ContactUsPopup />}
        </>
    )
}

export default ContactUs;