import React, { useEffect, useState } from 'react'
import { useSnackbar } from '../../../hooks/useSnackBar';
import { useBusinessProfileService } from '../../../services/useBusinessProfileService';
import { HTTP_STATUS } from '../../../utils/types';
import  { Address } from '../../template/OnBoarding/ProfileOverview.template';
import { ILocationGstinForm } from './BussinessLocation.page';
import ProfileOverviewTemplate from '../../template/StoreFront/ProfileOverview.template';

interface Cin {
    cin: number;
    entityType: string;
    registeredOffice: string;
    dateOfIncorporation: string;
}

interface Udhyam {
    businessType: string;
    udhyamNumber: number;
}

interface Location {
    gstin: string;
    state: string;
}

interface TurnOver {
    year: string;
    amount: number;
}

interface BankAccount {
    ifsc: string;
    branch: string;
    bankName: string;
    isDefault: boolean;
    accountName: string;
    accountNumber: string;
}

interface Finance {
    turnOvers: TurnOver[];
    bankAccounts: BankAccount[];
}

interface SocialLinks {
    linkedin: string;
}

interface Poc {
    name: string;
    email: string;
    mobile: string;
    prefix: string;
    department: string;
    profilePic: string;
    countryCode: string;
    designation: string;
    socialLinks: SocialLinks;
    isAuthorized: boolean;
    whatsAppLink: string;
    alternateEmail: string;
    alternateMobile: string;
    modeOfCommunication: string;
}

interface Authority {
    authority: string;
}

interface User {
    id: number;
    email: string | null;
    password: string | null;
    mobileNumber: string;
    fullName: string;
    countryCode: string;
    userType: string;
    gstin: string;
    department: string | null;
    designation: string | null;
    alternativeEmail: string | null;
    socialLinks: SocialLinks | null;
    isAuthorized: boolean;
    prefix: string | null;
    whatsAppLink: string | null;
    alternateMobile: string | null;
    createdAt: string;
    updatedAt: string;
    enabled: boolean;
    phoneNumberVerified: boolean;
    authorities: Authority[];
    accountNonExpired: boolean;
    accountNonLocked: boolean;
    credentialsNonExpired: boolean;
    emailVerified: boolean;
    username: string | null;
}

export interface IBusinessProfile {
    id: number;
    name: string;
    userId: number;
    description: string;
    gstin: string;
    pan: string;
    cin: Cin;
    udhyam: Udhyam;
    yearOfEstablishment: number;
    numberOfEmployees: number;
    websiteUrl: string;
    industryType: string;
    businessType: string;
    brand: string;
    locations: Location[];
    finance: Finance;
    pocs: Poc[];
    communicationChannel: string;
    language: string;
    contactMode: string;
    paymentMode: string;
    whatsappUpdates: boolean;
    whatsappCommunity: boolean;
    emailUpdates: boolean;
    docsAccess: boolean;
    createdAt: string;
    updatedAt: string;
    user: User;
    address: Address;
    cinRegisteredAddress: Address;
    docs: any[];
    entityType: string;
    industry: string;
}



const initialValues = {
    locations: [],
    noOfRows: null,
    newLocations: []
}

const StoreFrontAboutUsPage: React.FC = () => {

    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const bussinessProfileService = useBusinessProfileService();

    const [businessProfile, setBusinessProfileTo] = useState<IBusinessProfile | null>(null);
    const [bussinessLocation, setBussinessLocationTo] = useState<ILocationGstinForm>(initialValues);

    const getBusinessProfile = async () => {
        try {
            const businessProfileResponse = await bussinessProfileService.getBusinessProfile();
            if (businessProfileResponse.status === HTTP_STATUS.OK) {
                const profileData = businessProfileResponse?.data?.data;
                setBusinessProfileTo(profileData);
                setBussinessLocationTo({ locations: profileData.locations || [], noOfRows: null, newLocations: [] });
            }
        } catch (error) {
            showSnackbar('error', 'Business Profile fetch failed');
        }
    };

    useEffect(() => {
        getBusinessProfile();
    }, []);

    return (
        <div>
            {SnackBarComponent} 
            {businessProfile && <ProfileOverviewTemplate businessProfile={businessProfile} />}
        </div>
    )
}
export default StoreFrontAboutUsPage;
