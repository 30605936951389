import React from 'react'
import Button from '../../../atoms/Button/Button';
import { createUseStyles } from 'react-jss';
import { FormikProps } from 'formik';
import MultiSelectInput2, { IMultiSelectOption } from '../../../atoms/MultiSelectInput/MultiSelectInput2';
import { IBrand, IMaterialBrandManufacturer } from './MaterialBrandManufacturer.template';
import { IManufacturer } from '../../Rfq/ProductRequirementForm.template';

interface MaterialBrandManufacturerFormTemplateProps {
    handleSelectChange: (key: string) => (option: IMultiSelectOption[]) => void;
    formik: FormikProps<IMaterialBrandManufacturer>;
    manufacturers: IManufacturer[]
    brands: IBrand[]
}

const useStyles = createUseStyles((theme: any) => ({
    primary500: {
        color: theme.palette.text.primary.primary500,
    },
    heading: {
        color: theme.palette.text.primary.primary700
    },
    tertiary100Border: {
        borderColor: theme.palette.border.neutral.neutral100
    },
    sectionBackground: {
        background: 'white',
        border: `1px solid ${theme.palette.border.neutral.neutral100}`
    },
}));

const MaterialBrandManufacturerFormTemplate:React.FC<MaterialBrandManufacturerFormTemplateProps> = ({formik, handleSelectChange, manufacturers, brands}) => {
    const classes = useStyles();
    return (
        <form className={` p-6 rounded-xl ${classes.sectionBackground}`} onSubmit={formik.handleSubmit}>
            <div className={`grid gap-y-3`}>
                <div className={`w-full flex justify-between items-center`}>
                    <span className={`${classes.heading} text-lg font-medium`}>Manufacturer/Brand Preference</span>
                    <Button variant='tertiaryContained' type="submit" label="Save" className='!font-normal' disabled={!formik.values?.brands?.length}/>
                </div>
                <div className={`${classes.tertiary100Border} border-b`}></div>
                <div className={`grid grid-cols-2 gap-6`}>
                    <MultiSelectInput2
                        {...formik.getFieldProps('manufacturers')}
                        options={manufacturers?.map(option => ({ label: option?.name, value: option?.id }))}
                        label="Manufacturer"
                        value={formik.values.manufacturers}
                        onchange={handleSelectChange('manufacturers')}
                        fullWidth
                        placeholder='Select Manufacturer'
                    />
                    <MultiSelectInput2
                        {...formik.getFieldProps('brands')}
                        options={brands?.map(option => ({ label: option?.name, value: option?.name }))}
                        label="Brand"
                        value={formik.values.brands}
                        onchange={handleSelectChange('brands')}
                        fullWidth
                        disabled={!!!formik.values?.manufacturers?.length}
                        placeholder='Select Brands'
                    />
                </div>
            </div>
        </form>
    )
}

export default MaterialBrandManufacturerFormTemplate