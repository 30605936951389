import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { createUseStyles } from 'react-jss';
import Button from '../../../atoms/Button/Button';
import CloseIcon from '@mui/icons-material/Close';
import TextFieldV2 from '../../../atoms/Input/TextFieldV2';
import { FormikErrors, FormikProps } from 'formik';
import { IAddNewInventoryDetail, IAddVendorInventoryForm } from './AddNewVendorInventory.template';
import CustomRadioGroup from '../../../molecules/CustomRadioGroup/CustomRadioGroup';
import uploadIconVendor from '../../../../assets/icons/uploadIconVendor.svg'
import { FormHelperText } from '@mui/material';
import SelectV2 from '../../../atoms/Select/SelectV2';
import { PACKAGING_TYPES, STATUS_OPTION } from '../../../../utils/constant';
import { iModalFormikForm } from './AddInventorysection.template';
import { useSnackbar } from '../../../../hooks/useSnackBar';

export interface IAddNewInventoryModalProps {
    dialogOpen: boolean;
    setDialogOpen: (open: boolean) => void;
    formik: FormikProps<IAddVendorInventoryForm>;
    modalFormik: FormikProps<iModalFormikForm>;
    isMillTestSelected?: Boolean;
    setIsMillTestSelectedTo?: (isMillTest: boolean) => void;
    setSelectedFileTo?: (file: File | null) => void;
    selectedFile?: File | null;
}

const radioGroupOption = [
    { value: "true", label: "Yes" },
    { value: "false", label: "No" },
]

const useStyles = createUseStyles((theme: any) => ({
    container: {
        "& .MuiDialog-paper": {
            width: "1154px !important",
            maxWidth: "1154px !important",
            borderRadius: "12px"
        },
    },
    closeIcon: {
        color: theme.palette.background.neutral.neutral600
    },
    selectBox: {
        border: `1px solid ${theme.palette.background.neutral.neutral100}`,
    },
    headingText: {
        color: theme.palette.text.primary.primary800,
    },
    radioColor: {
        color: theme.palette.text.primary.primary800,
        width: '23%'
    },
    uploadBox: {
        border: `1px solid ${theme.palette.border.primary.primary800}`,
    },
    uploadText: {
        color: theme.palette.text.primary.primary800,
    },
}));

const AddNewInventoryModalTemplate: React.FC<IAddNewInventoryModalProps> = ({ dialogOpen, setDialogOpen, formik, modalFormik, isMillTestSelected, setIsMillTestSelectedTo, setSelectedFileTo, selectedFile }) => {
    const classes = useStyles();
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    
    const handleDialogClose = () => {
        if(selectedFile){
           setSelectedFileTo?.(null); 
        }
        setDialogOpen(false);
        modalFormik.setFieldValue('wareHouses', []);
        modalFormik.setTouched({});
    };
    const handleSubmitClose = () => {
        if(isMillTestSelected && !selectedFile){
        showSnackbar('error',"Please upload Mill Test Certificate in order to proceed");
        return;    
        }
        modalFormik.handleSubmit();
        if (!modalFormik.dirty) {
            modalFormik.setTouched({});
        }
    };
    const addAnotherWarehouseFields = () => {
        const newWarehouseDetail: IAddNewInventoryDetail = {
            warehouseId: modalFormik.values.wareHouses[0]?.warehouseId,
            vendorUsnCode: '',
            grossWeight: 0,
            netWeight: 0,
            packagingType: '',
            isMillTest: false,
        };
        modalFormik.setFieldValue('wareHouses', [...modalFormik.values.wareHouses, newWarehouseDetail])
    }
    const handleChange = (event: any) => {
        const { value } = event.target;
        setIsMillTestSelectedTo?.(value === 'true');
        if(value === 'false'){
          setSelectedFileTo?.(null);  
        }
    };
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const validTypes = ['application/pdf', 'image/jpeg', 'image/jpg', 'image/png'];
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            if (!validTypes.includes(file.type)) {
                showSnackbar('error', 'Invalid file type. Only PDF, JPEG, JPG, and PNG are allowed.');
                return;
            }
            if (file != null && file !== undefined) {
                setSelectedFileTo!(file);
            }
        }
    };

    return (
        <Dialog fullWidth className={`${classes.container} w-full`} open={dialogOpen} onClose={handleDialogClose}>
            <div className="p-6 border-3 grid gap-6">
                <div className="flex justify-between">
                    <span className={`${classes.headingText} text-2xl font-medium`}>Add Inventory</span>
                    <div onClick={handleDialogClose}>
                        <CloseIcon className={`${classes.closeIcon}`} />
                    </div>
                </div>

                {modalFormik?.values?.wareHouses?.map((warehouse: IAddNewInventoryDetail, index: number) => (
                    <div key={index} className={`${classes.selectBox} rounded-xl grid gap-6 p-4`}>
                        <div className="flex gap-6">

                            <div className='w-1/4'>
                                <TextFieldV2
                                    variant="outlined"
                                    label="Your USN No. (Optional)"
                                    placeholder="Enter Number"
                                    fullWidth
                                    {...modalFormik.getFieldProps(`wareHouses[${index}].vendorUsnCode`)}
                                    type="text"
                                />
                            </div>

                            <div className='w-1/4'>
                                <TextFieldV2
                                    variant="outlined"
                                    placeholder="Enter Quantity"
                                    label="Net Weight"
                                    fullWidth
                                    {...modalFormik.getFieldProps(`wareHouses[${index}].netWeight`)}
                                    type="number"
                                    error={
                                        modalFormik?.touched && modalFormik?.touched?.wareHouses && modalFormik?.touched?.wareHouses?.[index]?.netWeight &&
                                        Boolean((modalFormik?.errors && modalFormik?.errors?.wareHouses as FormikErrors<IAddNewInventoryDetail>[])?.[index]?.netWeight)
                                    }
                                />
                                {modalFormik?.touched?.wareHouses?.[index]?.netWeight && modalFormik?.errors?.wareHouses && (modalFormik?.errors?.wareHouses as FormikErrors<IAddNewInventoryDetail>[])[index]?.netWeight &&
                                    <FormHelperText error className='top-half '>{(modalFormik?.errors?.wareHouses as FormikErrors<IAddNewInventoryDetail>[])?.[index]?.netWeight}</FormHelperText>
                                }
                            </div>

                            <div className='w-1/4'>
                                <TextFieldV2
                                    variant="outlined"
                                    placeholder="Enter Quantity"
                                    label="Gross Weight"
                                    fullWidth
                                    {...modalFormik.getFieldProps(`wareHouses[${index}].grossWeight`)}
                                    type="number"
                                    error={
                                        modalFormik?.touched?.wareHouses?.[index]?.grossWeight &&
                                        Boolean((modalFormik?.errors?.wareHouses as FormikErrors<IAddNewInventoryDetail>[])?.[index]?.grossWeight)
                                    }
                                />
                                {modalFormik?.touched?.wareHouses?.[index]?.grossWeight && modalFormik?.errors?.wareHouses && (modalFormik?.errors?.wareHouses as FormikErrors<IAddNewInventoryDetail>[])[index]?.grossWeight &&
                                    <FormHelperText error className='top-half '>{(modalFormik?.errors?.wareHouses as FormikErrors<IAddNewInventoryDetail>[])[index]?.grossWeight}</FormHelperText>
                                }
                            </div>

                            <div className='w-1/4'>
                                <SelectV2
                                    label="Packing Type"
                                    placeholder="Select packaging"
                                    fullWidth
                                    {...modalFormik.getFieldProps(`wareHouses[${index}].packagingType`)}
                                    error={
                                        modalFormik?.touched?.wareHouses?.[index]?.packagingType &&
                                        Boolean((modalFormik?.errors?.wareHouses as FormikErrors<IAddNewInventoryDetail>[])?.[index]?.packagingType)
                                    }
                                    options={
                                        PACKAGING_TYPES?.map((packaging) => ({
                                            value: packaging.value,
                                            label: packaging.label,
                                        })) || []
                                    }
                                />
                                {modalFormik?.touched?.wareHouses?.[index]?.packagingType && modalFormik?.errors?.wareHouses && (modalFormik?.errors?.wareHouses as FormikErrors<IAddNewInventoryDetail>[])[index]?.packagingType &&
                                    <FormHelperText error className='top-half '>{(modalFormik?.errors?.wareHouses as FormikErrors<IAddNewInventoryDetail>[])[index]?.packagingType}</FormHelperText>
                                }
                            </div>

                        </div>


                    </div>
                ))}
                <div className="grid gap-4">
                    <span className={`${classes.headingText} font-medium text-base`}>Mill Test Certificate Available?</span>
                    <CustomRadioGroup
                        options={radioGroupOption}
                        value={isMillTestSelected ? 'true' : 'false'}
                        name="isMillTest"
                        onChange={(e) => handleChange(e)}
                        className={`${classes.radioColor}`}
                    />
                </div>

                {isMillTestSelected && (
                    <div className={`${classes.uploadBox} p-3 w-1/2 flex justify-center rounded-xl gap-2`}>
                        <img src={uploadIconVendor} alt="Upload Icon" />
                        <div
                            className={`${classes.uploadText} text-lg font-medium`}
                            style={{ cursor: 'pointer' }}
                            onClick={() => fileInputRef.current?.click()}
                        >
                            {selectedFile ? selectedFile.name : 'Mill Test Certificate (MTC)'}
                            <input
                                type="file"
                                ref={fileInputRef}
                                style={{ display: 'none' }}
                                onChange={handleFileChange}
                            />
                        </div>
                    </div>
                )}

                <div className="flex justify-between">
                    <Button
                        variant="tertiaryContained"
                        label="+Add Another"
                        onClick={addAnotherWarehouseFields}
                    />
                    <div className='flex gap-4'>
                        <Button
                            variant="tertiaryContained"
                            label="Cancel"
                            onClick={handleDialogClose}
                        />
                        <Button
                            variant="primaryContained"
                            label="+Add"
                            onClick={handleSubmitClose}
                        />
                    </div>
                </div>
            </div>
        </Dialog>
    );
}

export default AddNewInventoryModalTemplate;
