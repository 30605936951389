import { FormHelperText } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import Button from '../../atoms/Button/Button';
import { HTTP_STATUS, ONBOARDING_STATES } from '../../../utils/types';
import { IOnBoardingPayload } from '../../pages/OnBoarding/OnBoarding.page';
import { useBusinessService } from '../../../services/useBusinessService';
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { FormikProps } from 'formik';
import { useAdminMasterService } from '../../../services/useAdminMasterService';
import { ICategory } from '../Rfq/ProductRequirementForm.template';
import { useNavigate } from 'react-router-dom';

interface ProductTemplateProps {
    formik: FormikProps<IOnBoardingPayload>;
    setFormState: (value: ONBOARDING_STATES) => void;
};


export interface IProduct {
    business: {
        categories: string
    }
}

const useStyles = createUseStyles((theme: any) => ({
    heading: {
        color: theme.palette.text.neutral.neutral800,
        fontSize: "28px",
        lineHeight: "34px"
    },
    description: {
        color: theme.palette.text.neutral.neutral700,
        lineHeight: "20px"
    },
    selected: {
        border: `1px solid ${theme.palette.border.primary.primary200}`,
        background: theme.palette.background.primary.primary50,
    },
    unSelected: {
        color: theme.palette.text.primary.primary800,
        border: `1px solid ${theme.palette.border.primary.primary200}`,
        "&:hover": {
            background: theme.palette.background.primary.primary50,
        },
    },
    label: {
        color: theme.palette.text.neutral.neutral700,
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "16px"
    },
}))

const ProductTemplate: React.FC<ProductTemplateProps> = ({ formik, setFormState }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const adminMasterService = useAdminMasterService();
    const businessService = useBusinessService();
    const { user } = useAuthenticatedUser();
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const [categories, setCategoriesTo] = useState<ICategory[]>([]);
    const [errors, setErrors] = useState({ product: false });
    const [loading, setLoading] = useState(false);

    const loadCategories = async () => {
        try {
            adminMasterService.getAllCategories({ level: 2, page: 0, size: 10 })
                .then(res => {
                    if (res?.status === HTTP_STATUS.OK)
                        setCategoriesTo(res?.data?.data?.content)
                }).catch((error) => {
                    showSnackbar('error', "Error Fetching Category: ");
                })
        } catch (error) {
            setCategoriesTo([]);
        }
    };

    useEffect(() => {
        loadCategories();
    }, [])

    const handleSelect = (option: any, existingSelection: string[], formik: any, section: string, field: string) => {
        const selection = new Set(existingSelection);
        if (selection.has(option)) {
            selection.delete(option);
        } else {
            if (field !== 'categories') {
                selection.clear();
            }
            selection.add(option);
        }
        formik.setFieldValue(field, Array.from(selection));
        setErrors(prev => ({ ...prev, [section]: false }));
    };

    const handleSubmit = () => {
        const selectedCategories = formik.getFieldProps("categories").value;
        if (selectedCategories.length === 0) {
            setErrors({ product: !!!selectedCategories.length })
            return;
        }
        setLoading(true)
        user && businessService.updateBusiness({ business: { categories: selectedCategories.join(",") } }, user.businessId)
            .then(res => {
                if (res?.status === HTTP_STATUS.OK) {
                    formik.setFieldValue('categories', selectedCategories);
                    setFormState(ONBOARDING_STATES.REQUIREMENTS)
                }
                else showSnackbar('error', 'Something went wrong');
            }).catch(error => {
                console.error(error)
                showSnackbar('error', 'Something went wrong')
            }).finally(() => { setLoading(false) });
    }

    const onBack = () => {
        setFormState(ONBOARDING_STATES.ENTITY_TYPE)
    }

    const handleSkip = () => {
        navigate('/');
    }

    return (
        <div className='grid gap-y-10'>
            {SnackBarComponent}
            <div className={`grid gap-y-1`}>
                <div className={`${classes.heading} font-medium`} >Business Profile</div>
                <div className={`${classes.description} text-base font-medium`}>Please verify your business details</div>
            </div>
            
            <div className='grid gap-y-2'>
                <div className={classes.label}>Service Requirements</div>
                <div>
                    <div className='grid container grid-cols-3 gap-3'>
                        {
                            categories?.map((category, index) => (
                                <div className={`text-base  rounded-md w-full cursor-pointer flex flex-col items-center  px-3 pb-3 gap-1 ${formik.getFieldProps("categories").value.includes(category?.id.toString()) ? classes.selected : classes.unSelected}`}
                                    onClick={() => handleSelect(category.id.toString(), formik.getFieldProps("categories").value, formik, 'product', 'categories')} key={index}>
                                    <div key={category.id} className='flex justify-center w-24'>
                                        <img src={category?.images?.at(0)?.path} alt={""} className='w-3/4 aspect-square' />
                                    </div>
                                    <div className='text-center'>{category?.name}</div>
                                </div>
                            ))
                        }
                    </div>
                    {errors.product &&
                        <FormHelperText error className='top-half '>Product Category is required</FormHelperText>
                    }
                </div>
            </div>

            <div className='flex justify-between'>
                <Button size='medium' variant='tertiaryContained' label={"Back"} className='' onClick={onBack} />
                <div className='flex justify-end gap-x-3'>
                    <Button size='medium' variant='tertiaryContained' label={"Skip for now"} onClick={handleSkip} />
                    <Button size='medium' variant="primaryContained" label="Next" className='w-[179px]' onClick={handleSubmit} />
                </div>
            </div>
        </div>
    )
}

export default ProductTemplate