import React, { useEffect, useMemo, useState } from 'react'
import { createUseStyles } from 'react-jss';
import { Checkbox, FormControl } from '@mui/material';
import * as Yup from "yup";
import { Service, SubAttributes, useVASMasterCategoryService } from '../../../../../services/useVASMasterCategoryService';
import { USC_FORM_STATE } from '../../../../pages/DashBoard/ValueAddedService/USC/USCCreate.page';
import { useSnackbar } from '../../../../../hooks/useSnackBar';
import { Attribute, SubAttribute } from '../../../../../services/useAttributeServiceV2';
import { HTTP_STATUS, VAS_MASTER_CATEGORY_TYPE } from '../../../../../utils/types';
import { MODE, SERVICE_STATUS_OPTIONS } from '../../../../../utils/constant';
import TextFieldV2 from '../../../../atoms/Input/TextFieldV2';
import { titleModification } from '../../../../../utils/helper';
import StatusV2 from '../../../../atoms/Status/StatusV2';
import Button from '../../../../atoms/Button/Button';
import AutoCompleteInputV2, { AutoCompleteOption } from '../../../../atoms/AutoCompleteInput/AutoCompleteInput';

export interface USCAndAttributeFormTemplateProps {
    mode: string;
    onClose: () => void; 
    formik: any;
    setFormStateTo: (state: USC_FORM_STATE) => () => void;
}

const useStyles = createUseStyles((theme: any) => ({
    border: {
        border: `1px solid ${theme.palette.border.neutral.neutral100}`
    },
    componentHeading:{
        color: theme.palette.text.primary.primary900
    },
    componentSubHeading:{
        color: theme.palette.text.neutral.neutral700
    },
    heading: {
        color: theme.palette.text.primary.primary900,
    },
    errorMessage: {
        color: theme.palette.text.secondary.secondary500,
    },
    activeProgressBar: {
        backgroundColor: theme.palette.background.primary.primary500,
    },
    inActiveProgressBar: {
        backgroundColor: theme.palette.background.neutral.neutral100,
    },
    inactiveBarHeading: {
        color: theme.palette.text.neutral.neutral100
    },
    barHeading: {
        color: theme.palette.text.primary.primary500,
    },
    checkBox: {
        color: theme.palette.text.neutral.neutral300,
        '&.Mui-checked': {
            color: theme.palette.text.primary.primary400,
        },
    },
    value: {
        color: theme.palette.text.primary.primary950
    },
}));

const USCAndAttributeFormTemplate: React.FC<USCAndAttributeFormTemplateProps> = ({ mode, onClose, formik, setFormStateTo }) => {
    const classes = useStyles();
    const vasMasterCategoryService = useVASMasterCategoryService();
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const [superServiceData, setSuperServiceData] = useState<Service[] | null>(null);
    const [mainServiceData, setMainServiceData] = useState<Service[] | null>(null);
    const [productServiceData, setProductServiceData] = useState<Service[] | null>(null);
    const [attributes, setAttributes] = useState<Attribute[]>(formik?.values?.attributes);
    const [service, setService] = useState<Service | null>(null);
    const [isReviewButtonDisabled, setIsReviewButtonDisabled]=useState<boolean>(true);

    const fetchSuperServices = (inputValue?: string) => {
        vasMasterCategoryService.getAllService({ level: VAS_MASTER_CATEGORY_TYPE.SUPER_SERVICE, page: 0, size: 10, sort: 'createdAt,desc', name: inputValue ?? '', status: 'ACTIVE' }, {})
            .then(res => {
                if (res.status === HTTP_STATUS.OK)
                    setSuperServiceData(res?.data?.data?.content)
            }).catch((error) => {
                console.error("Error Fetching Service: ", error);
                showSnackbar('error', "Error while fetching Super Service data");
            })
    }

    const fetchMainServices = (inputValue?: string) => {
        if (formik.values?.superService?.id) {
            vasMasterCategoryService.getAllService({ level: VAS_MASTER_CATEGORY_TYPE.MAIN_SERVICE, page: 0, size: 10, sort: 'createdAt,desc', parentId: formik.values?.superService?.id ?? '', name: inputValue ?? '', status: 'ACTIVE' }, {})
                .then(res => {
                    if (res.status === HTTP_STATUS.OK)
                        setMainServiceData(res?.data?.data?.content)
                }).catch((error) => {
                    console.error("Error Fetching Service: ", error);
                    showSnackbar('error', "Error while fetching main Service data");
                })
        }
    }

    const fetchProductServices = (inputValue?: string) => {
        if (formik.values?.mainService?.id) {
            vasMasterCategoryService.getAllService({ level: VAS_MASTER_CATEGORY_TYPE.SERVICE_CATEGORY, page: 0, size: 10, sort: 'createdAt,desc', parentId: formik.values?.mainService?.id ?? '', name: inputValue ?? '', status: 'ACTIVE' }, {})
                .then(res => {
                    if (res.status === HTTP_STATUS.OK)
                        setProductServiceData(res?.data?.data?.content)
                }).catch((error) => {
                    console.error("Error Fetching Service: ", error);
                    showSnackbar('error', "Error while fetching Service Category data");
                })
        }
    }

    const loadProductService = () => {
        vasMasterCategoryService.getServiceById(formik.values?.productService?.id)
            .then(res => {
                if (res.status === HTTP_STATUS.OK) {
                    let category = res.data.data
                    setService({
                        ...category,
                        superParent: category?.ancestors?.superParentCategory,
                        parentCategory: category?.ancestors?.parentCategory
                    })
                }
            }).catch((error) => {
                setService(null);
                console.error("Error Fetching Service: ", error);
                showSnackbar('error', "Error while fetching Product Service data");
            })
    }

    const checkErrors = () => {
        const errors = Object.keys(formik.errors).find(key => 
            ['superService', 'mainService', 'productService', 'productServiceId', 'name', 'description', 'status', 'attributes', 'subAttributeIds'].includes(key)
        );
        return !!errors;
    };

    useEffect(() => {
        setIsReviewButtonDisabled(checkErrors());
    }, [formik.errors]);

    useEffect(() => {
        if (formik.values?.productService?.id)
            loadProductService()
    }, [formik.values?.productService?.id])

    useEffect(() => {
        if (!superServiceData) {
            fetchSuperServices();
        }
    }, [superServiceData]);

    useEffect(() => {
        if (formik?.values?.superService?.id) {
            fetchMainServices();
        }
    }, [formik?.values?.superService?.id]);

    useEffect(() => {
        if (formik?.values?.mainService?.id) {
            fetchProductServices();
        }
    }, [formik?.values?.mainService?.id]);

    const superServiceOptions = useMemo(() => {
        return superServiceData?.map((superService: Service) => ({ label: superService.name, id: superService.id })) ?? []
    }, [superServiceData])

    const mainServiceOptions = useMemo(() => {
        return mainServiceData?.map((mainService: Service) => ({ label: mainService.name, id: mainService.id })) ?? []
    }, [mainServiceData])

    const productServiceOptions = useMemo(() => {
        return productServiceData?.map((productService: Service) => ({ label: productService.name, id: productService.id })) ?? []
    }, [productServiceData])

    const updateSelectedService = (key: string) => (option: AutoCompleteOption | null) => {
        formik.setFieldValue(key, option);
        if (key === "superService") {
            formik.setFieldValue("mainService", { label: "", id: null });
            formik.setFieldValue("productService", { label: "", id: null });
            formik.setFieldValue("productServiceId", null);
        }
        if (key === "mainService") {
            formik.setFieldValue("productService", { label: "", id: null });
            formik.setFieldValue("productServiceId", null);
        }
    };

    useEffect(() => {
        formik.setFieldValue('productServiceId', formik?.values?.productService?.id);
    }, [formik?.values?.productService?.id])

    const [selectedSubAttribute, setSelectedSubAttributes] = useState<{ [key: string]: SubAttributes }>({});

    const handleSubAttributeChange = (item: SubAttribute) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const updatedSubAttributes = { ...selectedSubAttribute }
        setSelectedSubAttributes((prev) => {
            if (event.target.checked) {
                updatedSubAttributes[item.id] = {
                    minTolerance: 0,
                    maxTolerance: 0,
                };
            } else {
                delete updatedSubAttributes[item.id];
            }
            return updatedSubAttributes;
        });
        formik.setFieldValue("subAttributeIds", updatedSubAttributes);
    };

    useEffect(() => {
        if (formik?.values?.subAttributeIds)
            setSelectedSubAttributes(formik?.values?.subAttributeIds)
    }, [formik?.values?.subAttributeIds])

    const handleNext = () => {
        formik.setTouched({ superService: true, mainService: true, productService: true, productServiceId: true, name: true, description: true, status: true, attributes: true, subAttributeIds: true }, true)
        const errors = Object.keys(formik.errors).find(key => ['superService', 'mainService', 'productService', 'productServiceId', 'name', 'description', 'status', 'attributes', 'subAttributeIds'].includes(key))
        if (errors) return;
        setFormStateTo(USC_FORM_STATE.REVIEW)();
    }

    useEffect(() => {
        formik.setFieldValue("attributes", service?.attributes);
    }, [service?.attributes]);

    return (
        <div className="grid gap-y-6" >
            {SnackBarComponent}
            <div className={`w-full m-auto flex justify-between gap-x-2 text-center text-sm  mt-2`}>
                <div className="grid gap-y-2 w-1/2">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.activeProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto text-sm font-semibold`}>USC and Attributes Details</div>
                    </div>
                    <div className={`${classes.activeProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-2 w-1/2">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.inActiveProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.inactiveBarHeading} text-sm my-auto`}>Review</div>
                    </div>
                    <div className={`${classes.inActiveProgressBar} h-2.5 rounded `}></div>
                </div>
            </div>
            <div className='grid gap-y-4'>
                <div>
                    <div className={`${classes.componentHeading} text-base font-medium`}>USC and Attributes Details</div>
                    <div className={`${classes.componentSubHeading} text-xs font-normal`}>Please fill out the details to create USC</div>
                </div>

                <div className={`${classes.border} grid gap-y-4 p-6 rounded-xl mt-2`}>
                    <div className={`${classes.heading} text-base font-medium`}>Select Super Service, Main Service and Service Category to be Linked with the USC</div>
                    <div className='grid grid-cols-3 gap-x-4'>
                        <div className="grid gap-y-1">
                            {(( mode === MODE.ADD) || (superServiceData)) && <FormControl fullWidth>
                                <AutoCompleteInputV2
                                    options={superServiceOptions}
                                    label="Select Super Service"
                                    placeholder='Select Super Service'
                                    onChange={updateSelectedService('superService')}
                                    onSearch={fetchSuperServices}
                                    isDisabled={mode === MODE.VIEW}
                                    value={formik.values?.superService}
                                    error={!!formik.errors.superService && formik.touched.superService}
                                />
                                {formik.errors?.superService?.label && formik.touched.superService && (
                                    <div className={`${classes.errorMessage} text-xs`}>
                                        {formik.errors?.superService?.label}
                                    </div>
                                )}
                            </FormControl>}
                        </div>
                        <div className="grid gap-y-1">
                            {((mode === MODE.ADD) || (mainServiceData)) && <FormControl fullWidth>
                                <AutoCompleteInputV2
                                    options={mainServiceOptions}
                                    label="Select Main Service"
                                    placeholder='Select Main Service'
                                    onChange={updateSelectedService('mainService')}
                                    onSearch={fetchMainServices}
                                    isDisabled={mode === MODE.VIEW}
                                    value={formik.values?.mainService}
                                    error={!!formik.errors.mainService && formik.touched.mainService}
                                />
                                {formik.errors?.mainService?.label && formik.touched.mainService && (
                                    <div className={`${classes.errorMessage} text-xs`}>
                                        {formik.errors?.mainService?.label}
                                    </div>
                                )}
                            </FormControl>}
                        </div>
                        <div className="grid gap-y-1">
                            {((mode === MODE.ADD) || (productServiceData)) && <FormControl fullWidth>
                                <AutoCompleteInputV2
                                    options={productServiceOptions}
                                    label="Select Service Category"
                                    placeholder='Select Service Category'
                                    onChange={updateSelectedService('productService')}
                                    onSearch={fetchProductServices}
                                    isDisabled={mode === MODE.VIEW}
                                    value={formik.values?.productService}
                                    error={!!formik.errors.productService && formik.touched.productService}
                                />
                                {formik.errors?.productService?.label && formik.touched.productService && (
                                    <div className={`${classes.errorMessage} text-xs`}>
                                        {formik.errors?.productService?.label}
                                    </div>
                                )}
                            </FormControl>}
                        </div>
                    </div>
                </div>

                {
                    formik.values?.attributes &&
                    <div className={`${classes.border} grid gap-y-4 p-6 rounded-xl`} >
                        <div className={`${classes.heading} text-base font-medium`}>Select Attributes Values</div>
                        {
                            formik.values?.attributes?.map((attribute: Attribute) => (
                                <div className={`${classes.border} grid gap-y-4 p-4 rounded-xl`} key={attribute.id}>
                                    <div className={`${classes.heading} text-base font-medium`}>{attribute.name}(Min-Max)</div>
                                    <div className='grid grid-cols-5 gap-4'>
                                        {
                                            attribute.subAttributes.map((subAttribute: SubAttribute) => (
                                                <div className="flex gap-x-2" key={subAttribute.id}>
                                                    <div className=''>
                                                        <Checkbox
                                                            sx={{ padding: 0, width: "18px", height: "18px", }}
                                                            className={classes.checkBox}
                                                            checked={Boolean(selectedSubAttribute[subAttribute.id])}
                                                            onChange={handleSubAttributeChange(subAttribute)}
                                                        />
                                                    </div>
                                                    <div className={`${classes.value} my-auto text-base font-normal`}>{subAttribute.minValue}-{subAttribute.maxValue} {attribute.uom}</div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            ))}
                        {formik.errors.subAttributeIds && formik.touched.subAttributeIds && (
                            <div className={`${classes.errorMessage} text-xs`}>
                                {formik.errors.subAttributeIds}
                            </div>
                        )}
                    </div>
                }

                <div className={`${classes.border} grid gap-y-4 p-6 rounded-xl`}>
                    <div className={`${classes.heading} text-base font-medium`}>USC Details</div>
                    <div className='grid grid-cols-2 gap-x-4'>
                        <div className="grid gap-y-1">
                            <TextFieldV2
                                label="USC Name"
                                placeholder='Enter USC Name'
                                {...formik.getFieldProps("name")}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                inputProps={{ readOnly: mode === MODE.VIEW, maxLength: 100 }}
                                onBlur={(event) => {
                                    const newValue = titleModification(event.target.value);
                                    formik.setFieldValue('name', newValue);
                                }}
                            />
                            {formik.errors.name && formik.touched.name && (
                                <div className={`${classes.errorMessage} text-xs`}>
                                    {formik.errors.name}
                                </div>
                            )}
                        </div>
                        {
                            mode === MODE.UPDATE &&
                            <div className="grid gap-y-1">
                                <TextFieldV2
                                    label="USC Code"
                                    {...formik.getFieldProps("uscCode")}
                                    error={formik.touched.uscCode && Boolean(formik.errors.uscCode)}
                                    inputProps={{ readOnly: mode === MODE.UPDATE }}
                                    onBlur={(event) => {
                                        const newValue = titleModification(event.target.value);
                                        formik.setFieldValue('uscCode', newValue);
                                    }}
                                    disabled
                                />
                                {formik.errors.uscCode && formik.touched.uscCode && (
                                    <div className={`${classes.errorMessage} text-xs`}>
                                        {formik.errors.uscCode}
                                    </div>
                                )}
                            </div>
                        }
                    </div>
                    <div className="grid gap-y-1">
                        <TextFieldV2
                            variant="outlined"
                            label="Add Description"
                            placeholder='Enter Here'
                            multiline
                            maxRows={4}
                            {...formik.getFieldProps("description")}
                            error={formik.touched.description && Boolean(formik.errors.description)}
                            inputProps={{ readOnly: mode === MODE.VIEW,  maxLength: 500 }}
                            onBlur={(event) => {
                                const newValue = titleModification(event.target.value);
                                formik.setFieldValue('description', newValue);
                            }}
                        />
                        {formik.errors.description && formik.touched.description && (
                            <div className={`${classes.errorMessage} text-xs`}>
                                {formik.errors.description}
                            </div>
                        )}
                    </div>
                </div>

                <StatusV2 heading="USC Status" options={SERVICE_STATUS_OPTIONS} formik={formik} name="status" />
            </div>

            <div className="flex justify-end gap-4">
                <Button variant="tertiaryContained" label='Cancel' onClick={onClose} />
                {mode !== MODE.VIEW && <Button disabled={isReviewButtonDisabled} variant="primaryContained" onClick={handleNext} label={"Review"} />}
            </div>
        </div>
    )
}

export default USCAndAttributeFormTemplate