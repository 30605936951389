import * as React from 'react';
import MuiTooltip from '@mui/material/Tooltip';

export interface TooltipProps {
    title?: string | null;
    children: React.ReactNode;
}

const Tooltip: React.FC<TooltipProps> = ({ title, children }) => {
    return (
        <>
            {title ? (
                <MuiTooltip title={title}>{React.cloneElement(children as React.ReactElement)}</MuiTooltip>
            ) : (
                children
            )}
        </>
    );
};

export default Tooltip;