import { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import * as Yup from "yup";
import { FormikProps, useFormik } from 'formik';
import ProductRequirementFormTemplate, { Attributes } from './ProductRequirementForm.template';
import CustomerDetailsFormTemplate from './CustomerDetailsForm.template';
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';
import { useGstinService } from '../../../services/useGstService';
import { RFQ_SECTIONS } from '../../../utils/types';
import { REGEX } from '../../../utils/constant';
import { GstResponse } from '../../pages/OnBoarding/OnBoarding.page';

interface RequestForQuotationFormTemplateProps {
    onSubmit: (requestBody: any) => void;
}

export interface IRFQFormRequestBody {
	customerId: string;
    productCategoryId: string;
    manufacturerId: string;
    gradeId: string;
    standardId: string;
    shape: string;
    attributes: string;
    requiredQuantity: string;
    expectedDeliveryDate: string;
    specificRequirement: string | string[];
    paymentTerm: string;
	name: string;
    companyName: string;
    addressLine1: string;
    addressLine2: string;
    pinCode: string;
    city: string;
    state: string;
    gst: string;
	email: string;
	mobileNumber: string;
}

export interface IRFQForm {
	customerId: string;
    productCategoryId: string;
    manufacturerId: string;
    gradeId: string;
    standardId: string;
    shape: string;
	attributes: string;
    requiredQuantity: string;
    expectedDeliveryDate: string;
    specificRequirement: string[];
    paymentTerm: string;
	name: string;
    companyName: string;
    addressLine1: string;
    addressLine2: string;
    pinCode: string;
    city: string;
    state: string;
    gst: string;
	email: string;
	mobileNumber: string;
	manufacturer: {
		label: string;
		id: string;
	};
	standard: {
		label: string;
		id: string;
	};
	grade: {
		label: string;
		id: string;
	};
	superCategory: {
		label: string;
		id: string;
	};
	mainCategory: {
		label: string;
		id: string;
	};
	productCategory: {
		label: string;
		id: string;
	};
}

export interface IProductDetails {
    customerId: number;
    productCategoryId: number;
    manufacturerId: number;
    gradeId: number;
    standardId: number;
    shape: string;
	attributes: string;
    requiredQuantity: number;
    expectedDeliveryDate: any;
    specificRequirement: string;
    paymentTerm: string;
}
export interface IPersonalDetails {
	name: string;
    companyName: string;
    addressLine1: string;
    addressLine2: string;
    pinCode: number;
    city: string;
    state: string;
    gst: string;
	email: string;
	mobileNumber: string;
}

interface RfqSectionProps {
	setCurrentSectionTo: (section: RFQ_SECTIONS) => void;
	formik: FormikProps<IRFQForm>;
	gstDetails?: any
    setAttributeValues?: any
	attributeValues?: Attributes[],
	productSpecification?: any,
	setProductSpecificationOptions?: any
}

const RequestForQuotationFormTemplate: React.FC<RequestForQuotationFormTemplateProps> = ({ onSubmit }) => {
	const [currentSection, setCurrentSectionTo] = useState<RFQ_SECTIONS>(RFQ_SECTIONS.PRODUCT_REQUIREMENT);
    const {user} = useAuthenticatedUser();
	const gstService = useGstinService();
    const [gstDetails, setGstDetails] = useState<GstResponse | null>(null);
	const [attributeValues, setAttributeValues] = useState<Attributes[]>([]);
	const [productSpecification, setProductSpecificationOptions] = useState<any[]>();

	const fetchGstDetails = async () => {
		if(user===null){
			return;	
		}
		gstService.searchGstin({ gstin: user?.gstin, userId: user?.id })
		  .then(res => {
			setGstDetails(res.data.data)
		  }).catch(err => {
			console.error(err)
		  })
	  }

	  useEffect(() => {
		if (gstDetails == null)
		  fetchGstDetails();
	  }, [])

    const initialProductDetailsValues = {
        customerId: user?.id ?? "",
        productCategoryId: "",
        manufacturerId: "",
        gradeId: "",
        standardId: "",
        shape: "",
		attributes: "",
        requiredQuantity: "",
        expectedDeliveryDate: "",
        specificRequirement: [],
        paymentTerm: "",
		superCategory:
		{
			id: "",
			label: ""
		},
		mainCategory:
		{
			id: "",
			label: ""
		},
		productCategory:
		{
			id: "",
			label: ""
		},
		manufacturer:
		{
			id: "",
			label: ""
		},
		standard:
		{
			id: "",
			label: ""
		},
		grade:
		{
			id: "",
			label: ""
		},
		
		name:  user?.fullName ?? "",
        companyName:  gstDetails?.legalNameOfBusiness ?? "",
        addressLine1:   gstDetails?.addressLine ?? "",
        addressLine2:  "",
        pinCode:  gstDetails?.pincode ?? "",
		city: gstDetails?.city[0].split('(')[0].trim() ?? "",
		state: gstDetails?.state[0][0] ?? "",
        gst:  user?.gstin ?? "",
        email:  user?.email ?? "",
        mobileNumber:  user?.mobileNumber ?? "",
	};
	
    const validationSchema = Yup.object().shape({
		superCategory: Yup.object().shape({
			id: Yup.number().required('Super Category is required'),
			label: Yup.string().required('Super Category is required')
		}).required('Super Category is required'),
		mainCategory: Yup.object().shape({
			id: Yup.number().required('Main Category is required'),
			label: Yup.string().required('Main Category is required')
		}).required('Main Category is required'),
		productCategory: Yup.object().shape({
			id: Yup.number().required('Product Category is required'),
			label: Yup.string().required('Product Category is required')
		}).required('Product Category is required'),
		standard: Yup.object().shape({
			id: Yup.number().required('Standard is required'),
			label: Yup.string().required('Standard is required')
		}).required('Manufacturer is required'),
		grade: Yup.object().shape({
			id: Yup.number().required('Grade is required'),
			label: Yup.string().required('Grade is required')
		}).required('Manufacturer is required'),
		shape: Yup.string().required('Shape is required'),
		requiredQuantity: Yup.number().nullable().moreThan(0,"Quantity should be greater than zero").required('Quantity is required'),	
		expectedDeliveryDate: Yup.date().typeError("Enter valid date").required('Expected Delivery Date is required'),	
		specificRequirement: Yup.array().of(Yup.string()).min(1, 'At least one Specific Requirement is required').required('Specific Requirement are required'),
		paymentTerm: Yup.string().required('Payment Term is required'),
		name: Yup.string().nullable().matches(REGEX.NAME, 'Enter valid name').required('Name is required'),
		// companyName: Yup.string().nullable().matches(REGEX.NAME, 'Enter valid company name').required('Company Name is required'),
		addressLine1: Yup.string().nullable().trim().min(1, 'Address Line is required').required('Address Line is required'),
		pinCode: Yup.number().min(100000, 'Enter a valid PINCODE').max(999999, 'Enter a valid PINCODE').required('Pincode is required'),
		city:  Yup.string().nullable().matches(REGEX.NAME, 'Enter valid city').required('City is required'),
		state:  Yup.string().nullable().matches(REGEX.NAME, 'Enter valid state').required('State is required'),
		email: Yup.string().email("Invalid email format").matches(REGEX.EMAIL, 'Enter valid Email'),
		mobileNumber: Yup.string().required("Mobile Number is required").matches(/^[6-9]\d{9}$/, "Mobile number must start with either 6,7,8,ot 9 and must be 10 digits only"),
		gst: Yup.string().transform((value, originalValue) => originalValue ? originalValue.toUpperCase() : value).matches(REGEX.GSTIN, 'Enter valid GSTIN'),
		attributes: Yup.array().of(
			Yup.object().shape({
				value: Yup.string().matches(/^[a-zA-Z0-9]+(?:\.[a-zA-Z0-9]{0,3})?$/, 'Enter valid value').required("Value is required")
			})
		)
	});

    const formik = useFormik<IRFQForm>({
		validateOnMount: true,
		enableReinitialize: true,
		initialValues: initialProductDetailsValues,
		validationSchema: validationSchema,
		onSubmit: async (values, { setSubmitting }) => {
				setSubmitting(true);
				const requestBody: IRFQFormRequestBody = {
					customerId: values?.customerId,
					productCategoryId: values?.productCategory?.id,
					manufacturerId: values?.manufacturer?.id,
					gradeId: values?.grade?.id,
					standardId: values?.standard?.id,
					shape: values?.shape,
					attributes: values?.attributes,
					requiredQuantity: values?.requiredQuantity,
					expectedDeliveryDate: moment(values?.expectedDeliveryDate).format('DD-MM-YYYY'),
					specificRequirement: values?.specificRequirement,
					paymentTerm: values?.paymentTerm,
					name: values?.name,
					companyName: values?.companyName,
					addressLine1: values?.addressLine1,
					addressLine2: values?.addressLine2,
					pinCode: values?.pinCode,
					city: values?.city,
					state: values?.state,
					gst: values?.gst,
					email:  values?.email,
					mobileNumber: values?.mobileNumber,
				}
                    onSubmit(requestBody)
            }
	});

    const rfqSectionProps: RfqSectionProps = {
		setCurrentSectionTo: setCurrentSectionTo,
		formik: formik,
		gstDetails: gstDetails,
		setAttributeValues: setAttributeValues,
		attributeValues: attributeValues,
		productSpecification: productSpecification,
	    setProductSpecificationOptions: setProductSpecificationOptions
	}

    const rfqSectionView = useMemo(() => {
		switch (currentSection) {
			case RFQ_SECTIONS.PRODUCT_REQUIREMENT:
				return <ProductRequirementFormTemplate { ...rfqSectionProps } />;

			case RFQ_SECTIONS.PERSONAL_DETAILS:
				return <CustomerDetailsFormTemplate { ...rfqSectionProps } />;

			default:
				return <ProductRequirementFormTemplate { ...rfqSectionProps } />;
		}
	}, [currentSection, formik]);

	return <div>  { rfqSectionView } </div>;
}

export const changeSectionTo = (setCurrentSectionTo: (section: RFQ_SECTIONS) => void) => (section: RFQ_SECTIONS) => {
	return (event: React.MouseEvent<HTMLButtonElement>) => {
		setCurrentSectionTo(section);
	};
};

export default RequestForQuotationFormTemplate