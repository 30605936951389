import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import TextFieldV2 from '../../../../atoms/Input/TextFieldV2';
import Button from '../../../../atoms/Button/Button';
import { VALUE_ADDED_SERVICE_ROUTES, sortOptions, statusOptions } from '../../../../../utils/constant';
import {  IUSCs, Service, useVASMasterCategoryService } from '../../../../../services/useVASMasterCategoryService';
import { HTTP_STATUS, IPagination, VAS_MASTER_CATEGORY_TYPE } from '../../../../../utils/types';
import { useSnackbar } from '../../../../../hooks/useSnackBar';
import { enumToString, makeRoute } from '../../../../../utils/helper';
import moment from 'moment';
import viewEyeIcon from "../../../../../assets/icons/viewOutlinedIcon.svg";
import ResourceStatusV2 from '../../../../atoms/ResourceStatusV2/ResourceStatusV2';
import SearchIcon from "../../../../../assets/images/headerSearch.svg";
import { createUseStyles } from 'react-jss';
import TableV2, { ColumnType } from '../../../../organisms/TableV2';
import FilterChipV2, { IMultiSelectOption } from '../../../../atoms/FilterChipV2/FilterChipV2';
import FilterChipV3 from '../../../../atoms/FilterChipV3/FilterChipV3';

interface USCListTemplateProps {
    usc: any[];
    pagination: IPagination;
    handlePaginationChange: any;
    handleRowsPerPageChange: any;
    handleFiltersChange: (name: string, value: any) => void;
    filters: any;
    uscRequestFilters: any;
    setUscRequestFiltersTo: any;
}

const useStyles = createUseStyles((theme: any) => ({

    quantityInput: {
        "& .MuiOutlinedInput-root": {
            // padding: "5px",
            border: 'none',
            // width:"100%"
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: 'none'
        },
    },
    searchContainer: {
        display: 'flex',
        alignItems: 'center',
        borderRadius: '8px',
        // fontSize: '1.25vw',
        paddingLeft: '10px',
        border: `1px solid ${theme.palette.border.primary.primary100}`,
        width: '100%',
    },
}));

const USCListTemplate: React.FC<USCListTemplateProps> = ({ usc, pagination, handlePaginationChange, handleRowsPerPageChange, handleFiltersChange, filters, uscRequestFilters, setUscRequestFiltersTo }) => {

    const navigate = useNavigate();
    const classes = useStyles()

    const schema = {
        id: "1",
        title: "",
        pagination: {
            total: pagination?.totalRecords,
            currentPage: pagination?.page,
            isVisible: true,
            limit: pagination?.size,
            handleChangePage: handlePaginationChange,
            handleChangeRowsPerPage: handleRowsPerPageChange,
        },
        columns: [
            { label: "S.No.", key: "id", type: "number" as ColumnType, props: { className: '' } },
            { label: "Universal Service Code", key: "uscCode", type: "string" as ColumnType, props: { className: '' } },
            { label: "Service Category", key: "serviceCategory", type: "string" as ColumnType, props: { className: '' } },
            { label: "Main Service", key: "mainService", type: "string" as ColumnType, props: { className: '' } },
            { label: "Super Service", key: "superService", type: "string" as ColumnType, props: { className: '' } },
            { label: "Created On", key: "createdAt", type: "datetime" as ColumnType, props: { className: '' } },
            { label: "Created By", key: "createdBy", type: "string" as ColumnType, props: { className: '' } },
            { label: "Status", key: "status", component: ({ value }: { value: string }) => <ResourceStatusV2 status={value} />, type: "custom" as ColumnType, props: {} },
            { label: "Action", key: "action", type: "custom" as ColumnType, props: { className: '' } },
        ],
    };

    const handleActionRoute = (id: number) => () => {
        navigate(makeRoute(VALUE_ADDED_SERVICE_ROUTES.USCS_VIEW, { params: { id: id } }));
    }

    const Action = (id: number) => {
        return (
            <div>
                <Button variant='primaryText' size='small' label={"View"} onClick={handleActionRoute(id)} />
            </div>
        );
    };

    const records = usc?.map((usc: IUSCs, index: number) => [
        pagination.page * pagination.size + index + 1,
        usc?.uscCode,
        usc?.serviceCategory,
        usc?.mainService,
        usc?.superService,
        moment(usc.createdAt).format("DD-MM-YYYY"),
        usc.createdBy,
        enumToString(usc?.status),
        Action(usc?.id),
    ]);

    const vasMasterCategoryService = useVASMasterCategoryService();
    const [superServiceData, setSuperServiceData] = useState<Service[] | null>(null);
    const [mainServiceData, setMainServiceData] = useState<Service[] | null>(null);
    const [productServiceData, setProductServiceData] = useState<Service[] | null>(null);

    const initialSelections = {
        status: [{ label: 'ALL', value: 'ALL' }],
        sortDate: [{ label: 'createdAt,desc', value: 'createdAt,desc' }]
    };

    const initialUscRequestFilters: any = {
        superServiceIds: [],
        mainServiceIds: [],
        serviceCategoryIds: [],
    };

    const [searchTerm, setSearchTerm] = useState<string>('');
    const [selectedfilters, setSelectedFilters] = useState<any>(initialUscRequestFilters);

    const [status, setStatus] = useState<IMultiSelectOption[]>(initialSelections.status);
    const [sortDate, setSortDate] = useState<IMultiSelectOption[]>(initialSelections.sortDate);

    const { showSnackbar, SnackBarComponent } = useSnackbar();

    const fetchSuperServices = (inputValue?: string) => {
        vasMasterCategoryService.getAllService({ level: VAS_MASTER_CATEGORY_TYPE.SUPER_SERVICE, page: 0, size: 10, sort: 'createdAt,desc', name: inputValue ?? '', status: 'ACTIVE' },{})
            .then(res => {
                if (res.status === HTTP_STATUS.OK)
                    setSuperServiceData(res?.data?.data?.content)
            }).catch((error) => {
                console.error("Error Fetching Service: ", error);
                showSnackbar('error', "Error while fetching Super Service data");
            })
    }

    const fetchMainServices = (inputValue?: string) => {
        vasMasterCategoryService.getAllService({ level: VAS_MASTER_CATEGORY_TYPE.MAIN_SERVICE, page: 0, size: 10, sort: 'createdAt,desc', name: inputValue ?? '', status: 'ACTIVE' },{})
            .then(res => {
                if (res.status === HTTP_STATUS.OK)
                    setMainServiceData(res?.data?.data?.content)
            }).catch((error) => {
                console.error("Error Fetching Service: ", error);
                showSnackbar('error', "Error while fetching Main Service data");
            })
    }

    const fetchProductServices = (inputValue?: string) => {
        vasMasterCategoryService.getAllService({ level: VAS_MASTER_CATEGORY_TYPE.SERVICE_CATEGORY, page: 0, size: 10, sort: 'createdAt,desc', name: inputValue ?? '', status: 'ACTIVE' },{})
            .then(res => {
                if (res.status === HTTP_STATUS.OK)
                    setProductServiceData(res?.data?.data?.content)
            }).catch((error) => {
                console.error("Error Fetching Service: ", error);
                showSnackbar('error', "Error while fetching Service Category data");
            })
    }

    useEffect(() => {
        fetchSuperServices(searchTerm);
    }, [searchTerm]);

    useEffect(() => {
        fetchMainServices(searchTerm);
    }, [searchTerm]);

    useEffect(() => {
        fetchProductServices(searchTerm);
    }, [searchTerm]);

    const superServiceOptions = useMemo(() => {
        return superServiceData?.map((mainService: Service) => ({ label: mainService.name, value: mainService.id })) ?? []
    }, [superServiceData])

    const mainServiceOptions = useMemo(() => {
        return mainServiceData?.map((mainService: Service) => ({ label: mainService.name, value: mainService.id })) ?? []
    }, [mainServiceData])

    const productServiceOptions = useMemo(() => {
        return productServiceData?.map((mainService: Service) => ({ label: mainService.name, value: mainService.id })) ?? []
    }, [productServiceData])

    const handleSelectionChangeStatus = (selected: IMultiSelectOption[], clearWithCrossIcon: boolean | undefined) => {
        setStatus(selected);
        clearWithCrossIcon && handleStatusSaveClick(selected);
    };

    const handleSelectionChangeSort = (selected: IMultiSelectOption[], clearWithCrossIcon: boolean | undefined) => {
        setSortDate(selected);
        clearWithCrossIcon && handleSortSaveClick(selected);
    };

    const handleSelectionChange = (name: string, selected: IMultiSelectOption[], clearWithCrossIcon: boolean | undefined) => {
        setSelectedFilters((previousSelectedFilters: any) => ({
            ...previousSelectedFilters,
            [name]: selected
        }));
        clearWithCrossIcon && handleDeleteClick(name, selected);
    };

    const handleStatusSaveClick = (status: IMultiSelectOption[]) => {
        const valueString = status.length > 0 ? status[0].value : 'ALL';
        handleFiltersChange("status", valueString);
    };

    const handleSortSaveClick = (sortDate: IMultiSelectOption[]) => {
        const sortString = sortDate.length > 0 ? sortDate[0].value : 'createdAt,desc';
        handleFiltersChange("sort", sortString);
    };

    const handleSaveClick = (name: string, selectedValues: IMultiSelectOption[]) => {
        const updatedFilters = { ...uscRequestFilters };
        const newValues = selectedValues.map(option => option.value);

        if (updatedFilters[name]) {
            updatedFilters[name] = Array.from(new Set([...updatedFilters[name], ...newValues]));
        } else {
            updatedFilters[name] = newValues;
        }
        setUscRequestFiltersTo(updatedFilters);
    };

    const handleDeleteClick = (name: string, selectedValues: IMultiSelectOption[]) => {
        const selectedValueIds = selectedValues.map(option => option.value);
        const updatedFilters = {
            ...uscRequestFilters,
            [name]: uscRequestFilters[name].filter((id: number) => selectedValueIds.includes(id))
        };
        setUscRequestFiltersTo(updatedFilters);
    };

    const handleClearClick = (filterType: string) => {
        switch (filterType) {
            case 'status':
                setStatus([{ label: 'ALL', value: 'ALL' }]);
                handleFiltersChange("status", 'ALL');
                break;
            case 'sortDate':
                setSortDate([{ label: 'createdAt,desc', value: 'createdAt,desc' }]);
                handleFiltersChange("sort", 'createdAt,desc');
                break;
            case 'superServiceIds':
                setSelectedFilters((previousSelectedFilters: any) => ({
                    ...previousSelectedFilters,
                    superServiceIds: []
                }));
                setUscRequestFiltersTo((prevState: any) => ({
                    ...prevState,
                    superServiceIds: []
                }));
                break;
            case 'mainServiceIds':
                setSelectedFilters((previousSelectedFilters: any) => ({
                    ...previousSelectedFilters,
                    mainServiceIds: []
                }));
                setUscRequestFiltersTo((prevState: any) => ({
                    ...prevState,
                    mainServiceIds: []
                }));

                break;
            case 'serviceCategoryIds':
                setSelectedFilters((previousSelectedFilters: any) => ({
                    ...previousSelectedFilters,
                    serviceCategoryIds: []
                }));
                setUscRequestFiltersTo((prevState: any) => ({
                    ...prevState,
                    serviceCategoryIds: []
                }));
                break;
            default:
                setStatus([{ label: 'ALL', value: 'ALL' }]);
                setSortDate([{ label: 'createdAt,desc', value: 'createdAt,desc' }]);
                setSelectedFilters(initialUscRequestFilters);
        }
    };

    return (
        <div className='grid gap-y-6'>
            <div className='flex justify-between'>
                <div className='flex flex-wrap gap-x-3 my-auto'>
                    <FilterChipV2
                        options={statusOptions}
                        label=" "
                        value={status}
                        onchange={handleSelectionChangeStatus}
                        placeholder="Status"
                        ClearButtonComponent={Button}
                        buttonLabel={"Clear all"}
                        buttonOnClick={() => handleClearClick('status')}
                        SaveButton={Button}
                        saveButtonLabel='Apply'
                        saveButtonOnClick={() => handleStatusSaveClick(status)}
                        isSingleSelect={true}
                        minWidth='94px'
                    />
                    <div className='hidden'>
                        <FilterChipV2
                            options={sortOptions}
                            label=" "
                            value={sortDate}
                            onchange={handleSelectionChangeSort}
                            placeholder="Sort Date"
                            ClearButtonComponent={Button}
                            buttonLabel={"Clear all"}
                            buttonOnClick={() => handleClearClick('sortDate')}
                            SaveButton={Button}
                            saveButtonLabel='Apply'
                            saveButtonOnClick={() => handleSortSaveClick(sortDate)}
                            isSingleSelect={true}
                            // minWidth='180px'
                            />
                    </div>
                    <FilterChipV3
                        options={superServiceOptions}
                        label=" "
                        value={selectedfilters?.superServiceIds}
                        onchange={handleSelectionChange}
                        name="superServiceIds"
                        placeholder="Super Service"
                        ClearButtonComponent={Button}
                        textFieldPlaceholder='Search...'
                        searchTerm={searchTerm}
                        setSearchTerm={setSearchTerm}
                        buttonLabel={"Clear all"}
                        buttonOnClick={() => handleClearClick('superServiceIds')}
                        SaveButton={Button}
                        saveButtonLabel='Apply'
                        saveButtonOnClick={() => handleSaveClick('superServiceIds', selectedfilters?.superServiceIds)}
                    />
                    <FilterChipV3
                        options={mainServiceOptions}
                        label=" "
                        value={selectedfilters?.mainServiceIds}
                        onchange={handleSelectionChange}
                        name="mainServiceIds"
                        placeholder="Main Service"
                        ClearButtonComponent={Button}
                        textFieldPlaceholder='Search...'
                        searchTerm={searchTerm}
                        setSearchTerm={setSearchTerm}
                        buttonLabel={"Clear all"}
                        buttonOnClick={() => handleClearClick('mainServiceIds')}
                        SaveButton={Button}
                        saveButtonLabel='Apply'
                        saveButtonOnClick={() => handleSaveClick('mainServiceIds', selectedfilters?.mainServiceIds)}
                    />
                    <FilterChipV3
                        options={productServiceOptions}
                        label=" "
                        value={selectedfilters?.serviceCategoryIds}
                        onchange={handleSelectionChange}
                        name="serviceCategoryIds"
                        placeholder="Service Category"
                        ClearButtonComponent={Button}
                        textFieldPlaceholder='Search...'
                        searchTerm={searchTerm}
                        setSearchTerm={setSearchTerm}
                        buttonLabel={"Clear all"}
                        buttonOnClick={() => handleClearClick('serviceCategoryIds')}
                        SaveButton={Button}
                        saveButtonLabel='Apply'
                        saveButtonOnClick={() => handleSaveClick('serviceCategoryIds', selectedfilters?.serviceCategoryIds)}
                    />
                </div>

                <div className={`${classes.searchContainer}  ${classes.quantityInput} !w-[324px]`}>
                    <img src={SearchIcon} alt="searchLogo" className="w-5" />
                    <TextFieldV2
                        className="!w-full"
                        placeholder='Search...'
                        type="text"
                        name="name"
                        value={filters.name}
                        onChange={(e) => handleFiltersChange('name', e.target.value)}
                    />
                </div>
            </div>
            <TableV2 schema={schema} records={records} />
        </div>
    )
}

export default USCListTemplate